<template>
     <v-autocomplete
        v-model="model"
        :rules="rules"
        :items="cmdbItems"
        :loading="cmdbSearchLoading"
        :search-input.sync="cmdbSearchSync"
        :label="label"
        item-text="name"
        placeholder="Start typing the name of a CI"
        :disabled="disabled"
        clearable
        :filter="filterObject"
        return-object
    >
    <template slot="item" slot-scope="data">
        {{ data.item.name }}
    </template>
    </v-autocomplete>
</template>

<script>
import axios from 'axios'

export default {
  name: 'cmdb-search-component',
  props: ['value', 'disabled', 'rules', 'label', 'noClear'],
  watch: {
    cmdbSearchSync: {
      handler () {
        if (this.cmdbSearchSync) {
          if (this.model && this.cmdbSearchSync === this.model) {
            return
          }
          clearTimeout(this.cmdbSearchTimeout)

          // Make a new timeout set to go off in 800ms
          this.cmdbSearchTimeout = setTimeout(() => {
            this.cmdbSearch()
          }, 500)
        } else {
          this.cmdbItems = [ ]
          this.model = ''
        }
      }
    },
    model: {
      handler () {
        this.$nextTick(() => {
          if (!this.noClear) {
            this.model = null
          }
        })
      }
    }
  },
  computed: {
    model: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  data () {
    return {
      cmdbItems: [],
      cmdbSearchLoading: false,
      cmdbSearchTimeout: null,
      cmdbSearchSync: null
    }
  },
  methods: {
    filterObject (item, queryText, itemText) {
      return (item.name.toLowerCase().includes(queryText.toLowerCase()))
    },
    cmdbSearch () {
      this.cmdbSearchLoading = true
      axios(`${process.env.VUE_APP_CLOUDBASEURL}/cmdb?serviceName=${this.cmdbSearchSync}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then((response) => {
          this.cmdbSearchLoading = false
          this.cmdbItems = response.data
        }, (error) => {
          if (error) {
            this.cmdbSearchLoading = false
          }
        })
    }
  },
  beforeMount () {
    if (this.value) {
      this.cmdbItems = [this.value]
    }
  }
}
</script>

<style>
</style>
