<template>
<v-row class="mt-2">
    <v-col cols="12" md="12">
        <v-card
            :loading="isSavingTeam"
            class="ap-2"
            color="transparent"
            flat
        >
            <template v-slot:progress>
            <v-progress-linear
                absolute
                color="green lighten-3"
                height="4"
                indeterminate>
            </v-progress-linear>
            </template>
            <v-form ref="form">
            <v-container grow d-flex flex-column flex-nowrap>
                <v-row id="v-step-1">
                <v-col cols="12" md="6" class="fill-height">
                  <v-card outlined height="100%" class="pa-5">
                    <h2>General</h2>
                    <v-divider class="mb-5"></v-divider>
                    <v-row>
                    <v-col>
                        <v-text-field v-model="localTeam.displayName" label="Display Name" />
                    </v-col>
                    </v-row>
                    <v-row>
                    <v-col>
                        <v-textarea filled label="Description" :rules="descriptionRules" :disabled="isSavingTeam" v-model="localTeam.description"/>
                    </v-col>
                    </v-row>
                    <v-row>
                    <v-col>
                        <v-select
                        v-model="localTeam.affiliates"
                        :items="affiliates"
                        :loading="isLoadingAffiliates"
                        item-text="displayName"
                        item-value="id"
                        attach
                        chips
                        label="Affiliates (Optional)"
                        multiple
                        ></v-select>
                    </v-col>
                    </v-row>
                    <v-row v-if="adminSettings.isSuperUser">
                    <v-col>
                        <v-switch v-model="localTeam.enableTemplateCreation" label="Allow Template Creation" />
                    </v-col>
                    <v-col>
                        <v-switch v-model="localTeam.enableCloudAccountCreation" label="Allow Cloud Account Creation" />
                    </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card outlined class="pa-5">
                    <h2>Team Member Access</h2>
                    <v-divider class="mb-5"></v-divider>
                    <v-row>
                        <v-col>
                        <user-search-component :disabled="isSavingTeam" v-model="currentUserSearch" label="Members" />
                        <v-simple-table class="user-table">
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-left">34</th>
                                <th class="text-left">Name</th>
                                <th class="text-left">Delete</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(user, userIndex) in members" :key="'user-'+userIndex">
                                <td>{{ typeof user === 'object' && user !== null && Object.prototype.hasOwnProperty.call(user, 'Key') ? user.Key : 'Unknown ID' }}</td>
                                <td>{{ typeof user === 'object' && user !== null && Object.prototype.hasOwnProperty.call(user, 'Value') ? user.Value : 'Unknown Value' }}</td>
                                <td>
                                <v-btn icon @click="deleteUser(user)"><v-icon>mdi-delete</v-icon></v-btn></td>
                            </tr>
                            </tbody>
                        </template>
                        </v-simple-table>
                        </v-col>
                    </v-row>
                  </v-card>
                  <v-card outlined class="pa-5 mt-1">
                        <h2>Team AD Group Access</h2>
                        <v-divider class="mb-5"></v-divider>
                        <group-search-component class="required" id="v-step-5" :disabled="isSavingTeam" v-model="currentGroupSearch" label="Admin AD Groups" />
                        <v-simple-table class="user-table">
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-left">Group</th>
                                <th class="text-left">Delete</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="group in allowedGroups" :key="group">
                                <td>{{ group }}</td>
                                <v-btn icon @click="deleteGroup(group)"><v-icon>mdi-delete</v-icon></v-btn>
                            </tr>
                            </tbody>
                        </template>
                        </v-simple-table>
                  </v-card>
                    <v-row>
                </v-row>
                </v-col>
                </v-row>
                <v-row>
                <v-col cols="12" md="12">
                    <v-btn class="ma-2" color="info" :loading="isSavingTeam" :disabled="isSavingTeam" @click="triggerTeamUpdate">Update Team</v-btn>
                    <v-btn class="ma-2" :loading="isDeletingTeam" :disabled="isDeletingTeam" @click="displayDeleteDialog" color="error">Delete Team</v-btn>
                </v-col>
                </v-row>
                <v-row v-if="userError">
                <v-col class="text-center">
                    <span v-if="userError" style="color: #ff5252 !important">At least 1 user or group is required</span>
                </v-col>
                </v-row>
            </v-container>
            </v-form>
        </v-card>
    </v-col>
</v-row>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import axios from 'axios'
import userSearchComponent from '@/components/userSearchComponent.vue'
import groupSearchComponent from '@/components/groupSearchComponent.vue'
export default {
  name: 'team-settings',
  computed: {
    ...mapGetters(['adminSettings']),
    ...mapGetters('teams', ['team', 'isSavingTeam', 'isDeletingTeam']),
    ...mapGetters('affiliates', ['affiliates', 'isLoadingAffiliates']),
    ...mapState(['teams'])
  },
  components: {
    'user-search-component': userSearchComponent,
    'group-search-component': groupSearchComponent
  },
  watch: {
    team: function () {
      this.localTeam = this.team
      this.loadMembersAndGroups()
    },
    currentUserSearch: function (newValue, oldValue) {
      if (newValue && newValue !== null && Object.prototype.hasOwnProperty.call(newValue, 'Key')) {
        let currentList = this.members.filter(function (el) {
          return el != null
        })

        let duplicates = currentList.filter(user => {
          return user !== null && user !== 'undefined' && user.length > 0 && Object.prototype.hasOwnProperty.call(user, 'Key') && user.Key === newValue.Key
        })

        if (duplicates.length === 0) {
          this.members.push(newValue)
        }
      }
    },
    currentGroupSearch: function (newValue, oldValue) {
      if (newValue) {
        if (!this.allowedGroups.find(x => x === newValue)) {
          if (newValue.toLowerCase().startsWith('domain')) {
            this.$store.commit('showErrorDialog',
              {
                errorData: {
                  response: {
                    data: {
                      errorMessage: 'You cannot use a Domain Group for this list. Please select a more granular group'
                    }
                  }
                },
                persistErrorDialog: false
              })
          } else {
            this.allowedGroups.push(newValue)
          }
        }
      }
    }
  },
  data: () => ({
    currentUserSearch: null,
    currentGroupSearch: null,
    members: [ ],
    allowedGroups: [ ],
    descriptionRules: [
      v => !!v || 'A Description is required',
      v => (v && v.length < 500) || 'The Description must be less than 500 characters'
    ],
    userError: false,
    localTeam: {}
  }),
  methods: {
    ...mapActions({
      'updateTeam': 'teams/updateTeam',
      'deleteTeam': 'teams/deleteTeam'
    }),
    deleteUser: function (username) {
      let user = ''

      if (typeof username === 'object' && username !== null && Object.prototype.hasOwnProperty.call(username, 'Key')) {
        user = username.Key

        this.members = this.members.filter((item) => {
          return item.Key !== user
        })
      }
    },

    deleteGroup: function (group) {
      this.allowedGroups = this.allowedGroups.filter((item) => {
        return item !== group
      })
    },
    validate: function () {
      let validated = true
      this.userError = false
      if (this.members.length <= 0 && this.allowedGroups.length <= 0) {
        this.userError = true
        validated = false
      }
      if (!this.$refs.form.validate()) {
        validated = false
      }
      return validated
    },
    triggerTeamUpdate: function () {
      if (!this.validate()) {
        return false
      }

      if (this.members) {
        this.localTeam.members = [ ]
        this.members.forEach(x => {
          if (Object.prototype.hasOwnProperty.call(x, 'Key')) {
            this.localTeam.members.push(x.Key)
          }
        })
      }

      if (this.allowedGroups) {
        this.localTeam.adGroups = this.allowedGroups
      }

      this.updateTeam(this.localTeam)
    },
    displayDeleteDialog: function () {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to delete this Team? This action can not be undone.',
        confirmMethod: () => {
          this.deleteTeam()
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    getUserDetails: function (username) {
      return axios(
        `${process.env.VUE_APP_BASENETWORKAPIURL}/activedirectory/search/user?searchText=${username}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getUserDetails() })
          })
    },
    loadMembersAndGroups: function () {
      if (this.localTeam.members) {
        this.members = [ ]
        this.localTeam.members.forEach(x => {
          this.getUserDetails(x).then(y => {
            if (y && y.length === 1) {
              this.members.push(y[0])
            }
          })
        })
      }

      if (this.localTeam.adGroups) {
        this.allowedGroups = this.localTeam.adGroups
      }
    }
  },
  mounted: function () {
    this.localTeam = this.team
    this.loadMembersAndGroups()
  }
}
</script>

<style scoped>

</style>
