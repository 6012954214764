<template>
  <v-container>
      <v-row justify="center">
        <v-col cols="12" md="6" id="v-step-0">
          <h1>My Teams</h1>
          <v-icon color="info">mdi-help-circle</v-icon>
          <a class="pl-1" target="_blank" href="https://confluence.app.medcity.net/c/display/EAM1/Lab+%28API+Creator%29+-+Create+a+Team">Need help using Teams</a>
        </v-col>
        <v-spacer />
        <v-col cols="12" md="2" id="v-step-1" v-if="adminSettings && (adminSettings.enableTeamCreation || adminSettings.isSuperUser)">
          <v-btn to="/team-builder" dark color="primary">Create a Team</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="11" sm="11" md="6" id="v-step-1" class="mr-0 pr-0">
          <v-text-field
            solo
            label="Search team by name or description"
            placeholder=""
            prepend-inner-icon="mdi-magnify"
            clearable
            v-model="search"
            :loading="isLoading"
          >
          </v-text-field>
        </v-col>
      </v-row>
    <v-divider/>
    <v-row class="mt-2">
      <v-col cols="12" v-if="teams" id="v-step-2">
        <v-row v-if="teams.length === 0 && isLoading">
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" md="4" v-for="(team, index) in teams" :key="index">
            <v-card
                hover
                ripple
                :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
                max-width="344"
                min-width="375"
                :to="'/teams/' + team.id + '/settings'"
            >
                <v-list-item>
                <!-- <v-list-item-avatar color="#971B2F"></v-list-item-avatar> -->
                <v-list-item-content >
                    <v-list-item-title class="headline api-name">{{ team.displayName }}</v-list-item-title>
                    <v-list-item-subtitle>{{ team.description }}</v-list-item-subtitle>
                </v-list-item-content>
                </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="count > 1">
      <v-col>
        <v-pagination
          v-model="page"
          :length="count"
          circle
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'Teams',
  computed: {
    ...mapGetters(['adminSettings'])
  },
  watch: {
    page: function (newValue, oldValue) {
      if (oldValue !== newValue) {
        this.getTeams()
      }
    },
    search: function () {
      clearTimeout(this.searchTimeout)

      // Make a new timeout set to go off in 800ms
      this.searchTimeout = setTimeout(() => {
        if (this.search == null) {
          this.search = ''
        }
        this.page = 1
        this.getTeams()
      }, 500)
    }
  },
  data: () => ({
    searchTimeout: null,
    teams: [ ],
    isLoading: true,
    page: 1,
    limit: 12,
    count: 0,
    search: ''
  }),
  methods: {
    getTeams: function () {
      this.isLoading = true
      axios(
        `${process.env.VUE_APP_BASEURL}/teams?showOwnedTeamsOnly=true&page=${this.page}&limit=${this.limit}&search=${this.search}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isLoading = false
            this.teams = response.data.items
            this.count = Math.ceil(response.data.totalCount / this.limit)
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getTeams() })
          })
    },
    routeToTeam: function (id) {
      this.$router.push(`/teams/${id}`)
    }
  },
  mounted: function () {
    this.getTeams()
    this.$store.commit('breadcrumbs', [
      {
        text: 'My Teams',
        exact: true,
        disabled: false,
        to: '/teams'
      }
    ])
  }
}
</script>

<style scoped>

</style>
