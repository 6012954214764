<template>
  <v-container>
    <v-row>
      <v-col>
          <h2>Policies</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>To create policy please first click <a :href="getSharedFlowLink()">here</a> and build the shared flow. These details are required when adding policy into the catalog</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="2">
        <v-btn @click="addPolicy" color="info">Add Policy</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="policyHeaders"
          :items="policies"
          :items-per-page="15"
          v-if="!loadingPolicies"
        >
          <template v-slot:item.isSharedFlow="{ item }">
            {{ item.isSharedFlow ? "Yes" : "No" }}
          </template>
          <template v-slot:item.policyType="{ item }">
            {{ getPolicyType(item) }}
          </template>
          <template v-slot:item.isPublic="{ item }">
            {{ item.isPublic ? "Yes" : "No" }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn icon @click="editPolicy(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon @click="deletePolicyDialog(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
        <v-dialog v-model="displayPolicyDialog" width="1500">
      <v-card>
        <v-card-title :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'" primary-title>Create/Update Policy</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="policy.name" label="Name" />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="policy.displayName" label="Display Name" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-select :items="policyTypes" v-model="policy.policyType" item-value="value" item-text="key" label="Policy Type" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" v-if="adminSettings.isSuperUser">
                <v-switch v-model="policy.isPublic" label="Publically Available"></v-switch>
              </v-col>
              <v-col cols="12" md="6" v-if="!policy.isPublic">
                <v-select
                  v-model="policy.affiliates"
                  :items="allowedAffiliates"
                  item-text="displayName"
                  item-value="id"
                  attach
                  chips
                  label="Affiliates"
                  multiple
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-textarea v-model="policy.description" label="Description" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-switch v-model="policy.allowFlowName" label="Allow FlowName Specification"></v-switch>
              </v-col>
              <v-col cols="12" md="6">
                <v-switch v-model="policy.overwriteOnEdit" label="Overwrite Policy on Edit"></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-switch v-model="policy.isEnabled" label="Enabled"></v-switch>
              </v-col>
              <v-col cols="12" md="6">
                <v-switch v-model="policy.isSharedFlow" label="Is Shared Flow"></v-switch>
              </v-col>
            </v-row>
            <v-row v-if="policy.isSharedFlow">
              <v-col>
                <b>Shared Flow Properties</b>
              </v-col>
            </v-row>
            <v-row v-if="policy.isSharedFlow">
              <v-col cols="12" md="6">
                <v-text-field v-model="policy.sharedFlowProperties.sharedFlowName" label="Apigee Shared Flow Name" />
              </v-col>
              <v-col cols="12" md="6">
                <v-select :items="policyFlowTargets" item-value="value" item-text="key" v-model="policy.sharedFlowProperties.flowTarget" label="Flow Target" />
              </v-col>
            </v-row>
            <v-row v-if="policy.isSharedFlow">
              <v-col cols="12" md="6">
                <v-select :items="policyEndpointTargets" item-value="value" item-text="key" v-model="policy.sharedFlowProperties.endpointTarget" label="Endpoint Target" />
              </v-col>
              <v-col cols="12" md="6">
                <v-select :items="policyFlowStageTargets" item-value="value" item-text="key" v-model="policy.sharedFlowProperties.flowStageTarget" label="Flow Stage Target" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <b>Policy Properties</b>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" col="2">
                <v-btn @click="policy.properties.push({})">Add Property</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Name</th>
                        <th class="text-left">Display Name</th>
                        <th class="text-left">Policy Property Type</th>
                        <th class="text-left">Pre-defined Value</th>
                        <th class="text-left">Allow User Defined</th>
                        <th class="text-left">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(property, index) in policy.properties" :key="index">
                        <td><v-text-field v-model="property.name" /></td>
                        <td><v-text-field v-model="property.displayName" /></td>
                        <td><v-select :items="policyPropertyTypes" item-value="value" item-text="key" v-model="property.policyPropertyType" /></td>
                        <td><v-text-field v-model="property.value" /></td>
                        <td><v-switch v-model="property.allowUserDefined" /></td>
                        <v-btn icon @click="policy.properties.splice(index, 1)"><v-icon>mdi-delete</v-icon></v-btn>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="displayPolicyDialog = false">Cancel</v-btn>
          <v-btn color="primary" :loading="savingPolicy" :disabled="savingPolicy" text @click="createOrUpdatePolicy">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'admin-api-policies',
  computed: {
    ...mapGetters(['adminSettings']),
    allowedAffiliates: {
      get: function () {
        if (this.adminSettings.isSuperUser) {
          return this.affiliates
        } else {
          return this.affiliates.filter(x => {
            return this.adminSettings.adminAffiliates.includes(x.id)
          })
        }
      }
    }
  },
  data: () => ({
    affiliates: [ ],
    displayPolicyDialog: false,
    policyHeaders: [
      {
        text: 'Name',
        align: 'left',
        sortable: true,
        value: 'displayName'
      },
      {
        text: 'Shared Flow',
        align: 'left',
        sortable: true,
        value: 'isSharedFlow'
      },
      {
        text: 'Policy Type',
        align: 'left',
        sortable: true,
        value: 'policyType'
      },
      {
        text: 'Is Public',
        align: 'left',
        sortable: true,
        value: 'isPublic'
      },
      {
        text: 'Actions',
        align: 'left',
        sortable: true,
        value: 'actions'
      }
    ],
    policy: {
      sharedFlowProperties: { },
      properties: [ ]
    },
    savingPolicy: false,
    policies: [ ],
    policyFlowTargets: [
      {
        key: 'Pre-Flow',
        value: 0
      },
      {
        key: 'Post-Flow',
        value: 1
      }
    ],
    policyEndpointTargets: [
      {
        key: 'Proxy',
        value: 0
      },
      {
        key: 'Target',
        value: 1
      }
    ],
    policyFlowStageTargets: [
      {
        key: 'Request',
        value: 0
      },
      {
        key: 'Response',
        value: 1
      }
    ],
    policyTypes: [
      {
        key: 'Endpoint Security',
        value: 0
      },
      {
        key: 'Proxy Security',
        value: 1
      },
      {
        key: 'Generic',
        value: 2
      },
      {
        key: 'System Policies',
        value: 3
      }
    ],
    policyPropertyTypes: [
      {
        key: 'String',
        value: 0
      },
      {
        key: 'Number',
        value: 1
      },
      {
        key: 'Bool',
        value: 2
      },
      {
        key: 'Password',
        value: 3
      }
    ],
    loadingPolicies: false
  }),
  methods: {
    getAffiliates: function () {
      this.loadingAffiliates = true
      return axios(
        `${process.env.VUE_APP_BASEURL}/affiliates/`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.affiliates = response.data
            this.loadingAffiliates = false
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getAffiliates() })
            return error
          })
    },
    getSharedFlowLink: function () {
      return `${process.env.VUE_APP_BASEMANAGEMENTURL}/organizations/hca/sharedflows/`
    },
    getPolicies: function () {
      this.loadingPolicies = true
      axios(
        `${process.env.VUE_APP_BASEURL}/policy/admin`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.policies = response.data
            this.loadingPolicies = false
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getPolicies() })
            return error
          })
    },
    editPolicy: function (item) {
      this.policy = item
      if (!this.policy.properties) {
        this.policy.properties = [ ]
      }
      if (!this.policy.sharedFlowProperties) {
        this.policy.sharedFlowProperties = { }
      }
      this.displayPolicyDialog = true
    },
    deletePolicyDialog: function (item) {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to delete this policy. This action can not be undone',
        confirmMethod: () => {
          this.deletePolicy(item)
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    deletePolicy: function (item) {
      axios(
        `${process.env.VUE_APP_BASEURL}/policy/${item.id}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.getPolicies()
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.deletePolicy() })
            return error
          })
    },
    getPolicyType: function (item) {
      var policyType = this.policyTypes.filter(x => x.value === item.policyType)

      if (policyType) {
        return policyType[0].key
      }

      return item.policyType
    },
    addPolicy: function () {
      this.policy = {
        sharedFlowProperties: { },
        properties: [ ]
      }
      this.displayPolicyDialog = true
    },
    createOrUpdatePolicy: function () {
      this.savingPolicy = true

      if (this.policy.id) {
        axios(
          `${process.env.VUE_APP_BASEURL}/policy/${this.policy.id}`,
          {
            method: 'PUT',
            data: JSON.stringify(this.policy),
            headers: { 'Content-Type': 'application/json' }
          })
          .then(
            (response) => {
              this.savingPolicy = false
              this.getPolicies()
              this.displayPolicyDialog = false
            },
            (error) => {
              this.savingPolicy = false
              this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.createOrUpdatePolicy() })
              return error
            })
      } else {
        axios(
          `${process.env.VUE_APP_BASEURL}/policy`,
          {
            method: 'POST',
            data: JSON.stringify(this.policy),
            headers: { 'Content-Type': 'application/json' }
          })
          .then(
            (response) => {
              this.savingPolicy = false
              this.getPolicies()
              this.displayPolicyDialog = false
            },
            (error) => {
              this.savingPolicy = false
              this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.createOrUpdatePolicy() })
              return error
            })
      }
    }
  },
  mounted: function () {
    this.getAffiliates().then(() => {
      this.getPolicies()
    })
  }
}
</script>

<style scoped>

</style>
