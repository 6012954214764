<template>
  <v-container fluid>
    <v-form ref="form">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="10">
          <v-row>
            <v-col cols="12" sm="6" class="d-flex justify-start align-center">
              <v-skeleton-loader
                v-if="!template"
                class="ma-2"
                style="height: 90px; width: 90px;"
                type="image"
              ></v-skeleton-loader>
              <transition name="fade-transition" mode="out-in">
                <v-img
                  key="edit-image"
                  v-if="template"
                  class="mb-1"
                  max-height="100"
                  max-width="100"
                  min-height="100"
                  min-width="100"
                  src="@/assets/template.svg"
                  contain
                  style="width: 100px"
                />
              </transition>
              <v-skeleton-loader
                v-if="!template"
                type="list-item-two-line"
                class="badge-name-loading"
                style="width: 500px"
              ></v-skeleton-loader>
              <transition name="slide-y-transition" mode="out-in" v-if="template">
                <div>
                    <span class="ma-2 text-h5" key="edit-display-off">{{ template.displayName }}</span>
                    <br/>
                    <span class="ma-2 text-subtitle2" key="edit-display-off2">{{ template.description }}</span>
                    <br/>
                    <transition name="slide-y-transition" mode="out-in" v-if="template">
                      <div v-if="templateType && templateRelease && templateRelease.releaseId !== ''">
                        <v-chip class="ma-2">{{ templateRelease.releaseId }}</v-chip>
                        <v-chip v-if="templateType" :color="templateType.color" class="ma-2">{{ templateType.name }}</v-chip>
                      </div>
                    </transition>
                </div>
              </transition>
            </v-col>
            <v-spacer />
            <div style="margin-right: 25px;">
              <v-tooltip bottom v-if="installation.installationStatus && installation.installationStatus.isComplete && installation.installationStatus.isSuccess">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    x-large
                    v-bind="attrs"
                    v-on="on"
                    color="success"
                    @click="retryInstallPrompt"
                  >
                    mdi-source-branch-check
                  </v-icon>
                </template>
                <span>This installation completed successfully</span>
              </v-tooltip>
              <v-tooltip bottom v-if="installation.installationStatus && installation.installationStatus.isComplete && !installation.installationStatus.isSuccess">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    x-large
                    v-bind="attrs"
                    v-on="on"
                    color="error"
                    @click="retryInstallPrompt"
                  >
                    mdi-source-branch-remove
                  </v-icon>
                </template>
                <span>This installation failed. Click to retry</span>
              </v-tooltip>
              <v-tooltip bottom v-if="!installation.installationStatus || !installation.installationStatus.isComplete">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    x-large
                    v-bind="attrs"
                    v-on="on"
                    color="gray"
                  >
                    mdi-source-branch-sync
                  </v-icon>
                </template>
                <span>This installation is running</span>
              </v-tooltip>
            </div>
          </v-row>
      </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="10" xl="10">
          <div class="tab-item-wrapper">
            <v-row v-if="!isLoading && installation.installationStatus">
              <v-col cols="12" md="12">
                <p><span class="text-overline">Status</span></p>
                <v-card
                  class="mx-auto"
                  outlined
                >
                  <v-card-text>
                    <v-container fluid>
                      <v-row>
                        <v-col>
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">
                                    Triggered
                                  </th>
                                  <th class="text-left">
                                    Triggered By
                                  </th>
                                  <th class="text-left">
                                    Status
                                  </th>
                                  <th class="text-left">
                                    Total Duration
                                  </th>
                                  <th class="text-left">
                                    Feature Branch
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{{ getDateString(installation.installationStatus.initiatedDateTimeUtc) }}</td>
                                  <td>{{ installation.meta.createdBy.firstName }} {{ installation.meta.createdBy.lastName }}</td>
                                  <td>{{ installationStatus }}</td>
                                  <td>{{ installationDuration }}</td>
                                  <td><a :href="`https://github.com/${installation.githubOrganizationName}/${installation.repositoryName}/tree/${installation.installationStatus.featureBranchName}`">{{ installation.installationStatus.featureBranchName }}</a></td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="12" v-if="installation.installationStatus.log">
                <p><span class="text-overline">Installation Log</span></p>
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="(item,i) in installation.installationStatus.log"
                    :key="i"
                  >
                    <v-expansion-panel-header disable-icon-rotate>
                      {{ item.stepName }}
                      <v-spacer/>
                      <div class="step-duration"><span v-if="item.inititatedDateTimeUtc && item.completedDateTimeUtc"> {{ getStepDurationTime(item.inititatedDateTimeUtc, item.completedDateTimeUtc) }}</span></div>
                      <template v-slot:actions>
                        <v-icon color="gray" v-if="item.status === 0">
                          mdi-checkbox-blank-circle-outline
                        </v-icon>
                        <v-progress-circular
                          v-if="item.status === 1"
                          indeterminate
                          color="gray"
                          size="18"
                          width="2"
                          style="margin-right: 4px;"
                        ></v-progress-circular>
                        <v-icon color="success" v-if="item.status === 2">
                          mdi-check-circle-outline
                        </v-icon>
                        <v-icon color="error" v-if="item.status === 3">
                          mdi-close-circle-outline
                        </v-icon>
                        <v-icon color="gray" v-if="item.status === 4">
                          mdi-minus-circle-outline
                        </v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content color="grey darken-4">
                      <span class="log-content">{{ item.response }}</span>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" md="12">
                <p><span class="text-overline">Status</span></p>
                <v-card
                  class="mx-auto"
                  outlined
                >
                  <v-card-text>
                    <v-container fluid>
                      <v-row>
                        <v-col>
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">
                                    Triggered
                                  </th>
                                  <th class="text-left">
                                    Triggered By
                                  </th>
                                  <th class="text-left">
                                    Status
                                  </th>
                                  <th class="text-left">
                                    Total Duration
                                  </th>
                                  <th class="text-left">
                                    Feature Branch
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Your installation has been queued and will start momentarily...</td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'editTemplate',
  computed: {
    ...mapGetters(['adminSettings', 'oidcUser']),
    hasAdminRole: {
      get: function () {
        return this.adminSettings.isSuperUser || (this.team && this.team.memberEmails.find(x => x.toLowerCase() === this.oidcUser.emailAddress.toLowerCase()))
      }
    },
    installationStatus: {
      get: function () {
        if (this.installation.installationStatus) {
          if (this.installation.installationStatus.isComplete && this.installation.installationStatus.isSuccess) {
            return 'Successful'
          } else if (this.installation.installationStatus.isComplete && !this.installation.installationStatus.isSuccess) {
            return 'Failed'
          } else if (!this.installation.installationStatus.isComplete) {
            return 'In Progress'
          }
        }
        return 'Queued'
      }
    },
    templateType: {
      get: function () {
        if (this.template) {
          if (this.template.templateType === 0) {
            return {
              name: 'Github Action',
              color: '#B2EBF2'
            }
          } else if (this.template.templateType === 1) {
            return {
              name: 'Terraform Module',
              color: '#FFECB3'
            }
          } else {
            return {
              name: 'Project',
              color: '#B39DDB'
            }
          }
        } else {
          return null
        }
      }
    }
  },
  data: () => ({
    installationDuration: null,
    installationDurationTimeout: null,
    templateId: null,
    template: null,
    installation: {
      installationStatus: {
        initiatedDateTimeUtc: null
      }
    },
    templateRelease: null,
    team: null,
    isLoading: false,
    installationTimeout: null
  }),
  methods: {
    retryInstallPrompt: function () {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to retry this installation?',
        confirmMethod: () => {
          this.retryInstall()
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    retryInstall: function () {
      return axios(
        `${process.env.VUE_APP_TEMPLATEBASEURL}/api/templates/installs/${this.installationId}`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.installationId = response.data.id
            this.getInstallation()
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.retryInstall() })
          })
    },
    getDateString: function (date) {
      return moment(date).calendar()
    },
    getInstallation: function () {
      return axios(
        `${process.env.VUE_APP_TEMPLATEBASEURL}/api/templates/installs/${this.installationId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.installation = response.data
            if (!this.templateRelease) {
              this.getTemplateRelease()
            }
            this.getInstallationDuration()
            if (!this.installation.installationStatus || !this.installation.installationStatus.isComplete) {
              this.installationTimeout = setTimeout(() => {
                this.getInstallation()
              }, 5000)
            }
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getInstallation() })
          })
    },
    getTemplate: function () {
      this.isLoading = true
      return axios(
        `${process.env.VUE_APP_TEMPLATEBASEURL}/api/templates/${this.templateId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.template = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getTemplate() })
          })
    },
    getTemplateRelease: function () {
      return axios(
        `${process.env.VUE_APP_TEMPLATEBASEURL}/api/templates/releases/${this.installation.templateReleaseId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isLoading = false
            this.templateRelease = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getTemplateRelease() })
          })
    },
    getTeam: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/teams/${this.template.teamId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.team = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getTeam() })
          })
    },
    getStepDurationTime: function (start, end) {
      let startTime = moment(start)
      let endTime = moment(end)

      return this.formatDuration(moment.duration(endTime.diff(startTime)))
    },
    getInstallationDuration: function () {
      if (this.installation.installationStatus) {
        if (this.installation.installationStatus.completedDateTimeUtc) {
          let startTime = moment(this.installation.installationStatus.initiatedDateTimeUtc)
          let endTime = moment(this.installation.installationStatus.completedDateTimeUtc)

          this.installationDuration = this.formatDuration(moment.duration(endTime.diff(startTime)))
        } else {
          let startTime = moment(this.installation.installationStatus.initiatedDateTimeUtc)
          let endTime = moment()
          let diff = endTime.diff(startTime)

          this.installationDuration = this.formatDuration(moment.duration(diff))
          this.installationDurationTimeout = setTimeout(() => {
            this.getInstallationDuration()
          }, 1000)
        }
      }
      return ''
    },
    formatDuration: function (duration) {
      let dateString = ''
      if (duration.days()) {
        dateString += `${duration.days()}d `
      }
      if (duration.hours()) {
        dateString += `${duration.hours()}h `
      }
      if (duration.minutes()) {
        dateString += `${duration.minutes()}m `
      }
      if (duration.seconds()) {
        dateString += `${duration.seconds()}s`
      }
      if (!duration.seconds()) {
        dateString = '<1s'
      }
      return dateString
    }
  },
  beforeMount: function () {
    if (this.$route.params.templateId && this.$route.params.installationId) {
      this.templateId = this.$route.params.templateId
      this.installationId = this.$route.params.installationId
      this.getInstallation()
      this.getTemplate().then(() => {
        this.$store.commit('breadcrumbs', [
          {
            text: 'Templates',
            exact: true,
            disabled: false,
            to: '/templates'
          },
          {
            text: `${this.template.displayName}`,
            exact: true,
            disabled: false,
            to: `/templates/${this.templateId}`
          },
          {
            text: `Installation`,
            exact: true,
            disabled: true,
            to: `/templates/${this.templateId}/installs/${this.installationId}`
          }
        ])
      })
    }
  },
  onDestroy: function () {
    clearTimeout(this.installationDurationTimeout)
    clearTimeout(this.installationTimeout)
  }
}
</script>

<style scoped>
.group-name {
  padding-top: 35px;
}

.group-name-loading {
  padding-top: 15px;
}

.loading-name {
  width: 150px;
}

.no-transition {
  transition: none;
}

.image-select {
  cursor: pointer;
}

.tab-item-wrapper {
  /* vuetify sets the v-tabs__container height to 48px */
  min-height: calc(100vh - 220px);
  padding: 15px;
}

.heading-input {
  height: 50px !important;
}
.step-duration {
  max-width: 50px;
}
.log-content {
  color: #fff !important;
  padding-top: 15px;
  display: block;
}
</style>
