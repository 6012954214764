<template>
  <div>
    <v-navigation-drawer
      absolute
      :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
      permanent
      v-if="teamId"
    >
      <v-list dense nav>
       <template v-for="item in links">
        <v-list-item
          link
          v-if="!item.subItems"
          :to="generateLink(item.link)"
          :key="item.title"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <template v-if="item.subItems">
          <v-list-group
            :key="item.title"
            :prepend-icon="item.icon"
            no-action
            :value = "shouldExpand(item)"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-if="item.subItems">
            <v-list-item
              v-for="child in item.subItems"
              :key="child.title"
              link
              :to="generateLink(child.link)"
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            </template>
          </v-list-group>
        </template>
       </template>
      </v-list>
    </v-navigation-drawer>
    <div style="margin-left: 256px">
      <v-row>
        <v-col cols="12" md="12">
            <h1>{{ team.displayName }}</h1>
            <v-skeleton-loader
              v-if="!team.displayName"
              class="mx-auto mb-2"
              min-width="500"
              type="heading"
            ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-divider />
      <transition name="fade-transition" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  name: 'EditTeam',
  watch: {
  },
  computed: {
    ...mapGetters(['adminSettings']),
    ...mapGetters('teams', ['team', 'teamId']),
    ...mapState(['teams']),
    active: function () {
      return this.links.subItems.filter(function (child) { return child })
    },
    isCloudAccountCreationEnabled: {
      get: function () {
        return this.team.enableCloudAccountCreation
      }
    }
  },
  data: () => ({
    links: [
      {
        icon: 'mdi-cog',
        title: 'General',
        link: `settings`,
        subItems: [
          {
            icon: 'mdi-cog',
            title: 'Settings',
            link: `settings`,
            display: { val: '' }
          }
          /* {
            icon: 'mdi-cog',
            title: 'Team Cloud Account',
            link: `team-cloud-account`,
            display: { val: '' }
          } */
        ]
      },
      {
        icon: 'mdi-application',
        title: 'Products',
        link: `products`
      },
      {
        icon: 'mdi-book',
        title: 'Repositories',
        link: `repositories`
      },
      {
        icon: 'mdi-stamper',
        title: 'Templates',
        link: `templates`
      },
      {
        icon: 'mdi-github',
        title: 'Github Organizations',
        link: `github-organizations`
      }
    ]
  }),
  methods: {
    ...mapActions({
      setTeamId: 'teams/setTeamId',
      getTeam: 'teams/getTeam',
      resetTeam: 'teams/resetTeam',
      getAffiliates: 'affiliates/getAffiliates',
      resetAffiliates: 'affiliates/resetAffiliates'
    }),
    display (items) {
      items[0].val = true
      items[1].val = this.isCloudAccountCreationEnabled

      return items.filter(item => item.val === true)
    },
    shouldExpand: function (item) {
      let path = this.$router.currentRoute.path
      let subItemPath = item.subItems.find(x => {
        return this.generateLink(x.link) === path
      })

      if (subItemPath) {
        return true
      }

      return false
    },
    generateLink: function (subItem) {
      return `/teams/${this.teamId}/${subItem}`
    }
  },
  mounted: function () {
    if (this.$route.params.id) {
      this.setTeamId(this.$route.params.id)
      this.getAffiliates()
      this.getTeam(() => {
        this.$store.commit('breadcrumbs', [
          {
            text: 'My Teams',
            exact: true,
            disabled: false,
            to: '/teams'
          },
          {
            text: this.team.displayName,
            exact: true,
            disabled: false,
            to: `/teams/${this.teamId}/settings`
          }
        ])
      })
    } else {
      alert('could not find team as an ID was not provided in the url')
    }
  },
  destroyed: function () {
    this.resetTeam()
    this.resetAffiliates()
  }
}
</script>

<style scoped>
  .main-app-view {
    margin-top: 15px;
  }
  .user-table {
    border: 1px solid rgba(0, 0, 0, 0.4);
  }
  .key-table {
    /* border: 1px solid rgba(0, 0, 0, 0.4);
    padding: 5px; */
    margin: 2px;
    margin-bottom: 15px;
  }
  .user-error {
    font-size: 12px;
  }

  .visible {
    margin-bottom: 8px;
  }

#v-step-8 {
  max-width: 200px;
}

</style>
