<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <div class="key-table">
          <h3>Internal App Keys</h3>
          <v-skeleton-loader
            v-if="internalEnvironmentAppKeys.length === 0"
            class="mx-auto"
            min-width="500"
            type="list-item-two-line"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-if="internalEnvironmentAppKeys.length === 0"
            class="mx-auto"
            min-width="500"
            type="list-item-two-line"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-if="internalEnvironmentAppKeys.length === 0"
            class="mx-auto"
            min-width="500"
            type="list-item-two-line"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-if="internalEnvironmentAppKeys.length === 0"
            class="mx-auto"
            min-width="500"
            type="list-item-two-line"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-if="internalEnvironmentAppKeys.length === 0"
            class="mx-auto"
            min-width="500"
            type="list-item-two-line"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-if="internalEnvironmentAppKeys.length === 0"
            class="mx-auto"
            min-width="500"
            type="list-item-two-line"
          ></v-skeleton-loader>
          <v-list-item two-line v-for="(env, index) in internalEnvironmentAppKeys" :key="index">
            <v-list-item-content>
              <v-list-item-title><b id="v-step-3">{{ env.displayName }}</b></v-list-item-title>
              <v-list-item-subtitle id="v-step-4">{{ env.baseUrl }}</v-list-item-subtitle>
              <v-list-item-subtitle>
                <v-row id="v-step-5">
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="env.key.consumerKey"
                      type="text"
                      name="Consumer Key"
                      label="Consumer Key"
                      readonly=""
                    >
                      <template v-slot:append>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn id="v-step-6" v-on:mouseleave="copyMouseLeave" v-on="on" v-clipboard:copy="env.key.consumerKey" v-clipboard:success="copyMouseClick" icon><v-icon >mdi-clipboard-text-play-outline</v-icon></v-btn>
                          </template>
                            <span> {{ copyText }}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="env.key.consumerSecret"
                      :type="env.key.showKeys ? 'text' : 'password'"
                      name="Consumer Secret"
                      label="Consumer Secret"
                      append-icon="mdi-clipboard-text-play-outline"
                      readonly=""
                    >
                      <template v-slot:append>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn v-on:mouseleave="copyMouseLeave" v-on="on" v-clipboard:copy="env.key.consumerSecret" v-clipboard:success="copyMouseClick" icon><v-icon >mdi-clipboard-text-play-outline</v-icon></v-btn>
                          </template>
                            <span> {{ copyText }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on" id="v-step-7" icon @click="env.key.showKeys = !env.key.showKeys"><v-icon>{{ !env.key.showKeys ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon></v-btn>
                          </template>
                          <span>Show/hide consumer secret</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" id="v-step-8">
                    <v-btn :disabled="generatingToken" @click="generateToken(env.id)" outlined class="generate-token">Generate Token</v-btn>
                  </v-col>
                  <v-col cols="12" md="2" id="v-step-9">
                    <v-btn :disabled="isEditDisabled || resettingKeys.includes(env.key.consumerKey) || (!adminSettings.enableRegerationOfTokens  && !adminSettings.isSuperUser)" :loading="resettingKeys.includes(env.key.consumerKey)" @click="resetKeyDialog(env.key.consumerKey)" outlined class="generate-token" color="error">Reset Key</v-btn>
                  </v-col>
                </v-row>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          </div>
          <div class="key-table">
          <h3>External App Keys</h3>
          <v-skeleton-loader
            v-if="internalEnvironmentAppKeys.length === 0"
            class="mx-auto"
            min-width="500"
            type="list-item-two-line"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-if="internalEnvironmentAppKeys.length === 0"
            class="mx-auto"
            min-width="500"
            type="list-item-two-line"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-if="internalEnvironmentAppKeys.length === 0"
            class="mx-auto"
            min-width="500"
            type="list-item-two-line"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-if="internalEnvironmentAppKeys.length === 0"
            class="mx-auto"
            min-width="500"
            type="list-item-two-line"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-if="internalEnvironmentAppKeys.length === 0"
            class="mx-auto"
            min-width="500"
            type="list-item-two-line"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-if="internalEnvironmentAppKeys.length === 0"
            class="mx-auto"
            min-width="500"
            type="list-item-two-line"
          ></v-skeleton-loader>
          <v-list-item two-line v-for="(env, index) in externalEnvironmentAppKeys" :key="index">
            <v-list-item-content>
              <v-list-item-title><b id="v-step-3">{{ env.displayName }}</b></v-list-item-title>
              <v-list-item-subtitle id="v-step-4">{{ env.baseUrl }}</v-list-item-subtitle>
              <v-list-item-subtitle>
                <v-row id="v-step-5">
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="env.key.consumerKey"
                      type="text"
                      name="Consumer Key"
                      label="Consumer Key"
                      readonly=""
                    >
                      <template v-slot:append>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn id="v-step-6" v-on:mouseleave="copyMouseLeave" v-on="on" v-clipboard:copy="env.key.consumerKey" v-clipboard:success="copyMouseClick" icon><v-icon >mdi-clipboard-text-play-outline</v-icon></v-btn>
                          </template>
                            <span> {{ copyText }}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="env.key.consumerSecret"
                      :type="env.key.showKeys ? 'text' : 'password'"
                      name="Consumer Secret"
                      label="Consumer Secret"
                      append-icon="mdi-clipboard-text-play-outline"
                      readonly=""
                    >
                      <template v-slot:append>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn v-on:mouseleave="copyMouseLeave" v-on="on" v-clipboard:copy="env.key.consumerSecret" v-clipboard:success="copyMouseClick" icon><v-icon >mdi-clipboard-text-play-outline</v-icon></v-btn>
                          </template>
                            <span> {{ copyText }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on" id="v-step-7" icon @click="env.key.showKeys = !env.key.showKeys"><v-icon>{{ !env.key.showKeys ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon></v-btn>
                          </template>
                          <span>Show/hide consumer secret</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" id="v-step-9">
                    <v-btn :disabled="isEditDisabled || resettingKeys.includes(env.key.consumerKey) || (!adminSettings.enableRegerationOfTokens  && !adminSettings.isSuperUser)" :loading="resettingKeys.includes(env.key.consumerKey)" @click="resetKeyDialog(env.key.consumerKey)" outlined class="generate-token" color="error">Reset Key</v-btn>
                  </v-col>
                </v-row>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import marked from 'marked'
import userSearchComponent from '@/components/userSearchComponent'
import groupSearchComponent from '@/components/groupSearchComponent'

export default {
  name: 'productKeys',
  computed: {
    ...mapGetters(['adminSettings']),
    internalEnvironmentAppKeys: function () {
      let keys = [ ]
      if (this.environments) {
        let envs = this.environments.filter(x => {
          return x.isInternetAccessible === false
        })

        envs.forEach(x => {
          if (this.product && this.product.appKeys) {
            let key = this.product.appKeys.find(y => {
              return y.environmentName === x.name
            })
            x.key = key
            keys.push(x)
          }
        })
      }
      return keys
    },
    externalEnvironmentAppKeys: function () {
      let keys = [ ]
      if (this.environments) {
        let envs = this.environments.filter(x => {
          return x.isInternetAccessible === true
        })

        envs.forEach(x => {
          if (this.product && this.product.appKeys) {
            let key = this.product.appKeys.find(y => {
              return y.environmentName === x.name
            })
            x.key = key
            keys.push(x)
          }
        })
      }
      return keys
    },
    isEditDisabled: function () {
      return this.product.isApiManagedOnly
    }
  },
  data: () => ({
    resettingKeys: [ ],
    validated: true,
    generatingToken: false,
    recreatingToken: false,
    copyText: 'Copy to clipboard',
    environments: [ ],
    productId: '',
    userError: false,
    isSubmitted: false,
    product: {
      displayName: '',
      description: '',
      allowedUsers: [ ],
      subscriptions: [ ]
    },
    affiliates: [ ]
  }),
  methods: {
    resetKeyDialog: function (consumerKey) {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to reset this key? This action cannot be undone. You will need to update the consumer key and secret in all your applications utilizing this Key/Secret',
        confirmMethod: () => {
          this.resetKey(consumerKey)
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    resetKey: function (consumerKey) {
      this.recreatingToken = true
      this.resettingKeys.push(consumerKey)
      return axios(
        `${process.env.VUE_APP_BASEURL}/product/${this.productId}/token/${consumerKey}/`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          () => {
            this.getProduct()
            this.recreatingToken = false
            this.resettingKeys = this.resettingKeys.filter(x => {
              return x !== consumerKey
            })
            this.$store.commit('triggerSnack', { snackMessage: 'The token has been revoked and recreated successfully', snackColor: 'success' })
          },
          (error) => {
            this.resettingKeys = this.resettingKeys.filter(x => {
              return x !== consumerKey
            })
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.resetKey(consumerKey) })
          })
    },
    generateToken: function (environmentId) {
      this.generatingToken = true
      return axios(
        `${process.env.VUE_APP_BASEURL}/product/${this.productId}/environments/${environmentId}/token`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.$copyText(response.data.access_token).then(x => {
              this.$store.commit('triggerSnack', { snackMessage: 'The token has been copied to your clipboard', snackColor: 'success' })
              this.generatingToken = false
            }, function (e) {
              this.$store.commit('triggerSnack', { snackMessage: 'Failed to obtain token. Please try again later', snackColor: 'error' })
              this.generatingToken = false
            })
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.generateToken(environmentId) })
            this.generatingToken = false
          })
    },
    copyMouseClick: function () {
      this.copyText = 'Copied!'
    },
    copyMouseLeave: function () {
      setTimeout(x => {
        this.copyText = 'Copy to clipboard'
      }, 200)
    },
    validate: function () {
      this.validated = true
      this.userError = false
      if (this.allowedUsers.length <= 0 && this.allowedGroups.length <= 0) {
        this.userError = true
        this.validated = false
      }
      if (!this.$refs.form.validate()) {
        this.validated = false
      }
      return this.validated
    },
    getUserDetails: function (username) {
      return axios(
        `${process.env.VUE_APP_BASENETWORKAPIURL}/activedirectory/search/user?searchText=${username}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getUserDetails() })
          })
    },
    getProduct: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/product/${this.productId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            var res = response.data
            res.appKeys.forEach(x => {
              x.showKeys = false
            })

            this.product = res
            if (this.product.allowedUsers) {
              this.allowedUsers = [ ]
              this.product.allowedUsers.forEach(x => {
                this.getUserDetails(x).then(x => {
                  if (x) {
                    this.allowedUsers.push(x[0])
                  }
                })
              })
            }

            if (this.product.allowedGroups) {
              this.allowedGroups = this.product.allowedGroups
            }
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getProduct() })
          })
    },
    getEnvironments: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/environments?internetAccessible=true`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.environments = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getEnvironments() })
          })
    }
  },
  mounted: function () {
    if (this.$route.params.id) {
      this.productId = this.$route.params.id
      this.getProduct().then(() => {
        this.$store.commit('breadcrumbs', [
          {
            text: 'My Products',
            exact: true,
            disabled: false,
            to: '/products'
          },
          {
            text: this.product.displayName,
            exact: true,
            disabled: false,
            to: `/products/${this.productId}`
          },
          {
            text: 'keys',
            exact: true,
            disabled: false,
            to: `/products/${this.productId}/keys`
          }
        ])
      })
      this.getEnvironments()
    } else {
      alert('could not find product as an ID was not provided in the url')
    }
  }
}
</script>

<style scoped>
  .user-table {
    border: 1px solid rgba(0, 0, 0, 0.4);
  }
  .key-table {
    /* border: 1px solid rgba(0, 0, 0, 0.4);
    padding: 5px; */
    margin: 2px;
    margin-bottom: 15px;
  }
  .user-error {
    font-size: 12px;
  }

  .api-name {
    margin-bottom: 10px;
  }

  .visible {
    margin-bottom: 8px;
  }

  .generate-token {
    margin-top:15px;
    padding-left: 5px;
    padding-right: 5px;
    min-width: 150px !important;
  }
#v-step-8 {
  max-width: 200px;
}

</style>
