<template>
  <v-container fluid>
    <v-form ref="form">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="10">
          <v-row>
            <v-col cols="12" sm="6" class="d-flex justify-start align-center">
              <v-skeleton-loader v-if="!cloudAccount" class="ma-2" style="height: 90px; width: 90px;" type="image" />
              <transition name="fade-transition" mode="out-in">
                <v-img key="edit-image" v-if="cloudAccount" class="mb-1" max-height="100" max-width="100" min-height="100" min-width="100" src="@/assets/gcp.png" contain style="width: 100px" />
              </transition>

              <v-skeleton-loader v-if="!cloudAccount" type="list-item-two-line" class="badge-name-loading" style="width: 500px" />

              <transition name="slide-y-transition" mode="out-in" v-if="cloudAccount && typeof cloudAccount === 'object'">
                <div>
                  <p class="ma-2 text-h5" key="edit-display-off"><strong>{{ cloudAccount.displayName }}</strong></p>
                  <p class="ma-2 text-subtitle2" key="edit-display-off2"><strong>App Code:</strong> {{ cloudAccount.appCode }}</p>
                  <p class="ma-2 text-subtitle2" key="edit-display-off3"><strong>Created On:</strong> {{ Object.prototype.hasOwnProperty.call(cloudAccount, 'meta') && Object.prototype.hasOwnProperty.call(cloudAccount.meta, 'createdOnUtc') ? getDateString(cloudAccount.meta.createdOnUtc) : 'Unknown' }}</p>
                  <p class="ma-2 text-subtitle2" key="edit-display-off4" v-if="this.cloudAccount && this.cloudAccount !== null & Object.prototype.hasOwnProperty.call(this.cloudAccount, 'approvalDetails') && Object.prototype.hasOwnProperty.call(this.cloudAccount.approvalDetails, 'status') && this.cloudAccount.approvalDetails.status === 1"><strong>Approval Status:</strong> Approved</p>
                  <p class="ma-2 text-subtitle2" key="edit-display-off5" v-else-if="this.cloudAccount && this.cloudAccount !== null & Object.prototype.hasOwnProperty.call(this.cloudAccount, 'approvalDetails') && Object.prototype.hasOwnProperty.call(this.cloudAccount.approvalDetails, 'status') && this.cloudAccount.approvalDetails.status === 2"><strong>Approval Status:</strong> Denied</p>
                  <p class="ma-2 text-subtitle2" key="edit-display-off5"><strong>Last Attempted Deployment:</strong> {{ getDateString(cloudAccount.deploymentCreatedDateTimeUtc) }}</p>
                </div>
              </transition>
            </v-col>

            <v-spacer />

            <div style="margin-right: 25px;">
              <v-tooltip bottom v-if="cloudAccount.deploymentIsComplete && cloudAccount.deploymentIsSuccess">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mt-8" size="70" v-bind="attrs" v-on="on" color="success">mdi-cloud-check</v-icon>
                </template>
                <span>This deployment completed successfully</span>
              </v-tooltip>

              <v-tooltip bottom v-if="cloudAccount.deploymentStatus == 3">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mt-8" size="70" v-bind="attrs" v-on="on" color="error" @click="retryInstallPrompt">mdi-cloud-alert</v-icon>
                </template>
                <span>This deployment failed. Click to retry</span>
              </v-tooltip>

              <v-tooltip bottom v-if="cloudAccount.deploymentStatus == 1">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mt-2" size="70" v-bind="attrs" v-on="on" color="orange">mdi-cloud-sync</v-icon>
                </template>
                <span>This deployment is running</span>
              </v-tooltip>

              <v-tooltip bottom v-if="cloudAccount.deploymentStatus == 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mt-2" size="70" v-bind="attrs" v-on="on" color="orange">mdi-cloud-search</v-icon>
                </template>
                <span>This deployment is is pending approval</span>
              </v-tooltip>
            </div>
          </v-row>
        </v-col>
      </v-row>

      <v-divider class="mt-5" />

      <v-row class="d-flex justify-center mt-5">
        <v-col cols="12" md="10" xl="10">
          <div class="tab-item-wrapper">
            <v-row v-if="!isLoading">
              <v-col cols="12" md="12">
                <p v-if="this.cloudAccount.deploymentStatus">
                  <span class="text-overline">
                    <strong>Environment Deployment Status : </strong>
                    <v-chip dark :color="getColor(this.cloudAccount.deploymentStatus)" small>{{ formatStepStatus(this.cloudAccount.deploymentStatus) }}</v-chip>
                  </span>
                </p>

                <p v-else>
                  <span class="text-overline">
                    <strong>Environment Deployment Status : </strong>
                    <v-chip dark :color="getColor(this.cloudAccount.deploymentStatus)" small> Awaiting Approval</v-chip>
                  </span>

                  <v-progress-circular v-if="this.cloudAccount.deploymentStatus === 0" class="ml-2" indeterminate color="gray" size="18" width="2" style="margin-right: 4px;" />
                </p>

                <v-card class="mx-auto" elevation="0">
                  <v-card-text>
                    <div v-if="this.cloudAccount && this.cloudAccount !== null & Object.prototype.hasOwnProperty.call(this.cloudAccount, 'approvalDetails') && Object.prototype.hasOwnProperty.call(this.cloudAccount.approvalDetails, 'status') && this.cloudAccount.approvalDetails.status === 0">Your deployment will start when it has been approved.</div>

                    <v-data-table v-else :headers="headers" :items="cloudAccount.cloudEnvironments" :single-expand="singleExpand" :expanded.sync="expanded" item-key="name" :show-expand="cloudAccount.deploymentIsComplete" class="elevation-1">
                      <template v-slot:item.createdBy="{ item }">{{ cloudAccount.meta.createdBy.createdBy}}</template>
                      <template v-slot:item.status="{ item }">
                        <div v-if="item && item !== null && typeof item === 'object' && Object.prototype.hasOwnProperty.call(item, 'installationStatus') && item.installationStatus !== null && Object.prototype.hasOwnProperty.call(item.installationStatus, 'environmentDeploymentStatus') && item.installationStatus.environmentDeploymentStatus !== null">
                          <v-chip dark class="mr-2" id="circle" :color="getColor(item.installationStatus.environmentDeploymentStatus)">{{ formatStepStatus(item.installationStatus.environmentDeploymentStatus) }}</v-chip>
                        </div>
                      </template>
                      <template v-slot:item.duration="{ item }">{{ getDeploymentDuration(item) }}</template>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                          <v-simple-table dark>
                            <template v-slot:top><v-card-title>Tasks Log</v-card-title></template>
                            <template v-slot:item.actions="{ item }"></template>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">Step</th>
                                  <th class="text-left">Status</th>
                                  <th class="text-left">Start Time</th>
                                  <th class="text-left">End Time</th>
                                  <th class="text-left">Duration</th>
                                  <th class="text-left">Response</th>
                                </tr>
                              </thead>

                              <tbody v-if="item && typeof item === 'object' && Object.prototype.hasOwnProperty.call(item, 'installationStatus') && Object.prototype.hasOwnProperty.call(item.installationStatus, 'log')">
                                <tr v-for="log in item.installationStatus.log" :key="log.name">
                                  <td>{{ log.stepName }}</td>
                                  <td>
                                    <div v-if="Object.prototype.hasOwnProperty.call(log, 'status')">
                                      <v-chip dark class="mr-2" id="circle" :color="getColor(log.status)">{{ formatStepStatus(log.status) }}</v-chip>
                                    </div>
                                  </td>
                                  <td>{{ getDateString(log.inititatedDateTimeUtc) }}</td>
                                  <td>{{ getDateString(log.completedDateTimeUtc) }}</td>
                                  <td>{{ getStepDurationTime(log.inititatedDateTimeUtc, log.completedDateTimeUtc) }}</td>
                                  <td>{{ log.response }}</td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </td>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <p v-if="cloudAccount.deploymentIsComplete && cloudAccount.deploymentIsSuccess" class="mt-5"><span class="text-overline"><strong>Environment Details:</strong> </span></p>

            <v-row v-if="cloudAccount.deploymentIsComplete && cloudAccount.deploymentIsSuccess">
              <v-col v-for="cloudEnvironment in cloudAccount.cloudEnvironments" :key="cloudEnvironment">
                <v-card class="gcp-card">
                <v-card-title primary-title>
                  Environment Name: {{cloudEnvironment.displayName}}
                </v-card-title>
                  <v-card-text>
                    <p><strong>Gcp Project Link: </strong> <a :href="'https://console.cloud.google.com/home/dashboard?project=hca-' + cloudAccount.appCode + '-gcp-' + cloudEnvironment.name" target="_blank">https://console.cloud.google.com/home/dashboard?project=hca-{{cloudAccount.appCode}}-gcp-{{cloudEnvironment.name}}</a></p>
                    <p><strong>Terraform Link:</strong> <a :href="'https://app.terraform.io/app/hca-healthcare/workspaces/hca-' + cloudAccount.appCode + '-gcp-' + cloudEnvironment.name" target="_blank">https://app.terraform.io/app/hca-healthcare/workspaces/hca-{{cloudAccount.appCode}}-gcp-{{cloudEnvironment.name}}</a></p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'cloudInstallation',
  computed: {
    ...mapGetters(['adminSettings', 'oidcUser']),
    hasAdminRole: {
      get: function () {
        return this.adminSettings.isSuperUser || (this.team && this.team.memberEmails.find(x => x.toLowerCase() === this.oidcUser.emailAddress.toLowerCase()))
      }
    }
  },
  data: () => ({
    headers: [
      {
        text: 'Environment',
        align: 'start',
        sortable: false,
        value: 'displayName'
      },
      {
        text: 'Created By',
        sortable: false,
        value: 'createdBy'
      },
      {
        text: 'Status',
        sortable: false,
        value: 'status'
      },
      {
        text: 'Duration',
        sortable: false,
        value: 'duration'
      }
    ],
    logHeaders: [
      {
        text: 'Step',
        align: 'start',
        sortable: false,
        value: 'stepName'
      }
    ],
    singleExpand: false,
    expanded: [],
    isLoading: false,
    cloudAccount: {},
    deploymentDuration: null,
    deploymentDurationTimeout: null,
    reason: null
  }),
  methods: {
    retryInstallPrompt: function () { //    done
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to retry this installation?',
        confirmMethod: () => {
          this.retryInstall()
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    retryInstall: function () { //  done
      this.reason = 'retry'
      this.cloudAccount.deploymentStatus = null
      if (this.cloudAccount.cloudEnvironments && this.cloudAccount.cloudEnvironments.length > 0) {
        for (let i = 0; i < this.cloudAccount.cloudEnvironments.length; i++) {
          if (
            this.cloudAccount.cloudEnvironments &&
            this.cloudAccount.cloudEnvironments[i] &&
            Object.prototype.hasOwnProperty(this.cloudAccount.cloudEnvironments[i], 'installationStatus') &&
            Object.prototype.hasOwnProperty(this.cloudAccount.cloudEnvironments[i].installationStatus, 'log')
          ) {
            this.cloudAccount.cloudEnvironments[i].installationStatus.log = null
          }
        }

        const xhrPath = `${process.env.VUE_APP_CLOUDBASEURL}/cloud/accounts/${this.cloudAccount.id}/approve?reason=${this.reason}`
        const xhrHeader = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
        }

        return axios(xhrPath, xhrHeader).then((response) => {
          if (response && response !== null && typeof response === 'object' && Object.prototype.hasOwnProperty.call(response, 'data') && Object.prototype.hasOwnProperty.call(response.data, 'id')) {
            this.cloudAccountId = response.data.id
            this.getCloudAccount()
          }
        }, (error) => {
          this.$store.commit('showErrorDialog', {
            errorData: error,
            persistErrorDialog: false,
            retryMethod: () => this.retryInstall()
          })
        })
      } else {
        return false
      }
    },

    getDateString: function (date) { // done
      return moment(date).calendar()
    },
    getCloudAccount: function () { //   done
      return axios(
        `${process.env.VUE_APP_CLOUDBASEURL}/cloud/accounts/${this.cloudAccountId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            if (response && response !== null && typeof response === 'object' && Object.prototype.hasOwnProperty.call(response, 'data')) {
              this.cloudAccount = response.data
            }
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getTeam() })
          })
    },
    getStepDurationTime: function (start, end) {
      let startTime = moment(start)
      let endTime = moment(end)

      return this.formatDuration(moment.duration(endTime.diff(startTime)))
    },
    formatDuration: function (duration) { //    done
      let dateString = ''
      if (duration.days()) {
        dateString += `${duration.days()}d `
      }
      if (duration.hours()) {
        dateString += `${duration.hours()}h `
      }
      if (duration.minutes()) {
        dateString += `${duration.minutes()}m `
      }
      if (duration.seconds()) {
        dateString += `${duration.seconds()}s`
      }
      if (!duration.seconds()) {
        dateString = '<1s'
      }
      return dateString
    },
    formatStepStatus: function (status) {
      if (status === 0) {
        return 'Pending'
      }
      if (status === 1) {
        return 'In Progress'
      }
      if (status === 2) {
        return 'Completed'
      }
      if (status === 3) {
        return 'Failed'
      }
    },
    getColor: function (status) {
      if (status === 0) {
        return 'yellow'
      }
      if (status === 1) {
        return 'orange'
      }
      if (status === 2) {
        return 'green'
      }
      if (status === 3) {
        return 'red'
      }
    },
    getEnvironmentDeploymentColor: function (status) {

    },
    getDeploymentDuration: function (item) {
      if (item && item !== null && typeof item === 'object' && Object.prototype.hasOwnProperty.call(item, 'installationStatus') && item.installationStatus !== null && Object.prototype.hasOwnProperty.call(item.installationStatus, 'completedDateTimeUtc') && item.installationStatus.completedDateTimeUtc != null) {
        let startTime = moment(item.installationStatus.initiatedDateTimeUtc)
        let endTime = moment(item.installationStatus.completedDateTimeUtc)
        return this.formatDuration(moment.duration(endTime.diff(startTime)))
      } else if (item && item !== null && typeof item === 'object' && Object.prototype.hasOwnProperty.call(item, 'installationStatus') && item.installationStatus !== null && Object.prototype.hasOwnProperty.call(item.installationStatus, 'initiatedDateTimeUtc') && item.installationStatus.initiatedDateTimeUtc != null) {
        let startTime = moment(item.installationStatus.initiatedDateTimeUtc)
        let endTime = moment()
        let diff = endTime.diff(startTime)

        this.deploymentDuration = this.formatDuration(moment.duration(diff))
        this.deploymentDurationTimeout = setTimeout(() => {
          this.getDeploymentDuration()
        }, 1000)
        return this.deploymentDuration
      }
    }
  },
  beforeMount: function () { // done
    if (this.$route.params.id && this.$route.params.cloudAccountId) {
      this.productId = this.$route.params.id
      this.cloudAccountId = this.$route.params.cloudAccountId
      this.getCloudAccount().then(() => {
        let displayName = ''

        if (this.cloudAccount && this.cloudAccount !== null && typeof this.cloudAccount === 'object' && Object.prototype.hasOwnProperty.call(this.cloudAccount, 'displayName')) {
          displayName = this.cloudAccount.displayName
        }

        this.$store.commit('breadcrumbs', [
          {
            text: 'Products',
            exact: true,
            disabled: false,
            to: '/products'
          },
          {
            text: displayName,
            exact: true,
            disabled: false,
            to: `/products/${this.productId}`
          },
          {
            text: `Deployments`,
            exact: true,
            disabled: true,
            to: `/products/${this.productId}/installs/${this.cloudAccountId}`
          }
        ])
      })
    }
  },
  onDestroy: function () { //   done
    clearTimeout(this.installationDurationTimeout)
    clearTimeout(this.installationTimeout)
  }
}
</script>

<style scoped>
.group-name {
  padding-top: 35px;
}

.group-name-loading {
  padding-top: 15px;
}

.loading-name {
  width: 150px;
}

.no-transition {
  transition: none;
}

.image-select {
  cursor: pointer;
}

.tab-item-wrapper {
  /* vuetify sets the v-tabs__container height to 48px */
  min-height: calc(100vh - 220px);
  padding: 15px;
}

.heading-input {
  height: 50px !important;
}
.step-duration {
  max-width: 50px;
}
.log-content {
  color: #fff !important;
  padding-top: 15px;
  display: block;
}

.gcp-card {
  border-top: 8px solid #4285f4;
  border-right: 8px solid #db4437;
  border-bottom: 8px solid #f4b400;
  border-left: 8px solid #0f9d58;
}

#circle {
  height: 24px;
  border-radius: 20px;
}

</style>
