<template>
<v-row class="mt-2">
    <v-col cols="12">
        <v-card
            :loading="isSavingTeam"
            class="ap-2"
            color="transparent"
            flat
        >
            <template v-slot:progress>
            <v-progress-linear
                absolute
                color="green lighten-3"
                height="4"
                indeterminate>
            </v-progress-linear>
            </template>
            <v-form v-if="this.team.cloudGroup === null" ref="form">
                <v-row id="v-step-1">
                    <v-col cols="6">
                        <v-row no-gutters>
                        <v-col>
                          <v-card height="100%" class="pa-5" flat outlined >
                            <h2>Team Cloud Account Access</h2>
                            <v-divider class="mb-5"></v-divider>
                            <p
                            class="grey--text"
                            >This is used to create a team identity account used for cloud access</p>
                            <v-row>
                              <v-col cols="12">
                                <v-row justify="space-between">
                                  <v-col cols="2">
                                    <p class="mt-5"><strong>Add User</strong></p>
                                  </v-col>
                                  <v-col cols="10">
                                    <user-search-component :disabled="isSavingTeam" :value="currentAccountOwner" v-model="currentAccountOwner" label="Team Account Owner" />
                                  </v-col>
                                </v-row>
                                 <v-simple-table class="user-table">
                                  <template v-slot:default>
                                      <thead>
                                      <tr>
                                          <th class="text-left">34</th>
                                          <th class="text-left">Name</th>
                                          <th class="text-left">Delete</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr v-for="user in members" :key="user.Key">
                                          <td>{{ user.Key }}</td>
                                          <td>{{ user.Value }}</td>
                                          <td>
                                          <v-btn :disabled="buttonDisabled()" icon @click="deleteUser(user.Key)"><v-icon>mdi-delete</v-icon></v-btn></td>
                                      </tr>
                                      </tbody>
                                  </template>
                                  </v-simple-table>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-form>
            <v-form v-else>
            <v-row id="v-step-1">
              <v-col cols="6">
                  <v-row no-gutters>
                  <v-col>
                    <v-card height="100%" class="pa-5" flat outlined >
                      <h2>Team Cloud Account Access</h2>
                      <v-divider class="mb-5"></v-divider>
                      <p
                      class="grey--text"
                      >Looks like you already have owners set. Manage access <a :href="`https://portal.azure.com/${this.$data.localTeam.cloudGroup.identityGroupId}`">here.</a></p>
                      <h3>Owners</h3>
                      <v-divider class="mb-5"></v-divider>
                      <p v-for="(member, index) in members" :key="index">{{ member.Value }} </p>
                    </v-card>
                  </v-col>
                  </v-row>
              </v-col>
          </v-row>
            </v-form>
        </v-card>
    </v-col>
    <v-col cols="6">
      <v-alert
      v-if="this.team.cloudGroup === null || this.team.cloudGroup.owners.length < 2"
      text
      dense
      type="warning">You need a minimum of two owners!</v-alert>
      <v-btn v-show="this.team.cloudGroup === null" :disabled="this.members.length < 2 || this.team.cloudGroup != null" @click="triggerTeamUpdate" color="info">Update</v-btn>
    </v-col>
</v-row>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import axios from 'axios'
import userSearchComponent from '@/components/userSearchComponent.vue'

export default {
  name: 'team-cloud-account',
  computed: {
    ...mapGetters(['adminSettings']),
    ...mapGetters('teams', ['team', 'isSavingTeam', 'isDeletingTeam']),
    ...mapGetters('affiliates', ['affiliates', 'isLoadingAffiliates']),
    ...mapState(['teams'])
  },
  components: {
    'user-search-component': userSearchComponent
  },
  watch: {
    team: function () {
      this.localTeam = this.team
      this.loadMembersAndGroups()
    },
    currentAccountOwner: function (newValue, oldValue) {
      if (newValue) {
        if (!this.members.find(x => x.Key === newValue.Key)) {
          this.members.push(newValue)
          this.currentAccountOwner = newValue.value
        }
      }
    },
    currentSecondaryAccountOwner: function (newValue, oldValue) {
      if (newValue) {
        if (!this.members.find(x => x.Key === newValue.Key)) {
          this.members.push(newValue)
          this.currentSecondaryAccountOwner = newValue
        }
      }
    }
  },
  data: () => ({
    members: [ ],
    currentAccountOwner: '',
    currentSecondaryAccountOwner: '',
    descriptionRules: [
      v => !!v || 'A Description is required',
      v => (v && v.length < 500) || 'The Description must be less than 500 characters'
    ],
    userError: false,
    localTeam: {}
  }),
  methods: {
    ...mapActions({
      'updateTeam': 'teams/updateTeam',
      'deleteTeam': 'teams/deleteTeam',
      'updateIdentity': 'teams/updateIdentity'
    }),
    buttonDisabled: function () {
      if (this.members.length === 0) {
        return true
      } else {
        return false
      }
    },
    validate: function () {
      let validated = true
      this.userError = false
      if (this.members.length <= 0 && this.allowedGroups.length <= 0) {
        this.userError = true
        validated = false
      }
      if (!this.$refs.form.validate()) {
        validated = false
      }
      return validated
    },
    triggerTeamUpdate: function () {
      if (!this.validate()) {
        return false
      }

      let cloudGroup = {
        owners: [],
        groupName: ''
      }
      cloudGroup.displayName = this.localTeam.name
      if (this.members) {
        this.localTeam.cloudGroup = cloudGroup
        this.members.forEach(x => {
          this.localTeam.cloudGroup.owners.push(x.Key)
        })
      }

      this.localTeam.cloudGroup.displayName = this.localTeam.name
      this.localTeam.cloudGroup.description = this.localTeam.description

      if (this.allowedGroups) {
        this.localTeam.adGroups = this.allowedGroups
      }

      this.updateIdentity(this.localTeam)
    },
    getUserDetails: function (username) {
      return axios(
        `${process.env.VUE_APP_BASENETWORKAPIURL}/activedirectory/search/user?searchText=${username}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getUserDetails() })
          })
    },
    deleteUser: function (username) {
      this.members = this.members.filter((item) => {
        return item.Key !== username
      })
    },
    loadMembersAndGroups: function () {
      if (this.localTeam.cloudGroup) {
        this.members = [ ]
        this.localTeam.cloudGroup.owners.forEach(x => {
          this.getUserDetails(x).then(y => {
            if (y) {
              this.members.push(y[0])
            }
          })
        })
      }
    }
  },
  mounted: async function () {
    this.localTeam = this.team
    this.loadMembersAndGroups()
  }
}
</script>

<style scoped>

</style>
