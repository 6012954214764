<template>
  <v-container fluid>
    <v-form ref="form">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="10">
          <v-row>
            <v-col cols="12" sm="6" class="d-flex justify-start align-center">
              <v-skeleton-loader
                v-if="!template"
                class="ma-2"
                style="height: 90px; width: 90px;"
                type="image"
              ></v-skeleton-loader>
              <transition name="fade-transition" mode="out-in">
                <v-img
                  key="edit-image"
                  v-if="template"
                  class="mb-1"
                  max-height="100"
                  max-width="100"
                  min-height="100"
                  min-width="100"
                  src="@/assets/template.svg"
                  contain
                  style="width: 100px"
                />
              </transition>
              <v-skeleton-loader
                v-if="!template"
                type="list-item-two-line"
                class="badge-name-loading"
                style="width: 500px"
              ></v-skeleton-loader>
              <transition name="slide-y-transition" mode="out-in" v-if="template">
                <div v-if="!editModeEnabled">
                    <span class="ma-2 text-h5" key="edit-display-off">{{ template.displayName }}</span>
                    <br/>
                    <span class="ma-2 text-subtitle2" key="edit-display-off2">{{ template.description }}</span>
                    <br/>
                    <transition name="slide-y-transition" mode="out-in" v-if="template">
                      <div>
                        <v-chip v-if="releaseDetails.release.releaseId" class="ma-2">{{ releaseDetails.release.releaseId }}</v-chip>
                        <v-chip v-if="!releaseDetails.release.releaseId" class="ma-2">Not Yet Released</v-chip>
                        <v-chip v-if="templateType" :color="templateType.color" class="ma-2">{{ templateType.name }}</v-chip>
                      </div>
                    </transition>
                </div>
                <div v-else>
                    <v-text-field
                      solo
                      key="edit-displayName"
                      class="ml-2 heading-input"
                      v-model="template.displayName"
                      label="Display Name"
                      style="width: 400px"
                    />
                    <v-text-field
                      solo
                      key="edit-description"
                      class="ml-2 heading-input"
                      v-model="template.description"
                      label="Description"
                      style="width: 400px"
                    />
                    <br/>
                    <transition name="slide-y-transition" mode="out-in" v-if="template">
                      <div>
                        <v-chip v-if="releaseDetails.release.releaseId" class="ma-2">{{ releaseDetails.release.releaseId }}</v-chip>
                        <v-chip v-if="!releaseDetails.release.releaseId" class="ma-2">Not Yet Released</v-chip>
                        <v-chip v-if="templateType" :color="templateType.color" class="ma-2">{{ templateType.name }}</v-chip>
                      </div>
                    </transition>
                </div>
              </transition>
            </v-col>
            <v-spacer></v-spacer>
            <transition name="slide-y-transition" mode="out-in">
              <v-col cols="12" md="2" class="d-flex justify-center align-center" v-if="!editModeEnabled" key="edit-mode-disabled">
                <div>
                  <v-btn class="ma-1" min-width="200" color="primary" :disabled="isLoading" @click="setEditMode" v-if="hasAdminRole">Edit Template</v-btn>
                  <br v-if="hasAdminRole" />
                  <v-btn class="ma-1" :disabled="isLoading || !template.isReleased" min-width="200" color="success" @click="showInstallationComponent = true">Use This Template</v-btn>
                </div>
              </v-col>
              <v-col cols="12" md="2" class="d-flex justify-center align-center" v-else key="edit-mode-enabled">
                <div>
                <v-btn class="ma-1" color="error" :loading="isDeleting" :disabled="isDeleting || isSubmitted" @click="displayDeleteDialog" style="width: 200px">Delete</v-btn>
                <br/>
                <v-btn class="ma-1" color="grey darken-1" :disabled="isSubmitted" @click="cancelEdit" style="width: 96px; color:#fff">Cancel</v-btn>
                <v-btn class="ma-1" color="primary" :loading="isSubmitted" :disabled="isSubmitted" @click="updateTemplate" style="width: 96px">Save</v-btn>
                </div>
              </v-col>
            </transition>
          </v-row>
      </v-col>
      </v-row>
      <v-divider class="divider" />
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="10" xl="10">
        <v-tabs
          v-model="tabView"
        >
          <v-tab>Details</v-tab>
          <v-tab>Releases</v-tab>
          <v-tab>Dependencies</v-tab>
          <v-tab>Related Templates</v-tab>
          <v-tab v-if="hasAdminRole && editModeEnabled">Release Settings</v-tab>
        </v-tabs>
        <div :class="$vuetify.theme.dark ? 'tab-item-wrapper' : 'tab-item-wrapper tab-item-wrapper-light'">
          <v-row v-if="!isLoading">
              <v-col cols="12" md="8" v-if="tabView === 0" class="main-tab">
                <span class="readme-container" v-html="compiledMarkdown(releaseDetails.readme)"></span>
              </v-col>
              <v-col cols="12" md="8" v-if="tabView === 1">
                <span class="text-h5">Releases</span>
                <v-simple-table v-if="releaseDetails">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Version
                        </th>
                        <th class="text-left">
                          Released
                        </th>
                        <th class="text-left">
                          Details
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in releases"
                        :key="item.name"
                      >
                        <template v-if="hasAdminRole || (item.isPublished)">
                          <td><a :href="`/templates/${templateId}/releases/${item.release.releaseId}`">{{ item.release.releaseId }}</a></td>
                          <td v-if="item.release.meta">{{ item.release.meta.createdOnUtc ? getDateString(item.release.meta.createdOnUtc) : "" }}</td><td v-else></td>
                          <td><v-chip :color="item.release.isPublished ? '#D1C4E9' : '#B2DFDB'">{{ item.release.isPublished ? 'Published' : 'Draft'}}</v-chip></td>
                        </template>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <v-col cols="12" md="8" v-if="tabView === 2">
                <span class="text-subtitle1">Dependencies are coming soon...</span>
              </v-col>
              <v-col cols="12" md="8" v-if="tabView === 3">
                <span class="text-subtitle1">Related Templates are coming soon...</span>
              </v-col>
              <v-col cols="12" md="8" v-if="tabView === 4">
                <github-action-settings-component v-if="template.templateType === 0" :releases="releases" :template="template" :releaseDetails="releaseDetails" />
                <project-action-settings-component v-if="template.templateType === 3" :releases="releases" :template="template" :releaseDetails="releaseDetails" />
                <terraform-settings-component v-if="template.templateType === 2" :releases="releases" :template="template" :releaseDetails="releaseDetails" />
              </v-col>
            <v-divider
              vertical
            ></v-divider>
            <v-col cols="12" md="3">
              <v-row>
                <v-col>
                  <span class="text-h6 pa-2">Technical Name:</span>
                  <div class="ml-3">
                    <span>{{ template.name }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="text-h6 pa-2">Tags:</span>
                  <transition name="slide-y-transition" mode="out-in">
                    <div v-if="!editModeEnabled" key="edit-chip-disabled" class="ml-3">
                      <v-chip
                        v-for="(chip, index) in template.tags"
                        :key="index"
                        class="ma-2"
                      >
                        {{ chip }}
                      </v-chip>
                    </div>
                    <div v-else key="edit-chip" class="ml-3">
                      <v-combobox
                        style="max-width: 500px"
                        solo
                        v-model="template.tags"
                        hint="Type a tag and press enter. Maximum of 5."
                        :rules="tagRules"
                        label="Tags"
                        multiple
                        persistent-hint
                        small-chips
                      >
                      </v-combobox>
                    </div>
                  </transition>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="text-h6 pa-2">Repo Url:</span>
                  <div class="ml-3">
                    <a :href="`https://github.com/${template.githubOrganizationName}/${template.githubRepoName}`">github.com/{{ template.githubOrganizationName }}/{{ template.githubRepoName }}</a>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="text-h6 pa-2">Created:</span>
                  <div class="ml-3">
                    <span v-if="releaseDetails.release.meta.createdOnUtc">{{ getDateString(releaseDetails.release.meta.createdOnUtc) }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="text-h6 pa-2">Updated:</span>
                  <div class="ml-3">
                    <span v-if="releaseDetails.release.meta.modifiedOnUtc">{{ getDateString(releaseDetails.release.meta.modifiedOnUtc) }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="text-h6 pa-2">Language:</span>
                  <div class="ml-3">
                    <span v-if="releaseDetails.language">{{ releaseDetails.language }}</span>
                    <span v-else>Unknown</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="text-h6 pa-2">Install Count:</span>
                  <div class="ml-3">
                    <v-chip>{{ releaseDetails.installationCount }}</v-chip>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="text-h6 pa-2">Open Issue Count:</span>
                  <div class="ml-3">
                    <v-chip>{{ releaseDetails.issueCount }}</v-chip>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="text-h6 pa-2">Watcher Count:</span>
                  <div class="ml-3">
                    <v-chip>{{ releaseDetails.watcherCount }}</v-chip>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-else class="justify-center">
            <v-col cols="12" md="2" class="ma-4">
              <v-progress-circular
                :size="150"
                color="primary"
                indeterminate
                style="margin-top:150px"
              ></v-progress-circular>
            </v-col>
          </v-row>
        </div>
        </v-col>
      </v-row>
    </v-form>
    <v-dialog v-if="template && releaseDetails" v-model="showInstallationComponent" width="1000">
      <github-action-install-component v-if="template.templateType === 0" :template="template" :releaseDetails="releaseDetails" @closeInstallModal="showInstallationComponent = false" />
      <project-action-install-component v-if="template.templateType === 3" :template="template" :releaseDetails="releaseDetails" @closeInstallModal="showInstallationComponent = false" />
      <terraform-install-component v-if="template.templateType === 2" :template="template" :releaseDetails="releaseDetails" @closeInstallModal="showInstallationComponent = false" />
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import marked from 'marked'
import moment from 'moment'

import githubActionSettingsComponent from './components/githubActionSettingsComponent'
import githubActionInstallComponent from './components/githubActionInstallComponent'
import projectActionSettingsComponent from './components/projectActionSettingsComponent'
import projectActionInstallComponent from './components/projectActionInstallComponent'
import terraformSettingsComponent from './components/terraformSettingsComponent'
import terraformInstallComponent from './components/terraformInstallComponent'

export default {
  name: 'editTemplate',
  computed: {
    ...mapGetters(['adminSettings', 'oidcUser']),
    hasAdminRole: {
      get: function () {
        return this.adminSettings.isSuperUser || (this.team && this.team.memberEmails.find(x => x.toLowerCase() === this.oidcUser.emailAddress.toLowerCase()))
      }
    },
    templateType: {
      get: function () {
        if (this.template) {
          if (this.template.templateType === 0) {
            return {
              name: 'Github Action',
              color: '#B2EBF2'
            }
          } else if (this.template.templateType === 3) {
            return {
              name: 'Project',
              color: '#B39DDB'
            }
          } else if (this.template.templateType === 2) {
            return {
              name: 'Terraform Module',
              color: '#FFECB3'
            }
          } else {
            return null
          }
        } else {
          return null
        }
      }
    }
  },
  components: {
    'github-action-settings-component': githubActionSettingsComponent,
    'github-action-install-component': githubActionInstallComponent,
    'project-action-settings-component': projectActionSettingsComponent,
    'project-action-install-component': projectActionInstallComponent,
    'terraform-settings-component': terraformSettingsComponent,
    'terraform-install-component': terraformInstallComponent
  },
  data: () => ({
    showInstallationComponent: false,
    showSettingsDialog: false,
    displayInstallDialog: false,
    validated: true,
    originalTemplate: null,
    orginialreleaseDetails: null,
    templateId: null,
    template: null,
    releaseId: null,
    releaseDetails: {
      readme: '',
      installationCount: 0,
      stargazersCount: 0,
      language: '',
      watcherCount: 0,
      issueCount: 0,
      release: {
        releaseId: '',
        teamName: '',
        templateName: '',
        downloadUrl: '',
        githubRepoName: ''
      }
    },
    releases: [ ],
    team: null,
    isLoading: false,
    isLoadingReleases: false,
    editModeEnabled: false,
    isSubmitted: false,
    isDeleting: false,
    tabView: 0,
    required: [
      v => !!v || 'This field is required'
    ],
    descriptionRules: [
      v => !!v || 'A Description is required',
      v => (v && v.length < 2000) || 'must be less than 500 characters'
    ],
    tagRules: [
      v => !v || v.length < 6 || 'Only 5 tags are allowed'
    ]
  }),
  methods: {
    setEditMode: function () {
      this.originalTemplate = JSON.parse(JSON.stringify(this.template))
      this.orginialreleaseDetails = JSON.parse(JSON.stringify(this.releaseDetails))
      this.editModeEnabled = true
    },
    cancelEdit: function () {
      this.template = JSON.parse(JSON.stringify(this.originalTemplate))
      this.releaseDetails = JSON.parse(JSON.stringify(this.orginialreleaseDetails))
      this.originalTemplate = null
      this.originalTemplate = null
      this.editModeEnabled = false
    },
    getDateString: function (date) {
      return moment(date).format('LL')
    },
    compiledMarkdown: function (input) {
      return marked(input, { sanitize: true })
    },
    getTemplateReleases: function () {
      this.isLoadingReleases = true
      return axios(
        `${process.env.VUE_APP_TEMPLATEBASEURL}/api/templates/${this.templateId}/releases`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.releases = response.data
            this.isLoadingReleases = false
          },
          (error) => {
            this.isLoadingReleases = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getTemplateReleases() })
          })
    },
    getReleaseDetails: function () {
      this.isLoading = true
      return axios(
        `${process.env.VUE_APP_TEMPLATEBASEURL}/api/templates/${this.templateId}/details?release=${this.releaseId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.releaseDetails = response.data
            this.releaseId = this.releaseDetails.release.releaseId
            this.isLoading = false
          },
          (error) => {
            this.isLoading = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getReleaseDetails() })
          })
    },
    getTemplate: function () {
      this.isLoading = true
      return axios(
        `${process.env.VUE_APP_TEMPLATEBASEURL}/api/templates/${this.templateId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.template = response.data
            this.getReleaseDetails()
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getTemplate() })
          })
    },
    getTeam: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/teams/${this.template.teamId}?publicView=true`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.team = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getTeam() })
          })
    },
    validate: function () {
      this.validated = true
      return this.validated
    },
    updateTemplate: function () {
      this.isSubmitted = true
      axios(
        `${process.env.VUE_APP_TEMPLATEBASEURL}/api/templates/${this.templateId}`,
        {
          method: 'PUT',
          data: JSON.stringify(this.template),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          () => {
            this.updateTemplateSettings()
          },
          (error) => {
            this.isSubmitted = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.updateTemplate() })
            return error
          })
    },
    displayDeleteDialog: function () {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to delete this Template? Anyone using it will no longer be able to use it. If you wish to delete a specific version, do that from the edit version section.',
        confirmMethod: () => {
          this.deleteTemplate()
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    deleteTemplate: function () {
      this.isDeleting = true
      return axios(
        `${process.env.VUE_APP_TEMPLATEBASEURL}/api/templates/${this.templateId}/`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          () => {
            this.$router.push(`/teams/${this.team.id}`)
          },
          (error) => {
            this.isDeleting = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.deleteTemplate() })
          })
    },
    updateTemplateSettings: function () {
      if (this.releaseDetails.release) {
        axios(
          `${process.env.VUE_APP_TEMPLATEBASEURL}/api/templates/${this.templateId}/releases/`,
          {
            method: 'PUT',
            data: JSON.stringify(this.releaseDetails.release),
            headers: { 'Content-Type': 'application/json' }
          })
          .then(
            () => {
              this.isSubmitted = false
              this.editModeEnabled = false
              this.getTemplate()
              this.getTemplateReleases()
              this.tabView = 0
            },
            (error) => {
              this.isSubmitted = false
              this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.updateTemplateSettings() })
              return error
            })
      } else {
        this.isSubmitted = false
        this.editModeEnabled = false
        this.getTemplate()
        this.getTemplateReleases()
        this.tabView = 0
      }
    }
  },
  beforeMount: function () {
    if (this.$route.params.releaseId) {
      this.releaseId = this.$route.params.releaseId
    } else {
      this.releaseId = 'latest'
    }
    if (this.$route.params.templateId) {
      this.templateId = this.$route.params.templateId
      this.getTemplateReleases()
      this.getTemplate().then(() => {
        this.getTeam().then(() => {
          this.$store.commit('breadcrumbs', [
            {
              text: 'Templates',
              exact: true,
              disabled: false,
              to: '/templates'
            },
            {
              text: `${this.template.displayName}`,
              exact: true,
              disabled: false,
              to: `/templates/${this.templateId}`
            }
          ])
        })
      })
    }
  }
}
</script>

<style scoped>
.group-name {
  padding-top: 35px;
}

.group-name-loading {
  padding-top: 15px;
}

.loading-name {
  width: 150px;
}

.edit-component {
  padding-top: 15px;
}

.no-transition {
  transition: none;
}

.image-select {
  cursor: pointer;
}

.tab-item-wrapper {
  /* vuetify sets the v-tabs__container height to 48px */
  min-height: calc(100vh - 220px);
  padding: 15px;
}

.main-tab {
  overflow-x: hidden;
  overflow-y: auto;
  overflow: auto;
}

.tab-item-wrapper-light {
  background-color: #fff;
}

.readme-container {
  line-height: 1.8 !important;
  overflow-y: auto;
}

.heading-input {
  height: 50px !important;
}

.v-input__control {
  height: 50px !important;
}

.divider {
  margin-bottom: 15px !important;
}
</style>
