<template>
  <div>
    <v-navigation-drawer
      absolute
      :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
      permanent
    >
    <v-divider/>
      <v-list
        dense
        nav
      >
      <div v-for="item in links" :key="item.title">
        <v-list-item
          link
          v-if="display(item) && !item.subItems"
          :to="item.link"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
          v-if="display(item) && item.subItems"
          :value="routeGroup && routeGroup.includes(item.routeGroup)"
          sub-group
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(subItem, index) in item.subItems"
            :key="index"
            link
            :to="subItem.link"
          >
            <v-list-item-icon>
              <v-icon>{{ subItem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ subItem.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </div>
      </v-list>
    </v-navigation-drawer>
    <div style="margin-left: 256px">
      <transition name="fade-transition" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'Admin',
  computed: {
    ...mapGetters(['adminSettings', 'getGuid']),
    isSuperUser: {
      get: function () {
        return this.adminSettings.isSuperUser
      }
    },
    isApiUser: {
      get: function () {
        return this.adminSettings.isSuperUser || this.adminSettings.isApiUser
      }
    },
    isMu3Admin: {
      get: function () {
        return this.adminSettings.isSuperUser || this.adminSettings.isMu3Admin
      }
    },
    isRepositoryAdmin: {
      get: function () {
        return this.adminSettings.isSuperUser || this.adminSettings.isRepositoryAdmin
      }
    },
    isCloudAdmin: {
      get: function () {
        return this.adminSettings.isSuperUser || this.adminSettings.isCloudAdmin
      }
    },
    isPulseAdmin: {
      get: function () {
        return this.adminSettings.isSuperUser || this.adminSettings.isPulseAdmin
      }
    },
    routeGroup: {
      get: function () {
        return this.$router.currentRoute.meta ? this.$router.currentRoute.meta.group : null
      }
    }
  },
  data: () => ({
    tabView: 0,
    affiliates: [ ],
    links: [
      {
        icon: 'mdi-cog',
        title: 'Settings',
        display: { adminType: 'isSuperUser' },
        subItems: [
          {
            icon: 'mdi-flag',
            title: 'Feature Flags',
            link: '/admin/settings/flags'
          },
          {
            icon: 'mdi-gesture-tap',
            title: 'Corrective Actions',
            link: '/admin/settings/actions'
          }
        ]
      },
      {
        icon: 'mdi-account',
        title: 'Users',
        display: { adminType: 'isSuperUser' },
        subItems: [
          {
            icon: 'mdi-account',
            title: 'API Users',
            link: '/admin/api/users'
          },
          {
            icon: 'mdi-school',
            title: 'Evolve University Users',
            link: '/admin/users/university'
          },
          {
            icon: 'mdi-badge-account-horizontal',
            title: 'Badge Users',
            link: '/admin/badges/users'
          }
        ]
      },
      {
        title: 'Apis',
        display: { adminType: 'isApiAdmin' },
        routeGroup: 'api',
        subItems: [
          {
            icon: 'mdi-cube-send',
            title: 'Deployments',
            link: '/admin/api/deployments'
          },
          {
            icon: 'mdi-account-group',
            title: 'Affiliates',
            link: '/admin/api/affiliates'
          },
          {
            icon: 'mdi-clipboard-flow',
            title: 'Policies',
            link: '/admin/api/policies'
          }
        ]
      },

      {
        title: 'Repositories',
        display: { adminType: 'isRepositoryAdmin' },
        routeGroup: 'repos',
        subItems: [
          {
            icon: 'mdi-package',
            title: 'Public Repositories',
            link: '/admin/repos/public'
          },
          {
            icon: 'mdi-cube-send',
            title: 'Promotions',
            link: '/admin/repos/promotions'
          }
        ]
      },

      {
        title: 'Pulse-Bot',
        link: '/admin/pulseBot',
        display: { adminType: 'isPulseAdmin' }
      }
    ]
  }),
  methods: {
    getAffiliates: function () {
      this.loadingAffiliates = true
      return axios(
        `${process.env.VUE_APP_BASEURL}/affiliates/`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.affiliates = response.data
            this.loadingAffiliates = false
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getAffiliates() })
            return error
          })
    },
    display: function (item) {
      switch (item.display.adminType) {
        case 'isSuperUser' : return this.isSuperUser
        case 'isApiAdmin' : return this.isApiUser
        case 'isMu3Admin' : return this.isMu3Admin
        case 'isRepositoryAdmin' : return this.isRepositoryAdmin
        case 'isCloudAdmin' : return this.isCloudAdmin
        case 'isPulseAdmin' : return this.isPulseAdmin
        default: return false
      }
    }
  },
  mounted: function () {
    this.$store.commit('breadcrumbs', [
      {
        text: 'Admin',
        exact: true,
        disabled: false,
        to: '/admin/settings'
      }
    ])
    this.getAffiliates()
  }
}
</script>

<style scoped>

</style>
