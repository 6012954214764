<template>
  <v-container fluid>
    <v-form ref="listDepartmentsForm">
      <v-row>
        <v-col cols="auto">
          <v-icon x-large left>mdi-alpha-c-circle-outline</v-icon>
        </v-col>
        <v-col cols="12" sm="6">
          <transition name="slide-y-transition" mode="out-in">
            <span v-if="!edittingCollege" class="ma-2 text-h4" key="edit-name">{{college.name}}</span>
            <v-text-field
              v-else
              key="edit-name-enabled"
              style="width: 600px"
              solo
              class="ml-2"
              v-model="college.name"
              :rules="collegeNameRules"
              label="Name"
              hint="The college name."
              persistent-hint
            />
          </transition>
          <transition name="slide-y-transition" mode="out-in">
            <p v-if="!edittingCollege" key="edit-description" class="ma-2">{{college.description}}</p>
            <v-textarea
              v-else
              class="ml-3"
              key="edit-description-enabled"
              style="width: 600px; !important;"
              :rules="collegeDescriptionRules"
              solo
              hint="The description for your college."
              v-model="college.description"
              label="Description"
              required
              persistent-hint
            />
          </transition>
        </v-col>
        <v-spacer/>
        <v-col cols="12" sm="auto">
          <transition name="slide-y-transition" mode="out-in">
            <div v-if="!edittingCollege && !edittingDepartment" key="edit-college-button-disabled">
              <v-btn text color="primary" @click="edittingCollege=true"  v-if="hasAdminRole">
                <v-icon left>mdi-pencil</v-icon>Edit College
              </v-btn>
              <v-btn @click="showDepartmentBuilder=true" class="pa-2 ma-2" color="primary" v-if="hasAdminRole"><v-icon left>mdi-plus</v-icon>Add Department</v-btn>
            </div>
            <div v-if="edittingCollege" key="edit-mode-buttons-enabled">
              <v-btn v-if="edittingCollege" width="200px" class="ma-1 pa-1" :loading="isSubmitted" :disabled="isSubmitted" @click="updateCollege"><v-icon left>mdi-check</v-icon>Save College</v-btn>
              <br/>
              <v-btn v-if="edittingCollege" width="200px" color="error" class="ma-1 pa-1" :loading="isDeletingCollege" :disabled="isDeletingCollege" @click="displayDeleteDialog">
                <v-icon left>mdi-delete</v-icon>Delete College</v-btn>
              <br/>
              <v-btn class="ma-1 pa-1" width="200px" @click="cancelUpdate()"><v-icon left>mdi-close</v-icon>Cancel Update</v-btn>
            </div>
          </transition>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-navigation-drawer
            :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
            floating
            permanent
            width="100%"
          >
          <v-divider/>
            <v-list v-if="collegeId"
              dense
              nav
              two-line
            >
              <div v-for="item in departments" :key="item.name">
                <v-list-item
                link
                :to="'/colleges/' + collegeId + '/departments/' + item.id + '/courses'">
                  <v-list-item-avatar>
                    <v-icon x-large>mdi-alpha-d-circle-outline</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list>
          </v-navigation-drawer>
        </v-col>
        <v-col cols="12" md="9">
          <transition name="fade-transition" mode="out-in">
            <router-view></router-view>
          </transition>
        </v-col>
      </v-row>
    </v-form>
    <v-dialog v-model="showDepartmentBuilder" persistent width="700">
      <department-builder-component :college-id="collegeId" @departmentCreated="reloadDepartmentList" @closeModal="showDepartmentBuilder=false" />
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import departmentBuilderComponent from './components/departmentBuilderComponent.vue'

export default {
  name: 'Departments',
  components: {
    'department-builder-component': departmentBuilderComponent
  },
  computed: {
    ...mapGetters(['adminSettings', 'isEdittingDepartment', 'isEdittingCollege']),
    ...mapGetters(['universityUser']),
    hasAdminRole: {
      get: function () {
        return this.universityUser.roles.find(x => x === 'Admin') !== undefined
      }
    },
    edittingCollege: {
      get () {
        return this.isEdittingCollege
      },
      set (value) {
        this.$store.commit('editCollege', value)
      }
    },
    edittingDepartment: {
      get () {
        return this.isEdittingDepartment
      },
      set (value) {
        this.$store.commit('editDepartment', value)
      }
    }
  },
  watch: {
    page: function (newValue, oldValue) {
      if (oldValue !== newValue) {
        this.getDepartments()
      }
    },
    search: function () {
      clearTimeout(this.searchTimeout)

      // Make a new timeout set to go off in 800ms
      this.searchTimeout = setTimeout(() => {
        if (this.search == null) {
          this.search = ''
        }
        this.page = 1
        this.getDepartments()
      }, 500)
    }
  },
  data: () => ({
    searchTimeout: null,
    departments: [ ],
    college: '',
    collegeId: '',
    isLoading: false,
    page: 1,
    limit: 12,
    count: 0,
    search: '',
    showDepartmentBuilder: false,
    isDeletingCollege: false,
    isSubmitted: false,
    collegeNameRules: [
      v => !!v || 'College name is required.',
      v => (v && v.length < 100) || 'must be less than 100 characters'
    ],
    collegeDescriptionRules: [
      v => !!v || 'College description is required.',
      v => (v && v.length < 300) || 'must be less than 300 characters'
    ]
  }),
  methods: {
    validate: function () {
      this.validated = true
      if (!this.$refs.listDepartmentsForm.validate()) {
        this.validated = false
      }
      return this.validated
    },
    getDepartments: function () {
      if (this.$route.params.id) {
        this.collegeId = this.$route.params.id
        axios(
          `${process.env.VUE_APP_UNIVERSITYBASEAPIURL}/api/colleges/${this.collegeId}/departments?page=${this.page}&limit=${this.limit}&search=${this.search}`,
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
          })
          .then(
            (response) => {
              this.isLoading = false
              this.departments = response.data.items
              this.count = Math.ceil(response.data.totalCount / this.limit)
            },
            (error) => {
              this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getDepartments() })
            })
      }
    },
    reloadDepartmentList: function () {
      this.showDepartmentBuilder = false
      this.getDepartments()
    },
    performMount: function () {
      this.isLoading = true
      this.getCollege().then(() => {
        this.getDepartments()
        this.storeBreadcrumbs()
      })
    },
    getCollege: function () {
      if (this.$route.params.id) {
        this.collegeId = this.$route.params.id
        return axios(
          `${process.env.VUE_APP_UNIVERSITYBASEAPIURL}/api/colleges/${this.collegeId}`,
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
          })
          .then(
            (response) => {
              this.college = response.data
            },
            (error) => {
              this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getCollege() })
            })
      }
    },
    displayDeleteDialog: function () {
      this.$store.commit('showDialog', {
        message: 'Delete this college? This action cannot be undone.',
        confirmMethod: () => {
          this.deleteCollege()
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    deleteCollege: function () {
      this.isDeletingCollege = true
      return axios(
        `${process.env.VUE_APP_UNIVERSITYBASEAPIURL}/api/colleges/${this.collegeId}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          () => {
            this.isDeletingCollege = false
            this.edittingCollege = false
            this.$router.push(`/colleges`)
          },
          (error) => {
            this.isDeletingCollege = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.deleteCollege() })
          })
    },
    cancelUpdate: function () {
      this.performMount()
      this.edittingCollege = false
    },
    updateCollege: function () {
      this.isSubmitted = true

      if (!this.validate()) {
        this.isSubmitted = false
        return false
      }
      const updatedCollege = this.college
      axios(
        `${process.env.VUE_APP_UNIVERSITYBASEAPIURL}/api/colleges/${this.collegeId}`,
        {
          method: 'PUT',
          data: JSON.stringify(updatedCollege),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.college = response.data
            this.performMount()
            this.edittingCollege = false
            this.isSubmitted = false
            this.$store.commit('triggerSnack', { snackMessage: 'The college has been updated successfully', snackColor: 'success' })
          },
          (error) => {
            this.isSubmitted = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.updateCollege() })
            return error
          })
    },
    routeToDepartment: function (id) {
      this.$router.push(`/departments/${this.collegeId}/colleges`)
    },
    storeBreadcrumbs: function () {
      this.$store.commit('breadcrumbs', [
        {
          text: 'Evolve University',
          exact: true,
          disabled: false,
          to: '/colleges'
        },
        {
          text: this.college.name,
          exact: true,
          disabled: false,
          to: `/colleges/${this.collegeId}/departments`
        }
      ])
    }
  },
  mounted: function () {
    this.performMount()
  }
}
</script>

<style scoped>

</style>
