<template>
    <v-card>
      <v-card-title>College Builder</v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" md="10">
                <v-text-field v-model="college.name" label="Name" :rules="collegeNameRules" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="10">
                <v-textarea v-model="college.description" label="Description" :rules="collegeDescriptionRules" />
              </v-col>
            </v-row>
           </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
         <v-spacer></v-spacer>
         <v-btn
           color="primary"
           text
           @click="closeModal"
         >
           Cancel
         </v-btn>
         <v-btn
           color="primary"
           text
           @click="saveCollege"
           :disabled="isSubmitting"
           :loading="isSubmitting"
         >
           Create College
         </v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'college-builder-component',
  computed: {
    ...mapGetters(['adminSettings'])
  },
  data: () => ({
    isSubmitting: false,
    college: {
      name: '',
      description: ''
    },
    collegeNameRules: [
      v => !!v || 'College name is required.',
      v => (v && v.length < 100) || 'must be less than 100 characters'
    ],
    collegeDescriptionRules: [
      v => !!v || 'College description is required.',
      v => (v && v.length < 300) || 'must be less than 300 characters'
    ]
  }),
  methods: {
    closeModal: function () {
      this.$emit('closeModal')
    },
    saveCollege: function () {
      this.isSubmitting = true
      if (!this.validate()) {
        this.isSubmitting = false
        return false
      }
      axios(
        `${process.env.VUE_APP_UNIVERSITYBASEAPIURL}/api/colleges`,
        {
          method: 'POST',
          data: JSON.stringify(this.college),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isSubmitting = false
            this.$router.push(`/colleges/${response.data.id}/departments`)
          },
          (error) => {
            this.isSubmitting = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.saveCollege() })
            return error
          })
    },
    validate: function () {
      let validated = true
      if (!this.$refs.form.validate()) {
        validated = false
      }
      return validated
    }
  }
}
</script>

<style scoped>

</style>
