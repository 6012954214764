<template>
  <v-container>
    <div v-if="this.team !== null">
      <div v-if="this.team.cloudGroup != null">
        <v-row>
        <v-col>
          <v-alert
            dense
            text
            type="warning"
          >
            Looks like you don't have cloud account owners defined for this team. Please define this <router-link :to="'/teams/' + team.id + '/' + 'team-cloud-account'">here.</router-link>
          </v-alert>
        </v-col>
      </v-row>
      </div>
      <div v-else>
        <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step
            :complete="step > 1"
            step="1"
          >
            CMDB Product
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :complete="step > 2"
            step="2"
          >
            Details
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :complete="step > 3"
            step="3"
          >
            Environments
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="8">
                    <span class="text-subtitle-1">A CMDB CI item is required to build a cloud subscription. Type in the name of the CMDB CI item in the text box below and click Search.
                      Once the table has populated, select the CMDB item and then click Continue. If you do not yet have a CMDB item, you can create one
                      <a href="https://hcaservicecentral.service-now.com/hca?id=hca_cat_item&sys_id=0938baaddbaa9fc09bb7eeb5ca961940" target="_blank">here</a>.</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field solo v-model="cmdbName" label="Service Central Product Name" @keyup.enter="cmdbSearch" />
                  </v-col>
                  <v-col>
                    <v-btn large @click="cmdbSearch" :diabled="!cmdbName || loading" :loading="loading">Search</v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-data-table
                      :loading="loading"
                      :headers="cmdbHeaders"
                      :items="cmdbItems"
                      @click:row="cmdbSelection"
                      :item-class="setSelectionStyle"
                    >
                    <template v-slot:item.link="{ item }">
                      <v-btn icon @click.stop="" :href="`https://hcaservicecentral.service-now.com/nav_to.do?uri=cmdb_ci.do?sys_id=${item.sys_id}`" target="_blank">
                        <v-icon color="info">mdi-call-made</v-icon>
                      </v-btn>
                    </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer/>
                <v-btn color="info" :disabled="!selectedCmdbProduct" @click="step=2">Continue</v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col>
                    <span class="text-subtitle-1">Fill out the required fields below for the cloud subscriptions</span>
                  </v-col>
                </v-row>
                <v-form v-model="subscriptionStep2Validity">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col>
                          <v-text-field v-model="subscription.displayName" label="Display Name" hint="Give this subscription a friendly name" />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-select :items="cloudProviders" item-value="value" item-text="name" :rules="requiredField" v-model="subscription.cloudProvider" label="Cloud Provider" />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-select :items="cloudOrganizations" :isLoading="isLoadingCloudOrganizations" item-value="id" item-text="displayName" :rules="requiredField" v-model="selectedCloudOrganizationId" label="Cloud Organization Group" />
                        </v-col>
                      </v-row>
                      <v-row v-if="selectedCloudOrganizationId">
                        <v-col>
                          <v-select :items="lineOfBusiness" :isLoading="isLoadingCloudLobs" item-value="id" item-text="displayName" :rules="requiredField" v-model="subscription.lineOfBusinessId" label="Line of Business" />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field v-model="subscription.appCode" label="Application Code" />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col>
                          <v-text-field v-model="subscription.coId" label="HCA Coid" />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field v-model="subscription.departmentId" label="HCA Department Id" />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field v-model="subscription.tcoCode" label="HCA TCO Code" />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field v-model="subscription.planviewProjectId" label="PlanView Project Id" />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-select :items="githubLinks" item-text="orgName" item-value="id" v-model="subscription.githubOrganizationLink" label="GithubOrganization" />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn text @click="step = 1">Back</v-btn>
                <v-btn color="info" :disabled="!subscriptionStep2Validity" @click="step = 3">Continue</v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="8">
                    <span class="text-subtitle-1">
                      Use the table below to create and edit environments. Typically, cloud subscriptions will contain a dev, qa, and production environment.
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn color="info" @click="createOrUpdateEnvironment">Create Environment</v-btn>
                    <v-data-table
                      :items="subscription.cloudEnvironments"
                      :headers="environmentHeaders"
                    >
                    <template v-slot:item.cloudEnvironmentType="{ item }">
                      {{ environmentTypes.find(x => x.value == item.cloudEnvironmentType).text }}
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-btn icon @click="createOrUpdateEnvironment($event, item)"><v-icon>mdi-pencil</v-icon></v-btn>
                      <v-btn icon @click="deleteEnvironment(item)"><v-icon>mdi-delete</v-icon></v-btn>
                    </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn text @click="step = 2">Back</v-btn>
                <v-btn color="info" :loading="isSubmitted" :disabled="submitCloudAccountCheck" @click="submit">Create Subscription</v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      </div>
    </div>

    <v-dialog
        v-model="showEnvironmentDialog"
        width="800"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Create/Update Environment
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field v-model="currentEnvironment.displayName" label="Name" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select v-model="currentEnvironment.cloudEnvironmentType" label="Type" :items="environmentTypes" />
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="saveEnvironment"
            >
              Save Environment
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="displayFailedDialog" width="500">
          <v-card>
            <v-card-title :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'" primary-title>Error</v-card-title>
            <v-card-text>
              <v-container style="text-align: center" class="pt-10">
                <h1>Changes canceled</h1>
                <br>
                <p>Make sure both name and type have a value.</p>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="displayFailedDialog=false">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'cloud-builder',
  watch: {
    selectedCloudOrganizationId: function () {
      this.subscription.lineOfBusinessId = null
      this.filteredLinesOfBusinesses = []
      this.getCloudLob()
      this.lineOfBusiness.forEach(element => {
        if (element.organizationId === this.selectedCloudOrganizationId) {
          this.filteredLinesOfBusinesses.push(element)
        }
      })
    }
  },
  computed: {
    submitCloudAccountCheck: {
      get: function () {
        if (this.isSubmitted || this.subscription.cloudEnvironments.length === 0) {
          return true
        }
        return false
      }
    },
    subscriptionStep2Validity: {
      get: function () {
        if (this.subscription.cloudProvider && this.subscription.displayName && this.subscription.tcoCode && this.subscription.lineOfBusinessId && this.subscription.departmentId && this.subscription.coId && this.subscription.appCode && this.subscription.planviewProjectId) {
          return true
        }
        return false
      },
      set: function () {
        // ignore
      }
    }
  },
  data: () => ({
    cmdbName: '',
    loading: false,
    githubLinks: [],
    isLoadingCloudOrganizations: false,
    isLoadingCloudLobs: false,
    isSubmitted: false,
    step: 1,
    isOrganizationSelected: false,
    cloudOrganizations: [],
    lineOfBusiness: [],
    filteredLinesOfBusinesses: [],
    cloudProviders: [
      {
        name: 'GCP',
        value: 1
      }
    ],
    cmdbHeaders: [
      {
        text: 'Name',
        align: 'start',
        sortable: false,
        value: 'name'
      },
      {
        text: 'Alias',
        align: 'start',
        sortable: false,
        value: 'u_alias'
      },
      {
        text: 'Description',
        align: 'start',
        sortable: false,
        value: 'short_description'
      },
      {
        text: 'Link',
        align: 'start',
        sortable: false,
        value: 'link'
      }
    ],
    environmentHeaders: [
      {
        text: 'Environment Name',
        align: 'start',
        sortable: false,
        value: 'displayName'
      },
      {
        text: 'Environment Type',
        align: 'start',
        sortable: false,
        value: 'cloudEnvironmentType'
      },
      {
        text: 'Actions',
        align: 'start',
        sortable: false,
        value: 'actions'
      }
    ],
    environmentTypes: [
      {
        value: 0,
        text: 'Sandbox'
      },
      {
        value: 1,
        text: 'Non Production'
      },
      {
        value: 2,
        text: 'Production'
      }
    ],
    cmdbItems: [],
    selectedCmdbProduct: null,
    selectedCloudOrganizationId: null,
    subscription: {
      cloudProvider: 1,
      lineOfBusinessId: null,
      displayName: null,
      serviceCentralAppInstanceId: null,
      planviewProjectId: null,
      departmentId: null,
      coId: null,
      tcoCode: null,
      githubOrganizationLink: null,
      cloudEnvironments: [ ]
    },
    currentEnvironment: {
      displayName: '',
      cloudEnvironmentType: ''
    },
    currentEnvironmentIndex: null,
    showEnvironmentDialog: false,
    organizationAdmins: [],
    displayFailedDialog: false,
    requiredField: [
      v => !!v || 'Please Provide a value'
    ],
    team: null,
    product: null
  }),
  methods: {
    cmdbSearch: function () {
      this.loading = true
      return axios(
        `${process.env.VUE_APP_CLOUDBASEURL}/cmdb?serviceName=${this.cmdbName}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.loading = false
            this.cmdbItems = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.cmdbSearch() })
          })
    },
    cmdbSelection: function (item) {
      this.selectedCmdbProduct = item
      this.subscription.serviceCentralAppInstanceId = item.sys_id
      item.isSelected = true
    },
    setSelectionStyle: function (item) {
      if (item && this.selectedCmdbProduct && item.sys_id === this.selectedCmdbProduct.sys_id) {
        return 'cmdb-selected'
      } else {
        return ''
      }
    },
    createOrUpdateEnvironment: function (click, item) {
      if (item) {
        this.currentEnvironment = item
        this.currentEnvironmentIndex = this.subscription.cloudEnvironments.indexOf(item)
      }
      this.showEnvironmentDialog = true
    },
    saveEnvironment: function () {
      if (this.currentEnvironment.displayName.length === 0 || this.currentEnvironment.cloudEnvironmentType.length === 0) {
        this.showEnvironmentDialog = false
        this.displayFailedDialog = true
      } else {
        if (this.currentEnvironmentIndex === null) {
          this.subscription.cloudEnvironments.push(JSON.parse(JSON.stringify(this.currentEnvironment)))
        }
        this.showEnvironmentDialog = false
        this.currentEnvironment = JSON.parse(JSON.stringify({
          displayName: '',
          cloudEnvironmentType: ''
        }))
        this.currentEnvironmentIndex = null
      }
    },
    submit: function () {
      this.isSubmitted = true

      axios(
        `${process.env.VUE_APP_CLOUDBASEURL}/cloud/accounts/products/${this.productId}`,
        {
          method: 'POST',
          data: JSON.stringify(this.subscription),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.$router.push(`/products/${this.productId}/cloud/accounts/${response.data.id}/deployments`)
          },
          (error) => {
            this.isSubmitted = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.submit() })
            return error
          })
    },
    getGithubLinks: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/github/teams/${this.product.teamId}/links`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.githubLinks = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getGithubLinks() })
            return error
          })
    },
    getCloudOrganizations: function () {
      this.isLoadingCloudOrganizations = true
      axios(
        `${process.env.VUE_APP_CLOUDBASEURL}/cloud/organizations?page=0&limit=100`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isLoadingCloudOrganizations = false
            this.cloudOrganizations = response.data.items
            this.getCloudLob()
          },
          (error) => {
            this.isLoadingCloudOrganizations = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getCloudOrganizations() })
            return error
          })
    },
    getCloudLob: function () {
      this.isLoadingCloudLobs = true
      axios(
        `${process.env.VUE_APP_CLOUDBASEURL}/cloud/lob?page=0&limit=100`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isLoadingCloudLobs = false
            this.lineOfBusiness = response.data.items
          },
          (error) => {
            this.isLoadingCloudLobs = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getCloudLob() })
            return error
          })
    },
    deleteEnvironment: function (item) {
      this.subscription.cloudEnvironments.splice(this.subscription.cloudEnvironments.indexOf(item), 1)
    },
    getProduct: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/product/${this.productId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.product = response.data
            if (this.product.isSubscriberOnly) {
              this.$store.commit('showErrorDialog', {
                errorData: {
                  response: {
                    data: {
                      errorMessage: 'This product can only be used to subscribe to APIs.'
                    }
                  }
                },
                persistErrorDialog: true,
                retryMethod: () => this.getProduct()
              })
            }
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getProduct() })
          })
    },
    getTeam: async function () {
      await axios(
        `${process.env.VUE_APP_BASEURL}/teams/${this.product.teamId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.team = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getTeam() })
          })
    }
  },
  mounted: async function () {
    if (this.$route.params.id) {
      this.productId = this.$route.params.id
      this.getCloudOrganizations()
      await this.getProduct().then(() => {
        this.$store.commit('breadcrumbs', [
          {
            text: 'My Products',
            exact: true,
            disabled: false,
            to: '/products'
          },
          {
            text: this.product.displayName,
            exact: true,
            disabled: false,
            to: `/products/${this.productId}`
          },
          {
            text: 'Cloud Subscription Builder',
            exact: true,
            disabled: false,
            to: `/products/${this.productId}/cloud/builder`
          }
        ])
        this.getGithubLinks()
        this.getTeam()
      })
    } else {
      alert('Could not find product as an ID was not provided in the url')
    }
  }
}
</script>

<style>
  .cmdb-selected {
    background-color:#FFF59D !important;
  }
</style>
