<template>
  <v-container>
    <v-row justify="center">
        <v-col cols="12" md="12" id="v-step-0">
            <h1 class="text-center">Create a Repository</h1>
             <p class="text-center">Follow the form below to build a repository</p>
        </v-col>
      </v-row>
    <v-divider/>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-card
          :loading="isSubmitted"
        >
          <template v-slot:progress>
            <v-progress-linear
              absolute
              color="green lighten-3"
              height="4"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field label="Repository Name" v-model="repository.displayName" />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-model="repository.repositoryType"
                    :items="repositoryType"
                    item-text="key"
                    item-value="value"
                    label="Repository Type"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="repository.repositoryType !== null">
                <v-col>
                  <v-checkbox
                    v-model="enableProxy"
                    label="Would you like to proxy this repo with publicly available packages?"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row v-if="enableProxy && repository.repositoryType !== null">
                <v-col>
                  <v-select
                    label="Select a public repo source to use when pulling publicly available packages"
                    :items="proxyRepos"
                    item-text="displayName"
                    item-value="id"
                    v-model="repository.proxyRepositoryId"
                    :loading="isLoadingProxyRepos"
                    no-data-text="No public repositories are available for the selected repository type" />
                </v-col>
              </v-row>
              <v-row>
                <v-col id="v-step-6">
                  <v-btn style="color: white !important;" color="#E05929" :disabled="isSubmitted" @click="create">Create</v-btn>
                </v-col>
              </v-row>
              <v-row v-if="!validated">
                <v-col class="text-center" >
                  <span style="color: #ff5252 !important">There are errors with your request. Please check the form above and try again</span>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'RepositoryBuilder',
  watch: {
    enableProxy: function (value) {
      if (value) {
        this.getProxyRepoForType()
      }
    },
    repository: {
      handler: function (oldValue, newValue) {
        if (this.enableProxy && oldValue.repositoryType !== newValue.repositoryType) {
          this.getProxyRepoForType()
        }
      },
      deep: true
    }
  },
  data: () => ({
    teamId: null,
    isLoadingProxyRepos: false,
    isSubmitted: false,
    validated: true,
    proxyRepos: [ ],
    repositoryType: [
      {
        key: 'Nuget',
        value: 0
      },
      {
        key: 'NPM',
        value: 1
      },
      {
        key: 'Maven',
        value: 2
      },
      {
        key: 'Docker',
        value: 3
      },
      {
        key: 'Pypi',
        value: 4
      },
      {
        key: 'Bower',
        value: 5
      },
      {
        key: 'Helm',
        value: 6
      },
      {
        key: 'Raw',
        value: 7
      }
    ],
    repository: {
      teamId: '',
      repositoryType: 0,
      name: '',
      displayName: '',
      proxyRepositoryId: null
    },
    enableProxy: false
  }),
  computed: {
    ...mapGetters([
      'oidcUser'
    ])
  },
  methods: {
    validate: function () {
      this.validated = true

      if (!this.$refs.form.validate()) {
        this.validated = false
      }
      return this.validated
    },
    create: function () {
      this.isSubmitted = true

      if (!this.validate()) {
        this.isSubmitted = false
        return false
      }

      this.repository.teamId = this.teamId

      axios(
        `${process.env.VUE_APP_REPO_BASEURL}/repositories/teams/${this.teamId}/`,
        {
          method: 'POST',
          data: JSON.stringify(this.repository),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isSubmitted = false
            this.$router.push(`/teams/${this.teamId}/repositories/${response.data.id}`)
          },
          (error) => {
            this.isSubmitted = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.create() })
            return error
          })
    },
    getProxyRepoForType: function () {
      this.isLoadingProxyRepos = true
      axios(
        `${process.env.VUE_APP_REPO_BASEURL}/repositories/proxies?repositoryType=${this.repository.repositoryType}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.proxyRepos = response.data
            this.isLoadingProxyRepos = false
          },
          (error) => {
            this.isSubmitted = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.create() })
            return error
          })
    }
  },
  mounted: function () {
    if (this.$route.params.id) {
      this.teamId = this.$route.params.id
    } else {
      alert('cannot load because no team id was found in the url')
    }
  }
}
</script>
<style scoped>
  .required label::after {
    content: "*";
    color: 'red'
}
</style>
