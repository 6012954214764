<template>
  <v-row class="mt-2">
    <v-col>
      <v-row>
        <v-col cols="12" md="12" v-if="adminSettings && (adminSettings.enableRepositoryFeatures || adminSettings.isSuperUser)">
          <v-btn color="primary" :to="generateOrganizationBuilderLink">Link a new Github Organization</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="githubLinkHeader"
            :items="teamOrganizations"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:item.status="{ item }">
              <v-chip
                color="primary"
                dark
                v-if="item.status === 0"
              >
                Installed
              </v-chip>
              <v-chip
                color="warning"
                dark
                v-else
              >
                Error: Please contact an administrator
              </v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn target="_blank" :href="`https://github.com/organizations/${item.orgName}/settings/installations/${item.installationId}`" icon><v-icon>mdi-cog</v-icon></v-btn>
              <v-btn icon @click="displayGithubDeleteDialog(item.id)"><v-icon>mdi-delete</v-icon></v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'

export default {
  name: 'team-github-organizations',
  computed: {
    ...mapGetters(['adminSettings']),
    ...mapGetters('teams', ['teamOrganizations', 'teamId']),
    generateOrganizationBuilderLink: function () {
      return `/github-app/${this.teamId}`
    }
  },
  watch: {
  },
  data: () => ({
    githubLinkHeader: [
      {
        text: 'Organization',
        align: 'left',
        sortable: false,
        value: 'orgName'
      },
      {
        text: 'Installation Status',
        align: 'left',
        sortable: false,
        value: 'status'
      },
      {
        text: 'Actions',
        align: 'left',
        sortable: false,
        value: 'actions'
      }
    ]
  }),
  methods: {
    ...mapActions({
      getTeamOrganizations: 'teams/getTeamOrganizations'
    }),
    generateRepoLink: function (repoId) {
      return `/teams/${this.teamId}/repositories/${repoId}`
    },
    displayGithubDeleteDialog: function (linkId) {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to delete this Github Link? This will delete the evolve marketplace link but the github app installation on your github organization will still exist.',
        confirmMethod: () => {
          this.deleteApp(linkId)
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    deleteApp: function (linkId) {
      axios(
        `${process.env.VUE_APP_BASEURL}/github/teams/${this.teamId}/links/${linkId}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          () => {
            this.getTeamOrganizations()
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getAffiliates() })
            return error
          })
    }
  },
  mounted: function () {
  }
}
</script>

<style scoped>
</style>
