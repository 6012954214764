<template>
  <div>
  <v-row>
    <v-col cols="12" md="12">
      <span class="text-h5">Git Ignore Settings</span>
    </v-col>
    <v-col cols="12" md="12">
      <span class="text-subtitle">You can provide a gitignore line item below and specify what behavior you'd like the files that match this item to take.</span>
    </v-col>
     <v-col cols="12" md="12" v-if="releaseDetails.release.settings">
      <v-btn @click="addItem">Add Item</v-btn>
      <v-data-table
        :headers="headers"
        :items="ignoreSettings"
        :items-per-page="15"
      >
        <template v-slot:item.pattern="{ item }">
            <v-text-field v-model="item.pattern" />
        </template>
        <template v-slot:item.action="{ item }">
            <v-select item-text="text" item-value="value" :items="actionItems" v-model="item.action" />
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon @click="removeItem(item)">
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
  </div>
</template>

<script>
export default {
  props: {
    template: {
      required: true
    },
    releaseDetails: {
      required: true
    },
    triggerRefresh: {
      required: true
    }
  },
  watch: {
    triggerRefresh: {
      deep: true,
      handler: function () {
        this.ignoreSettings = this.releaseDetails.release.settings.ignoreFiles
      }
    },
    ignoreSettings: {
      deep: true,
      handler: function () {
        if (this.releaseDetails.release.settings.ignoreFiles !== this.ignoreSettings) {
          this.releaseDetails.release.settings.ignoreFiles = this.ignoreSettings
        }
      }
    }
  },
  data: () => ({
    ignoreSettings: [],
    headers: [
      {
        text: 'Pattern',
        align: 'start',
        sortable: false,
        value: 'pattern'
      },
      {
        text: 'Action',
        align: 'start',
        sortable: false,
        value: 'action'
      },
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'actions'
      }
    ],
    actionItems: [
      {
        text: 'Ignore File (does not include file in installation)',
        value: 0
      },
      {
        text: 'Ignore Tokens (includes file but ignores any tokenizations in file text)',
        value: 1
      }
    ]
  }),
  computed: {
  },
  methods: {
    addItem: function () {
      this.ignoreSettings.push({
        pattern: '',
        action: 0
      })
    },
    removeItem: function (item) {
      let index = this.ignoreSettings.indexOf(item)
      this.ignoreSettings.splice(index, 1)
    }
  },
  beforeMount: function () {
    if (!this.releaseDetails.release.settings.ignoreFiles) {
      this.releaseDetails.release.settings.ignoreFiles = []
    }
    this.ignoreSettings = this.releaseDetails.release.settings.ignoreFiles
  }
}
</script>

<style scoped>

</style>
