<template>
  <div>
    <v-navigation-drawer
      absolute
      :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
      permanent
      v-if="productId"
    >
      <v-list nav dense>
       <template v-for="item in links">
        <v-list-item
          link
          v-if="!item.subItems && item.permission"
          :to="generateLink(item.link)"
          :key="item.title"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <template v-if="item.subItems">
          <v-list-group
            :key="item.title"
            :prepend-icon="item.icon"
            no-action
            :value = "shouldExpand(item)"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-if="item.subItems">
            <v-list-item
              :prepend-icon="item.icon"
              v-for="child in item.subItems"
              :key="child.title"
              link
              :to="generateLink(child.link)"
            >
              <v-list-item-icon> <v-icon> {{child.icon }} </v-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item-content>
            </v-list-item>
            </template>
          </v-list-group>
        </template>
       </template>
      </v-list>
    </v-navigation-drawer>
    <div style="margin-left: 256px">
     <router-view></router-view>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'EditProduct',
  data: () => ({
    links: [
      {
        icon: 'mdi-cog',
        title: 'Settings',
        link: `settings`,
        permission: true,
        subItems: [
          {
            icon: 'mdi-cog',
            title: 'General Settings',
            link: `settings`,
            display: { val: '' }
          }
        ]
      },
      {
        icon: 'mdi-api',
        title: 'Apis',
        link: `apis`,
        permission: true,
        subItems: [
          {
            icon: 'mdi-key',
            title: 'Keys',
            link: `keys`
          },
          {
            icon: 'mdi-api',
            title: 'Apis',
            link: `apis`
          },
          {
            icon: 'mdi-bookmark',
            title: 'Subscriptions',
            link: `subscriptions`,
            permission: true
          }
        ]
      }

      /*
      {
        icon: 'mdi-cloud',
        title: 'Cloud Accounts',
        link: `cloudAccounts`,
        permission: false
      }
      */
    ],
    productId: '',
    product: {}
  }),
  methods: {
    shouldExpand: function (item) {
      let path = this.$router.currentRoute.path
      let subItemPath = item.subItems.find(x => {
        return this.generateLink(x.link) === path
      })

      if (subItemPath) {
        return true
      }
      return false
    },
    generateLink: function (subItem) {
      return `/products/${this.$route.params.id}/${subItem}`
    },
    userHasPermissionToSeeTab: function () {
      if (this.product.enableCloudCreation) {
        return true
      } else {
        return false
      }
    },
    getProduct: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/product/${this.productId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            var res = response.data
            res.appKeys.forEach(x => {
              x.showKeys = false
            })

            this.product = res
            if (this.product.allowedUsers) {
              this.allowedUsers = [ ]
              this.product.allowedUsers.forEach(x => {
                this.getUserDetails(x).then(x => {
                  if (x) {
                    this.allowedUsers.push(x[0])
                  }
                })
              })
            }

            if (this.product.allowedGroups) {
              this.allowedGroups = this.product.allowedGroups
            }
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getProduct() })
          })
    },
    getApis: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/product/${this.productId}/apis`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.apis = response.data
            this.apis.forEach(x => this.getApiHealthAtAGlance(x))
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getApis() })
          })
    },
    getUserDetails: function (username) {
      return axios(
        `${process.env.VUE_APP_BASENETWORKAPIURL}/activedirectory/search/user?searchText=${username}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getUserDetails() })
          })
    },
    getApiHealthAtAGlance: function (api) {
      axios(
        `${process.env.VUE_APP_BASEURL}/tests/api/${api.id}/glance`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            api.health = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getApis() })
          })
    }
  },
  mounted: async function () {
    if (this.$route.params.id) {
      this.productId = this.$route.params.id
      await this.getProduct().then(() => {
        this.$store.commit('breadcrumbs', [
          {
            text: 'My Products',
            exact: true,
            disabled: false,
            to: '/products'
          },
          {
            text: this.product.displayName,
            exact: true,
            disabled: false,
            to: `/products/${this.productId}`
          }
        ])
      })
      for (let index = 0; index < this.links.length; index++) {
        let element = this.links[index]
        if (element.title === 'Cloud Accounts') {
          element.permission = this.product.enableCloudAccountCreation
        }
      }
    } else {
      alert('could not find product as an ID was not provided in the url')
    }
  }
}
</script>
