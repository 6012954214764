<template>
  <v-card>
    <v-card-title>Install Template {{ template.displayName }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <span class="text-subtitle1">This template doesn't have any specific installation steps. Follow the read me to learn how to leverage it.</span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer></v-spacer>
        <v-btn @click="closeModal">Close</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios'

export default {
  name: 'github-action-install-component',
  watch: {
  },
  props: {
    template: {
      required: true
    },
    releaseDetails: {
      required: true
    }
  },
  data: () => ({
  }),
  methods: {
    closeModal: function () {
      this.$emit('closeInstallModal')
    }
  },
  beforeMount: function () {
  }
}
</script>

<style scoped>

</style>
