<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6" id="v-step-0">
          <h1 style="font-size: 36px">Software Templates</h1>
          <v-icon color="info">mdi-help-circle</v-icon>
          <a class="pl-1" target="_blank" href="">Need help using templates</a>
      </v-col>
    </v-row>
    <v-row class="pb-0 mb-0">
        <v-col cols="12" sm="12" md="5" id="v-step-1" class="pb-0 mb-0">
          <v-text-field
            label="Name, Description, Tag"
            hint="Search for templates by name, description, or tag"
            persistent-hint
            prepend-inner-icon="mdi-magnify"
            placeholder=""
            solo
            clearable
            v-model="search"
            :loading="isLoadingTemplates"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" class="pb-0 mb-0">
          <v-select
            solo
            hint="Sort Ordering"
            persistent-hint
            :items="orderByOptions"
            item-text="itemText"
            item-value="itemValue"
            v-model="orderBy"
            label="Sort By"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="mb-2">
            <v-slide-group
              show-arrows
              mandatory
              v-model="selectedTemplateUiItem"
            >
              <v-slide-item
                v-slot="{ active, toggle }"
              >
                <v-btn
                  class="mx-2"
                  :input-value="active"
                  active-class="blue white--text"
                  depressed
                  rounded
                  @click="toggle"
                >
                  Projects
                </v-btn>
            </v-slide-item>
            <v-slide-item
                v-slot="{ active, toggle }"
              >
                <v-btn
                  class="mx-2"
                  :input-value="active"
                  active-class="blue white--text"
                  depressed
                  rounded
                  @click="toggle"
                >
                  Github Actions
                </v-btn>
            </v-slide-item>
            <v-slide-item
                v-slot="{ active, toggle }"
              >
                <v-btn
                  class="mx-2"
                  :input-value="active"
                  active-class="blue white--text"
                  depressed
                  rounded
                  @click="toggle"
                >
                  Terraform Modules
                </v-btn>
            </v-slide-item>
            <v-slide-item
                v-slot="{ active, toggle }"
              >
                <v-btn
                  class="mx-2"
                  :input-value="active"
                  active-class="blue white--text"
                  depressed
                  rounded
                  @click="toggle"
                >
                  Legacy Terraform Modules
                </v-btn>
            </v-slide-item>
          </v-slide-group>
      </v-col>
    </v-row>
    <v-divider class="divider"/>
    <v-row v-if="selectedType === 6">
      <v-col>
        <v-alert
          dense
          outlined
          type="info"
        >
          These templates link out to Terraform Cloud. If you haven't yet been provisioned in terraform cloud <a @click="provisionUser">click here</a>
        </v-alert>
      </v-col>
    </v-row>
    <v-row class="mt-3" v-if="isLoadingTemplates && templates.length == 0">
      <template-skeleton />
    </v-row>
    <v-row>
      <v-col>
        <span v-if="templates.length === 0 && !isLoadingTemplates">No Templates found</span>
        <v-row>
          <v-col cols="12" xl="4" lg="6" v-for="(item) in templates" :key="item.id">
            <v-card
              v-if="item.templateType === 6"
              class="mx-auto"
              min-height="170"
              hover
              ripple
              :href="`https://app.terraform.io/app/hca-healthcare/registry/modules/private/${item.name}`"
              :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
            >
              <v-list-item three-line>
                <v-list-item-avatar tile size="75"><v-img contain src="@/assets/template.svg" /></v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="headline">{{ item.displayName }}</v-list-item-title>
                  <v-list-item-subtitle class="description">{{ item.description }}</v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <v-chip
                      v-for="(tag, tagIndex) in item.tags"
                      :key="tagIndex"
                      class="ma-1 pa-3"
                    >
                      {{tag}}
                    </v-chip>
                    <v-chip :color="getItemTemplateColor(item)" class="ma-2">{{ getItemTemplateName(item) }}</v-chip>
                    <v-chip class="ma-2">{{ item.latestRelease }}</v-chip>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
            <v-card
              v-else
              class="mx-auto"
              min-height="170"
              hover
              ripple
              :to="`/templates/${item.id}`"
              :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
            >
              <v-list-item three-line>
                <v-list-item-avatar tile size="75"><v-img contain src="@/assets/template.svg" /></v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="headline">{{ item.displayName }}</v-list-item-title>
                  <v-list-item-subtitle class="description">{{ item.description }}</v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <v-chip
                      v-for="(tag, tagIndex) in item.tags"
                      :key="tagIndex"
                      class="ma-1 pa-3"
                    >
                      {{tag}}
                    </v-chip>
                    <v-chip :color="getItemTemplateColor(item)" class="ma-2">{{ getItemTemplateName(item) }}</v-chip>
                    <v-chip class="ma-2">{{ item.latestRelease }}</v-chip>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="count > 1">
          <v-col>
            <v-pagination
              v-model="page"
              :length="count"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="showProvisioningUserDialog" width="500" height="600">
       <v-card v-if="isProvisioningUser">
        <v-card-title>Please wait while your account is provisioned in Terraform Cloud</v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="d-flex justify-center text-center">
              <v-progress-circular
                :size="75"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title>Successfully Provisioned</v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="d-flex justify-center text-center">
              <span>Your account has been successfully provisioned in Terraform Cloud. If you were not already a member, you will receive an email to your HCA email account with a link to accept the invitation. You may now close this window</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="showProvisioningUserDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import templateSkeleton from './components/templateSkeleton'

export default {
  name: 'List-Templates',
  components: {
    'template-skeleton': templateSkeleton
  },
  watch: {
    selectedTemplateUiItem: function () {
      this.page = 1
      if (this.selectedTemplateUiItem === 0) {
        this.selectedType = 3
        this.page = 1
        this.templates = [ ]
        this.getTemplates()
      } else if (this.selectedTemplateUiItem === 1) {
        this.selectedType = 0
        this.page = 1
        this.templates = [ ]
        this.getTemplates()
      } else if (this.selectedTemplateUiItem === 2) {
        this.selectedType = 6
        this.page = 1
        this.templates = [ ]
        this.getTemplates()
      } else if (this.selectedTemplateUiItem === 3) {
        this.selectedType = 2
        this.page = 1
        this.templates = [ ]
        this.getTemplates()
      }
    },
    search: function () {
      clearTimeout(this.searchTimeout)

      // Make a new timeout set to go off in 800ms
      this.searchTimeout = setTimeout(() => {
        if (this.search == null) {
          this.search = ''
        }
        this.page = 1
        this.getTemplates()
      }, 500)
    },
    page: function (newValue, oldValue) {
      if (oldValue !== newValue) {
        this.getTemplates()
      }
    },
    orderBy: function () {
      this.page = 1
      this.getTemplates()
    }
  },
  data: () => ({
    showProvisioningUserDialog: false,
    isProvisioningUser: true,
    templates: [ ],
    searchTimeout: null,
    page: 1,
    limit: 10,
    count: 0,
    search: '',
    isLoadingTemplates: false,
    orderBy: 'name',
    selectedType: 3,
    selectedTemplateUiItem: 0,
    types: [
      {
        itemText: 'Github Actions',
        itemValue: 0
      },
      {
        itemText: 'Terraform Modules',
        itemValue: 1
      },
      {
        itemText: 'Project',
        itemValue: 3
      }
    ],
    orderByOptions: [
      {
        itemText: 'Template Name (A - Z)',
        itemValue: 'name'
      },
      {
        itemText: 'Creation Date (Descending)',
        itemValue: 'date'
      }
    ]
  }),
  methods: {
    getItemTemplateColor: function (template) {
      if (template.templateType === 0) {
        return '#B2EBF2'
      } else if (template.templateType === 2) {
        return '#66BB6A'
      } else if (template.templateType === 3) {
        return '#FFECB3'
      } else if (template.templateType === 6) {
        return '#4DB6AC'
      } else {
        return '#B39DDB'
      }
    },
    getItemTemplateName: function (template) {
      if (template.templateType === 0) {
        return 'Github Action'
      } else if (template.templateType === 2) {
        return 'Legacy Terraform'
      } else if (template.templateType === 3) {
        return 'Project'
      } else if (template.templateType === 6) {
        return 'Terraform'
      } else {
        return 'Unknown Type'
      }
    },
    getTemplates: function () {
      this.isLoadingTemplates = true
      axios(
        `${process.env.VUE_APP_TEMPLATEBASEURL}/api/templates?page=${this.page}&limit=${this.limit}&search=${this.search}&orderBy=${this.orderBy}&type=${this.selectedType}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isLoadingTemplates = false
            this.templates = response.data.items
            this.count = Math.ceil(response.data.totalCount / this.limit)
          },
          (error) => {
            this.isLoadingTemplates = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getTemplates() })
          })
    },
    provisionUser: function () {
      this.showProvisioningUserDialog = true
      this.isProvisioningUser = true
      axios(
        `${process.env.VUE_APP_TEMPLATEBASEURL}/api/templates/provision-terraform`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isProvisioningUser = false
          },
          (error) => {
            this.isProvisioningUser = false
            this.showProvisioningUserDialog = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.provisionUser() })
          })
    }
  },
  beforeMount: function () {
    this.getTemplates()
    this.$store.commit('breadcrumbs', [
      {
        text: 'Templates',
        exact: true,
        disabled: false,
        to: '/templates'
      }
    ])
  }
}
</script>

<style scoped>
  .description {
    margin-left: 1px !important;
  }

  .divider {
    margin-bottom: 15px !important;
  }
</style>
