<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <v-btn dark color="primary" @click="routeToCloudAccount">Create a cloud account</v-btn>
      </v-col>
    </v-row>
      <v-row v-if="isLoading === false">
      <v-col v-if="cloudAccounts.length != 0 && isLoading === false" cols="12">
        <v-row align="center" justify="start">
          <v-card
            class="ma-2 pa-1"
            :class="{ inProgress: (account.deploymentStatus === 1), complete: (account.deploymentStatus === 2), incomplete: (account.deploymentStatus === 0 || account.deploymentStatus === null), failed: (account.deploymentStatus === 3) }"
            hover
            outlined
            ripple
            v-for="(account, index) in cloudAccounts" :key="index"
            :to="`/products/${productId}/cloud/accounts/${account.id}/deployments`"
            max-width="344"
            min-width="344"
            :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
          >
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="api-name"> <strong> {{ account.displayName }} </strong></v-list-item-title>
                <v-list-item-subtitle> <strong>AppCode: </strong> {{ account.appCode }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="account.deploymentStatus === 2"> <strong>Deployment Status: </strong> Successful</v-list-item-subtitle>
                <v-list-item-subtitle v-else-if="account.deploymentStatus === 0 || account.deploymentStatus === null"> <strong>Deployment Status: </strong> Pending</v-list-item-subtitle>
                <v-list-item-subtitle v-else-if="account.deploymentStatus === 1"> <strong>Deployment Status: </strong> In progress</v-list-item-subtitle>
                <v-list-item-subtitle v-else> <strong>Deployment Status: </strong> Failed</v-list-item-subtitle>
                <v-list-item-subtitle class="d-flex flex-wrap mt-2">
                  <v-chip>
                    <v-img
                      v-if="account.cloudProvider === 0"
                      class="mr-2"
                      src="@/assets/cloud/azure.png"
                      contain
                      style="width: 20px"
                    />
                    <v-img
                      v-if="account.cloudProvider === 2"
                      class="mr-2"
                      src="@/assets/cloud/aws.png"
                      contain
                      style="width: 20px"
                    />
                    <v-img
                      v-if="account.cloudProvider === 1"
                      class="mr-2"
                      src="@/assets/cloud/gcp.png"
                      contain
                      style="width: 20px"
                    />
                    {{ getProviderName(account.cloudProvider) }}</v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-row>
      </v-col>
      <v-col v-else cols="12">
        No Cloud Accounts found for this product.
      </v-col>
    </v-row>
    <v-row v-else>
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import marked from 'marked'
import userSearchComponent from '@/components/userSearchComponent'
import groupSearchComponent from '@/components/groupSearchComponent'

export default {
  name: 'productCloudAccounts',
  watch: {
    currentUserSearch: function (newValue, oldValue) {
      if (newValue) {
        if (!this.allowedUsers.find(x => x.Key === newValue.Key)) {
          this.allowedUsers.push(newValue)
        }
      }
    },
    currentGroupSearch: function (newValue, oldValue) {
      if (newValue) {
        if (!this.allowedGroups.find(x => x === newValue)) {
          if (newValue.toLowerCase().startsWith('domain')) {
            this.$store.commit('showErrorDialog',
              {
                errorData: {
                  response: {
                    data: {
                      errorMessage: 'You cannot use a Domain Group for this list. Please select a more granular group'
                    }
                  }
                },
                persistErrorDialog: false
              })
          } else {
            this.allowedGroups.push(newValue)
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(['adminSettings']),
    groupedApiSubscriptions: function () {
      if (this.product.subscriptions) {
        let subscriptions = this.product.subscriptions.filter(x => {
          return x.apiName !== null
        }).reduce((acc, obj) => {
          let key = obj['apiName']
          if (!acc[key]) {
            acc[key] = []
          }
          acc[key].push(obj)
          return acc
        }, {})
        return subscriptions
      } else {
        return [ ]
      }
    },
    internalEnvironmentAppKeys: function () {
      let keys = [ ]
      if (this.environments) {
        let envs = this.environments.filter(x => {
          return x.isInternetAccessible === false
        })

        envs.forEach(x => {
          if (this.product && this.product.appKeys) {
            let key = this.product.appKeys.find(y => {
              return y.environmentName === x.name
            })
            x.key = key
            keys.push(x)
          }
        })
      }
      return keys
    },
    externalEnvironmentAppKeys: function () {
      let keys = [ ]
      if (this.environments) {
        let envs = this.environments.filter(x => {
          return x.isInternetAccessible === true
        })

        envs.forEach(x => {
          if (this.product && this.product.appKeys) {
            let key = this.product.appKeys.find(y => {
              return y.environmentName === x.name
            })
            x.key = key
            keys.push(x)
          }
        })
      }
      return keys
    },
    isEditDisabled: function () {
      return this.product.isApiManagedOnly
    }
  },
  data: () => ({
    cloudAccounts: [ ],
    previewMarkdownDialog: false,
    resettingKeys: [ ],
    teams: [ ],
    isLoading: false,
    validated: true,
    generatingToken: false,
    recreatingToken: false,
    copyText: 'Copy to clipboard',
    environments: [ ],
    unsubscriptionDialog: false,
    selectedUnsubscribeApi: { },
    selectedUnsubscribeSubscriptionId: null,
    tabView: 0,
    productId: '',
    userError: false,
    isSubmitted: false,
    isDeletingProduct: false,
    currentUserSearch: null,
    currentGroupSearch: null,
    allowedUsers: [ ],
    allowedGroups: [ ],
    product: {
      displayName: '',
      description: '',
      allowedUsers: [ ],
      subscriptions: [ ]
    },
    affiliates: [ ],
    apis: [ ],
    descriptionRules: [
      v => !!v || 'A Description is required',
      v => (v && v.length < 500) || 'The Description must be less than 500 characters'
    ],
    teamRules: [
      v => !!v || 'A Team is required'
    ],
    cloudProviders: [
      {
        value: 0,
        name: 'Azure',
        color: '#E1BEE7'
      },
      {
        value: 1,
        name: 'GCP',
        color: '#FFCDD2'
      },
      {
        value: 2,
        name: 'AWS',
        color: '#C8E6C9'
      },
      {
        value: 3,
        name: 'On Prem',
        color: '#808080'
      }
    ]
  }),
  methods: {
    getProviderColor: function (providerValue) {
      let provider = this.cloudProviders.find(x => x.value === providerValue)
      return provider.color
    },
    getProviderName: function (providerValue) {
      let provider = this.cloudProviders.find(x => x.value === providerValue)
      return provider.name
    },
    compiledMarkdown: function (input) {
      return marked(input, { sanitize: true })
    },
    getMyTeams: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/teams?showOwnedTeamsOnly=true`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.teams = response.data.items
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getAllTeams() })
          })
    },
    resetKeyDialog: function (consumerKey) {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to reset this key? This action cannot be undone. You will need to update the consumer key and secret in all your applications utilizing this Key/Secret',
        confirmMethod: () => {
          this.resetKey(consumerKey)
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    resetKey: function (consumerKey) {
      this.recreatingToken = true
      this.resettingKeys.push(consumerKey)
      return axios(
        `${process.env.VUE_APP_BASEURL}/product/${this.productId}/token/${consumerKey}/`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          () => {
            this.getProduct()
            this.recreatingToken = false
            this.resettingKeys = this.resettingKeys.filter(x => {
              return x !== consumerKey
            })
            this.$store.commit('triggerSnack', { snackMessage: 'The token has been revoked and recreated successfully', snackColor: 'success' })
          },
          (error) => {
            this.resettingKeys = this.resettingKeys.filter(x => {
              return x !== consumerKey
            })
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.resetKey(consumerKey) })
          })
    },
    generateToken: function (environmentId) {
      this.generatingToken = true
      return axios(
        `${process.env.VUE_APP_BASEURL}/product/${this.productId}/environments/${environmentId}/token`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.$copyText(response.data.access_token).then(x => {
              this.$store.commit('triggerSnack', { snackMessage: 'The token has been copied to your clipboard', snackColor: 'success' })
              this.generatingToken = false
            }, function (e) {
              this.$store.commit('triggerSnack', { snackMessage: 'Failed to obtain token. Please try again later', snackColor: 'error' })
              this.generatingToken = false
            })
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.generateToken(environmentId) })
            this.generatingToken = false
          })
    },
    copyMouseClick: function () {
      this.copyText = 'Copied!'
    },
    copyMouseLeave: function () {
      setTimeout(x => {
        this.copyText = 'Copy to clipboard'
      }, 200)
    },
    validate: function () {
      this.validated = true
      this.userError = false
      if (this.allowedUsers.length <= 0 && this.allowedGroups.length <= 0) {
        this.userError = true
        this.validated = false
      }
      if (!this.$refs.form.validate()) {
        this.validated = false
      }
      return this.validated
    },
    revokeSubscriptionRequest: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/subscriptions/${this.selectedUnsubscribeSubscriptionId}/revoke`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.product.subscriptions = this.product.subscriptions.filter(x => {
              return x.subscriptionId !== this.selectedUnsubscribeSubscriptionId
            })
            this.selectedUnsubscribeApi = { }
            this.selectedUnsubscribeSubscriptionId = null
            this.unsubscriptionDialog = false
            this.$store.commit('triggerSnack', { snackMessage: 'The subscription has been revoked successfully', snackColor: 'success' })
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.revokeSubscriptionRequest() })
          })
    },
    deleteUser: function (username) {
      this.allowedUsers = this.allowedUsers.filter((item) => {
        return item.Key !== username
      })
    },
    deleteGroup: function (group) {
      this.allowedGroups = this.allowedGroups.filter((item) => {
        return item !== group
      })
    },
    getUserDetails: function (username) {
      return axios(
        `${process.env.VUE_APP_BASENETWORKAPIURL}/activedirectory/search/user?searchText=${username}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getUserDetails() })
          })
    },
    getProduct: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/product/${this.productId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            var res = response.data
            res.appKeys.forEach(x => {
              x.showKeys = false
            })

            this.product = res
            if (this.product.allowedUsers) {
              this.allowedUsers = [ ]
              this.product.allowedUsers.forEach(x => {
                this.getUserDetails(x).then(x => {
                  if (x) {
                    this.allowedUsers.push(x[0])
                  }
                })
              })
            }

            if (this.product.allowedGroups) {
              this.allowedGroups = this.product.allowedGroups
            }
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getProduct() })
          })
    },
    getApis: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/product/${this.productId}/apis`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.apis = response.data
            this.apis.forEach(x => this.getApiHealthAtAGlance(x))
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getApis() })
          })
    },
    getApiHealthAtAGlance: function (api) {
      axios(
        `${process.env.VUE_APP_BASEURL}/tests/api/${api.id}/glance`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            api.health = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getApis() })
          })
    },
    getCloudAccounts: async function () {
      await axios(
        `${process.env.VUE_APP_CLOUDBASEURL}/cloud/accounts/products/${this.productId}/`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.cloudAccounts = response.data.items
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getCloudAccounts() })
            return error
          })
    },
    routeToApiBuilder: function () {
      this.$router.push(`/products/${this.productId}/builder`)
    },
    routeToApi: function (id) {
      this.$router.push(`/apis/${id}`)
    },
    routeToApiPublicView: function (id) {
      this.$router.push(`/api-view/${id}`)
    },
    update: function () {
      this.isSubmitted = true

      if (!this.validate()) {
        this.isSubmitted = false
        return false
      }

      if (this.allowedUsers) {
        this.product.allowedUsers = [ ]
        this.allowedUsers.forEach(x => {
          this.product.allowedUsers.push(x.Key)
        })
      }

      if (this.allowedGroups) {
        this.product.allowedGroups = this.allowedGroups
      }

      axios(
        `${process.env.VUE_APP_BASEURL}/product/${this.productId}`,
        {
          method: 'PUT',
          data: JSON.stringify(this.product),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isSubmitted = false
            this.getProduct()
            this.$store.commit('triggerSnack', { snackMessage: 'The product has been updated successfully', snackColor: 'success' })
          },
          (error) => {
            this.isSubmitted = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.update() })
            return error
          })
    },
    getEnvironments: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/environments?internetAccessible=true`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.environments = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getEnvironments() })
          })
    },
    getAffiliates: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/affiliates/`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.affiliates = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getAffiliates() })
            return error
          })
    },
    displayDeleteDialog: function () {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to delete this Product? This action can not be undone and will delete all APIs under this product and the product.',
        confirmMethod: () => {
          this.deleteProduct()
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    deleteProduct: function () {
      this.isDeletingProduct = true
      return axios(
        `${process.env.VUE_APP_BASEURL}/product/${this.productId}/`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.$router.push(`/products/`)
          },
          (error) => {
            this.isDeletingProduct = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.deleteProduct() })
          })
    },
    routeToCloudAccount: function () {
      this.$router.push(`/products/${this.productId}/cloud/builder`)
    }
  },
  mounted: async function () {
    this.isLoading = true
    if (this.$route.params.id) {
      this.productId = this.$route.params.id
      await this.getProduct().then(async () => {
        await this.getCloudAccounts()
        this.$store.commit('breadcrumbs', [
          {
            text: 'My Products',
            exact: true,
            disabled: false,
            to: '/products'
          },
          {
            text: this.product.displayName,
            exact: true,
            disabled: false,
            to: `/products/${this.productId}`
          },
          {
            text: 'Cloud Accounts',
            exact: true,
            disabled: false,
            to: `/products/${this.productId}/`
          }
        ])
      })
      this.getMyTeams()
      this.getApis()
      this.getAffiliates()
      this.getEnvironments()
      this.isLoading = false
    } else {
      alert('could not find product as an ID was not provided in the url')
    }
  }
}
</script>

<style scoped>
  .user-table {
    border: 1px solid rgba(0, 0, 0, 0.4);
  }
  .key-table {
    /* border: 1px solid rgba(0, 0, 0, 0.4);
    padding: 5px; */
    margin: 2px;
    margin-bottom: 15px;
  }
  .user-error {
    font-size: 12px;
  }

  .api-name {
    margin-bottom: 10px;
  }

  .theme--light.inProgress {
    border-left: 8px solid #ff7b00 !important;
  }
  .theme--dark.inProgress {
    border-left: 8px solid #ff7b00 !important;
  }
  .theme--light.complete {
    border-left: 8px solid #009137 !important;
  }
  .theme--dark.complete {
    border-left: 8px solid #009137 !important;
  }
  .theme--light.incomplete {
    border-left: 8px solid #ffc845 !important;
  }
  .theme--dark.incomplete {
    border-left: 8px solid #ffc845 !important;
  }
  .theme--light.failed {
    border-left: 8px solid #ff0000 !important;
  }
  .theme--dark.failed {
    border-left: 8px solid #ff0000 !important;
  }

  .visible {
    margin-bottom: 8px;
  }

  .generate-token {
    margin-top:15px;
    padding-left: 5px;
    padding-right: 5px;
    min-width: 150px !important;
  }
#v-step-8 {
  max-width: 200px;
}

</style>
