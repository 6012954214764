<template>
  <v-card>
    <v-card-title>Install Template {{ template.displayName }}</v-card-title>
    <v-card-text>
      <v-stepper v-model="installStep">
        <v-stepper-header>
          <v-stepper-step
            :complete="installStep > 1"
            step="1"
          >
            Github Settings
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :complete="installStep > 2"
            step="2"
          >
            Variables
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3">
            Confirmation
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form v-model="step1Valid">
              <v-row>
                <v-col>
                  <span class="text-h6">Provide Github information on where this template will be installed</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <p>Select your Evolve Marketplace team</p>
                  <v-autocomplete
                    class="ml-4"
                    :loading="isLoadingTeams"
                    v-model="selectedTeam"
                    :items="teams"
                    label="Team"
                    item-text="displayName"
                    return-object
                    :search-input.sync="searchSync"
                    :rules="requiredRule"
                    hint="If your team is not listed in the drop-down, start typing the name to load more teams."
                    persistent-hint
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="selectedTeam">
                <v-col>
                  <p>Select a Github Organization that's linked to your team. If none are listed, click <a :href="`/github-app/${selectedTeam.id}/`" target="_blank">here</a> to create one and then click the refresh button below</p>
                  <v-select
                    class="ml-4"
                    :loading="isLoadingGithubLinks"
                    :items="githubLinks"
                    append-icon="mdi-refresh"
                    v-model="selectedLink"
                    item-text="orgName"
                    return-object
                    @click:append="getGithubLinks"
                    :rules="requiredRule"
                    label="Github Organization"
                  />
                </v-col>
              </v-row>
              <v-row v-if="selectedLink">
                <v-col>
                  <p>Select a repository for your github organization. This installation will create a new branch on the repository to install the action and required files into</p>
                  <v-autocomplete
                    class="ml-4"
                    append-icon="mdi-refresh"
                    :loading="isLoadingRepos"
                    :items="repos"
                    v-model="selectedRepoName"
                    item-text="name"
                    item-value="name"
                    label="Github Repository"
                    no-data-text="No repositories could be found for the selected organization."
                    @click:append="getRepositories"
                    :rules="requiredRule"
                  />
                </v-col>
              </v-row>
              <v-row v-if="selectedRepoName">
                <v-col>
                  <p>Select a branch. This installation will create a new branch based off this one and setup a pull request for you.</p>
                  <v-autocomplete
                    class="ml-4"
                    append-icon="mdi-refresh"
                    :loading="isLoadingBranches"
                    :items="branches"
                    v-model="selectedBranchName"
                    item-text="name"
                    item-value="name"
                    label="Repository Branch"
                    no-data-text="No branches could be found for the selected repository."
                    @click:append="getBranches"
                    :rules="requiredRule"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex justify-end">
                  <v-btn :disabled="!step1Valid" clas="ma-2" @click="installStep = 2" color="primary">
                    Continue
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-row>
              <v-col>
                <span class="text-h6">Fill out the required variables below</span>
              </v-col>
            </v-row>
            <v-row v-for="(item, index) in releaseDetails.release.settings.variables" :key="index">
              <v-col v-if="item.userDefined">
                <v-text-field
                  v-if="item.type === 0 || item.type === 1"
                  :label="item.displayName"
                  :hint="item.description"
                  persistent-hint
                  v-model="item.value"
                />
                <v-text-field
                  v-if="item.type === 2"
                  :label="item.displayName"
                  :hint="item.description"
                  persistent-hint
                  v-model="item.value"
                  type="password"
                />
                <v-switch
                  v-if="item.type === 3"
                  :label="item.displayName"
                  :hint="item.description"
                  persistent-hint
                  v-model="item.value"
                />
              </v-col>
              <v-col v-if="!releaseDetails.release.settings.variables">
                <span>No variables are required for this template, please click continue below</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-end">
                <v-btn
                  class="ma-2"
                  text
                  @click="installStep = 1"
                >
                  Back
                </v-btn>

                <v-btn
                  class="ma-2"
                  color="primary"
                  @click="installStep = 3"
                >
                  Continue
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-row>
              <v-col>
                <span class="text-h6">Verify the information below</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="text-subtitle2">Repository Details</span>
              </v-col>
            </v-row>
            <v-row v-if="selectedTeam && selectedLink">
              <v-col cols="12" md="6">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content class="font-weight-bold">Team:</v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ selectedTeam.displayName }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="font-weight-bold">Github Organization:</v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ selectedLink.orgName }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="font-weight-bold">Repository:</v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ selectedRepoName }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="font-weight-bold">Branch:</v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ selectedBranchName }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="text-subtitle2">Variables</span>
              </v-col>
            </v-row>
            <v-row v-if="selectedTeam && selectedLink">
              <v-col cols="12" md="6">
                <v-list dense>
                  <v-list-item v-for="(item, index) in releaseDetails.release.settings.variables" :key="'variable' + index">
                    <v-list-item-content class="font-weight-bold">{{ item.displayName }}:</v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ item.value }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-end">
                <v-btn
                  class="ma-2"
                  text
                  @click="installStep = 2"
                >
                  Back
                </v-btn>

                <v-btn
                  class="ma-2"
                  color="primary"
                  @click="installTemplate"
                  :disabled="isSubmitted"
                  :loading="isSubmitted"
                >
                  Install Template
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer></v-spacer>
        <v-btn @click="closeModal" :disabled="isSubmitted">Close</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios'

export default {
  name: 'github-action-install-component',
  watch: {
    searchSync: function () {
      clearTimeout(this.seachTimeout)

      // Make a new timeout set to go off in 500ms
      this.seachTimeout = setTimeout(() => {
        this.search = this.searchSync
        this.getTeams()
      }, 500)
    },
    selectedTeam: function () {
      this.getGithubLinks()
      this.selectedLink = null
      this.selectedRepoName = null
      this.selectedBranchName = null
    },
    selectedLink: function () {
      this.getRepositories()
      this.selectedRepoName = null
      this.selectedBranchName = null
    },
    selectedRepoName: function () {
      this.getBranches()
      this.selectedBranchName = null
    }
  },
  props: {
    template: {
      required: true
    },
    releaseDetails: {
      required: true
    }
  },
  data: () => ({
    step1Valid: false,
    step2Valid: false,
    searchSync: null,
    installStep: 1,
    teams: [ ],
    githubLinks: [ ],
    repos: [ ],
    branches: [ ],
    isLoadingTeams: false,
    isLoadingGithubLinks: false,
    isLoadingRepos: false,
    isLoadingBranches: false,
    selectedTeam: null,
    selectedLink: null,
    selectedRepoName: null,
    selectedBranchName: null,
    variables: [ ],
    page: 1,
    limit: 10,
    search: '',
    seachTimeout: null,
    requiredRule: [
      v => !!v || 'This field is required'
    ],
    isSubmitted: false
  }),
  methods: {
    getGithubLinks: function () {
      this.isLoadingGithubLinks = true
      axios(
        `${process.env.VUE_APP_BASEURL}/github/teams/${this.selectedTeam.id}/links`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.githubLinks = response.data
            this.isLoadingGithubLinks = false
          },
          (error) => {
            this.isLoadingGithubLinks = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getGithubLinks() })
            return error
          })
    },
    getRepositories: function () {
      this.isLoadingRepos = true
      axios(
        `${process.env.VUE_APP_BASEURL}/github/teams/${this.selectedTeam.id}/links/${this.selectedLink.id}/repos`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.repos = response.data
            this.isLoadingRepos = false
          },
          (error) => {
            this.isLoadingRepos = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getGithubLinks() })
            return error
          })
    },
    getBranches: function () {
      this.isLoadingBranches = true
      axios(
        `${process.env.VUE_APP_BASEURL}/github/teams/${this.selectedTeam.id}/links/${this.selectedLink.id}/repos/${this.selectedRepoName}/branches`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.branches = response.data
            this.isLoadingBranches = false
          },
          (error) => {
            this.isLoadingBranches = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getBranches() })
            return error
          })
    },
    getTeams: function () {
      this.isLoadingTeams = true
      axios(
        `${process.env.VUE_APP_BASEURL}/teams?showOwnedTeamsOnly=true&page=${this.page}&limit=${this.limit}&search=${this.search}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.teams = response.data.items
            this.isLoadingTeams = false
          },
          (error) => {
            this.isLoadingTeams = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getTeams() })
          })
    },
    installTemplate: function () {
      let installationRequest = {
        teamId: this.selectedTeam.id,
        templateReleaseId: this.releaseDetails.release.id,
        githubOrganizationLinkId: this.selectedLink.id,
        repositoryName: this.selectedRepoName,
        branchName: this.selectedBranchName,
        request: {
          templateType: 0,
          variables: this.releaseDetails.release.settings.variables
        }
      }
      this.isSubmitted = true
      axios(
        `${process.env.VUE_APP_TEMPLATEBASEURL}/api/templates/${this.template.id}/install`,
        {
          method: 'POST',
          data: JSON.stringify(installationRequest),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.$router.push(`/templates/${this.template.id}/installs/${response.data.id}`)
            this.isSubmitted = false
          },
          (error) => {
            this.isSubmitted = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.installTemplate() })
            return error
          })
    },
    closeModal: function () {
      this.$emit('closeInstallModal')
    }
  },
  beforeMount: function () {
    this.getTeams()
  }
}
</script>

<style scoped>

</style>
