<template>
  <v-container v-if="teams">
    <v-row>
      <v-col>
          <h2>Pending Promotions</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="pendingPromotionHeaders"
          :items="pendingPromotions"
          :items-per-page="15"
          v-if="!loadingRepos"
        >
          <template v-slot:item.teamId="{ item }">
            {{ teams.find(x => x.id == item.teamId) ? teams.find(x => x.id == item.teamId).displayName : '' }}
          </template>
          <template v-slot:item.requestDateTime="{ item }">
            <span>{{ getDate(item.requestDateTime) }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
               <v-btn v-bind="attrs" v-on="on" icon color="primary" :loading="currentlyApprovingPromotions.includes(item.id)" @click="approvePromotionConfirmation(item.id)" class="mr-2 action-button"><v-icon color="success">mdi-check</v-icon></v-btn>
              </template>
              <span>Approve</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon color="error" :loading="currentlyDenyingPromotions.includes(item.id)" @click="denyPromotionConfirmation(item.id)" class="mr-2 action-button"><v-icon color="error">mdi-close</v-icon></v-btn>
              </template>
              <span>Deny</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon color="info" @click="visitRepository(item.teamId, item.originalSourceRepositoryId)" class="action-button"><v-icon>mdi-call-made</v-icon></v-btn>
              </template>
              <span>Review</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
          <h2>Completed Promotions</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="approvedPromotionHeaders"
          :items="approvedPromotions"
          :items-per-page="15"
          v-if="!loadingRepos"
        >
          <template v-slot:item.teamId="{ item }">
            {{ teams.find(x => x.id == item.teamId) ? teams.find(x => x.id == item.teamId).displayName : '' }}
          </template>
          <template v-slot:item.completionDateTime="{ item }">
            <span>{{ getDate(item.completionDateTime) }}</span>
          </template>
          <template v-slot:item.status="{ item }">
            <span>{{ item.status === 0 ? 'Approved' : item.status === 2 ? 'Denied' : '' }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon color="info" @click="visitRepository(item.teamId, item.originalSourceRepositoryId)" class="action-button"><v-icon>mdi-call-made</v-icon></v-btn>
              </template>
              <span>Review</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="displayApprovePromotionDialog" width="500">
      <v-card>
        <v-card-title :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'" primary-title>Confirmation Required</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <h3>You are about to promote and artifact into the HCA innersource repository. Once an artifact is published, it cannot be unpublished. Are you sure you wish to proceed?</h3><br/>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="displayApprovePromotionDialog = false">Cancel</v-btn>
          <v-btn color="primary" @click="approvePromotion" text>Approve</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="displayDenyPromotionDialog" width="500">
      <v-card>
        <v-card-title :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'" primary-title>Confirmation Required</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <p>Are you sure you wish to deny this promotion request? This action cannot be undone and the user will need to request approval again</p>
                <v-text-field v-model="reason" label="Deny Reason" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="displayDenyPromotionDialog = false">Cancel</v-btn>
          <v-btn color="primary" text @click="denyPromotion">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'admin-repositories',
  computed: {
    ...mapGetters(['adminSettings'])
  },
  data: () => ({
    loadingRepos: false,
    pendingPromotionHeaders: [
      {
        text: 'Name',
        align: 'left',
        sortable: true,
        value: 'name'
      },
      {
        text: 'Version',
        align: 'left',
        sortable: true,
        value: 'version'
      },
      {
        text: 'Team',
        align: 'left',
        sortable: true,
        value: 'teamId'
      },
      {
        text: 'Request By',
        align: 'left',
        sortable: true,
        value: 'initiatedBy'
      },
      {
        text: 'Requested',
        align: 'left',
        sortable: true,
        value: 'requestDateTime'
      },
      {
        text: 'Actions',
        align: 'left',
        sortable: false,
        value: 'actions'
      }
    ],
    approvedPromotionHeaders: [
      {
        text: 'Name',
        align: 'left',
        sortable: true,
        value: 'name'
      },
      {
        text: 'Version',
        align: 'left',
        sortable: true,
        value: 'version'
      },
      {
        text: 'Team',
        align: 'left',
        sortable: true,
        value: 'teamId'
      },
      {
        text: 'Completed By',
        align: 'left',
        sortable: true,
        value: 'completedBy'
      },
      {
        text: 'Completed',
        align: 'left',
        sortable: true,
        value: 'completionDateTime'
      },
      {
        text: 'Status',
        align: 'left',
        sortable: true,
        value: 'status'
      },
      {
        text: 'Reason',
        align: 'left',
        sortable: true,
        value: 'reason'
      },
      {
        text: 'Actions',
        align: 'left',
        sortable: false,
        value: 'actions'
      }
    ],
    pendingPromotions: [ ],
    approvedPromotions: [ ],
    loadingPendingPromotions: false,
    loadingApprovedPromotions: false,
    currentlyApprovingPromotions: [ ],
    currentlyDenyingPromotions: [ ],
    displayApprovePromotionDialog: false,
    displayDenyPromotionDialog: false,
    currentPromotionId: null,
    reason: '',
    teams: [ ]
  }),
  methods: {
    getDate: function (date) {
      return moment(date).fromNow()
    },
    getUserDetails: async function (username) {
      return axios(
        `${process.env.VUE_APP_BASENETWORKAPIURL}/activedirectory/search/user?searchText=${username}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getUserDetails() })
          })
    },
    getAllTeams: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/teams`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.teams = response.data.items
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getAllTeams() })
          })
    },
    getPendingPromotions: function () {
      this.loadingPendingPromotions = true
      return axios(
        `${process.env.VUE_APP_REPO_BASEURL}/repositories/artifacts/promote/pending`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.pendingPromotions = response.data
            this.pendingPromotions.forEach(x => {
              this.getUserDetails(x.initiatedBy).then((y) => {
                if (y && y[0] && typeof y[0] === 'object' && Object.prototype.hasOwnProperty(y[0], 'Value')) {
                  x.initiatedBy = y[0].Value
                }
              })
            })
            this.loadingPendingPromotions = false
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getPublicRepos() })
          })
    },
    getApprovedPromotions: function () {
      this.loadingApprovedPromotions = true
      return axios(
        `${process.env.VUE_APP_REPO_BASEURL}/repositories/artifacts/promote/approved`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.approvedPromotions = response.data
            this.approvedPromotions.forEach(x => {
              this.getUserDetails(x.initiatedBy).then((y) => {
                if (y && y[0] && typeof y[0] === 'object' && Object.prototype.hasOwnProperty(y[0], 'Value')) {
                  x.initiatedBy = y[0].Value
                }
              })
            })
            this.approvedPromotions.forEach(x => {
              this.getUserDetails(x.completedBy).then((y) => {
                if (y && y[0] && typeof y[0] === 'object' && Object.prototype.hasOwnProperty(y[0], 'Value')) {
                  x.completedBy = y[0].Value
                }
              })
            })
            this.loadingApprovedPromotions = false
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getPublicRepos() })
          })
    },
    visitRepository: function (teamId, repositoryId) {
      this.$router.push(`/teams/${teamId}/repositories/${repositoryId}`)
    },
    approvePromotionConfirmation: function (id) {
      this.reason = null
      this.currentPromotionId = id
      this.displayApprovePromotionDialog = true
    },
    denyPromotionConfirmation: function (id) {
      this.reason = null
      this.currentPromotionId = id
      this.displayDenyPromotionDialog = true
    },
    approvePromotion: function () {
      this.displayApprovePromotionDialog = false
      this.currentlyApprovingPromotions.push(this.currentPromotionId)
      return axios(
        `${process.env.VUE_APP_REPO_BASEURL}/repositories/artifacts/${this.currentPromotionId}/promote?actions=approve`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.currentlyApprovingPromotions = this.currentlyApprovingPromotions.filter(x => {
              return x !== this.currentPromotionId
            })
            this.getPendingPromotions()
            this.getApprovedPromotions()
            this.currentPromotionId = null
            this.reason = null
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.approvePromotion() })
            this.currentlyApprovingPromotions = this.currentlyApprovingPromotions.filter(x => {
              return x !== this.currentPromotionId
            })
          })
    },
    denyPromotion: function () {
      this.displayDenyPromotionDialog = false
      this.currentlyDenyingPromotions.push(this.currentPromotionId)
      return axios(
        `${process.env.VUE_APP_REPO_BASEURL}/repositories/artifacts/${this.currentPromotionId}/promote?actions=deny&reason=${this.reason}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.currentlyDenyingPromotions = this.currentlyDenyingPromotions.filter(x => {
              return x !== this.currentPromotionId
            })
            this.getPendingPromotions()
            this.getApprovedPromotions()
            this.currentPromotionId = null
            this.reason = null
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.denyPromotion() })
            this.currentlyApprovingPromotions = this.currentlyApprovingPromotions.filter(x => {
              return x !== this.currentPromotionId
            })
          })
    }
  },
  mounted: function () {
    if (this.adminSettings.isRepositoryAdmin) {
      this.getAllTeams()
      this.getPendingPromotions()
      this.getApprovedPromotions()
    }
  }
}
</script>

<style scoped>

</style>
