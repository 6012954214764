<template>
  <v-container fluid>
    <span v-if="oidcStore && oidcStore.access_token">{{ oidcStore.access_token }}</span>
    <p>
     <v-btn v-clipboard:copy="oidcStore.access_token" v-clipboard:success="copyMouseClick">
        Copy Token
      </v-btn>
    </p>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Return Token',
  computed: {
    ...mapState(['oidcStore'])
  },
  methods: {
    copyMouseClick () {
      this.$store.commit('triggerSnack', { snackMessage: 'Url copied to clipboard', snackColor: 'success' })
    }
  }
}
</script>

<style scoped>

</style>
