<template>
  <v-container>
    <div v-for="item in items" :key="item.name">
      <v-row>
        <v-col>
          <h2>{{ item.name }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" v-for="lab in item.labs" :key="lab.name">
          <v-card
            class="ma-2"
            max-width="344"
            hover
            :href="lab.link"
            target="_blank"
          >
            <v-img
              :aspect-ratio="16/9"
              :src="lab.photo"
              height="160px"
            ></v-img>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>

export default {
  name: 'LearningCenter',
  data: () => ({
    items: [
      {
        name: 'General',
        labs: [
          {
            name: 'Learning the Language of Api Management',
            link: 'https://confluence.app.medcity.net/c/display/EAM1/Lab+-+Learning+the+Language+of+Api+Management',
            photo: require('../../assets/labs/learning-the-language.png')
          },
          {
            name: 'Access the Evolve Marketplace',
            link: 'https://confluence.app.medcity.net/c/display/EAM1/Lab+-+Access+the+Evolve+Marketplace',
            photo: require('../../assets/labs/access-the-marketplace.png')
          }
        ]
      },
      {
        name: 'API Creator Track',
        labs: [
          {
            name: 'Create a Team',
            link: 'https://confluence.app.medcity.net/c/display/EAM1/Lab+%28API+Creator%29+-+Create+a+Team',
            photo: require('../../assets/labs/create-a-team.png')
          },
          {
            name: 'Create a Product',
            link: 'https://confluence.app.medcity.net/c/display/EAM1/Lab+%28API+Creator%29+-+Create+a+Product',
            photo: require('../../assets/labs/create-a-product.png')
          },
          {
            name: 'Build an API using Swagger Petstore',
            link: 'https://confluence.app.medcity.net/c/display/EAM1/Lab+%28API+Creator%29+-+Build+an+API+using+Swagger+Petstore',
            photo: require('../../assets/labs/build-api-with-swagger.png')
          },
          {
            name: 'Build an API without Swagger',
            link: 'https://confluence.app.medcity.net/c/display/EAM1/Lab+%28API+Creator%29+-+Build+an+API+without+Swagger',
            photo: require('../../assets/labs/build-api-without-swagger.png')
          },
          {
            name: 'Deploying and Un-deploying an API',
            link: 'https://confluence.app.medcity.net/c/display/EAM1/Lab+%28API+Creator%29+-+Deploying+and+Un-deploying+an+API',
            photo: require('../../assets/labs/deploying-and-undeploying.png')
          },
          {
            name: 'Building a Build & Release Pipeline for an API in Azure DevOps',
            link: 'https://confluence.app.medcity.net/c/pages/viewpage.action?pageId=143200985',
            photo: require('../../assets/labs/build-and-release-pipelines.png')
          },
          {
            name: 'Testing an API in the Browser',
            link: 'https://confluence.app.medcity.net/c/display/EAM1/Lab+%28API+Creator%29+-+Testing+an+API+in+the+Browser',
            photo: require('../../assets/labs/testing-in-browser.png')
          },
          {
            name: 'Adding prototype methods to an API',
            link: 'https://confluence.app.medcity.net/c/display/EAM1/Lab+%28API+Creator%29+-+Adding+prototype+methods+to+an+API',
            photo: require('../../assets/labs/adding-prototype-methods.png')
          },
          {
            name: 'Subscribe to an API, View Subscriptions, Approve & Reject requests, and Revoke an active subscription',
            link: 'https://confluence.app.medcity.net/c/pages/viewpage.action?pageId=143217629',
            photo: require('../../assets/labs/subscribe-and-approve-subscriptions.png')
          },
          {
            name: 'Creating API HealthChecks',
            link: 'https://confluence.app.medcity.net/c/display/EAM1/Lab+%28API+Creator%29+-+Creating+API+HealthChecks',
            photo: require('../../assets/labs/creating-api-healthchecks.png')
          }
        ]
      },
      {
        name: 'Api Consumer Track',
        labs: [
          {
            name: 'Calling an API from the browser using the try-it-out wizard',
            link: 'https://confluence.app.medcity.net/c/display/EAM1/Lab+%28API+Consumer%29+-+Calling+an+API+from+the+browser+using+the+try-it-out+wizard',
            photo: require('../../assets/labs/calling-an-api-consumer.png')
          },
          {
            name: 'Create a Product',
            link: 'https://confluence.app.medcity.net/c/display/EAM1/Lab+%28API+Consumer%29+Create+a+Product',
            photo: require('../../assets/labs/create-a-product-consumer.png')
          },
          {
            name: 'Download Postman Collection and call API',
            link: 'https://confluence.app.medcity.net/c/display/EAM1/Lab+%28API+Consumer%29+-+Download+Postman+Collection+and+call+API',
            photo: require('../../assets/labs/download-postman-consumer.png')
          },
          {
            name: 'Search and Subscribe and Unsubscribe to an API',
            link: 'https://confluence.app.medcity.net/c/display/EAM1/Lab+%28API+Consumer%29+-+Search+and+Subscribe+and+Unsubscribe+to+an+API',
            photo: require('../../assets/labs/search-and-subscribe-consumer.png')
          }
        ]
      }
    ]
  }),
  mounted: function () {
    this.$store.commit('breadcrumbs', [
      {
        text: 'Learning Center',
        exact: true,
        disabled: false,
        to: '/learning-center'
      }
    ])
  }
}
</script>
<style scoped>

</style>
