<template>
  <v-container>
    <v-row justify="center">
        <v-col cols="12" md="12" id="v-step-0">
            <h1 class="text-center">Create a Team</h1>
             <p class="text-center">Follow the form below to build a team</p>
        </v-col>
      </v-row>
    <v-divider/>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-card
          :loading="isSubmitted"
        >
          <template v-slot:progress>
            <v-progress-linear
              absolute
              color="green lighten-3"
              height="4"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col id="v-step-1">
                  <v-text-field class="required" @blur="overwritePath = false" label="Display Name" hint="The name of your team." :rules="nameRules" required :disabled="isSubmitted" v-model="team.displayName"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col id="v-step-3">
                  <v-textarea class="required" label="Description" hint="The description of your team that end users will see." :rules="descriptionRules" required :disabled="isSubmitted" v-model="team.description"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-model="team.affiliates"
                    :items="affiliates"
                    item-text="displayName"
                    item-value="id"
                    attach
                    chips
                    label="Affiliates (Optional)"
                    multiple
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" id="v-step-4">
                  <user-search-component class="required" id="v-step-5" :disabled="isSubmitted" v-model="currentUserSearch" label="Team Members" />
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">34</th>
                          <th class="text-left">Name</th>
                          <th class="text-left">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="user in members" :key="user.Key">
                          <td>{{ user.Key }}</td>
                          <td>{{ user.Value }}</td>
                          <v-btn icon @click="deleteUser(user.Key)"><v-icon>mdi-delete</v-icon></v-btn>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" id="v-step-4">
                  <group-search-component class="required" id="v-step-5" :disabled="isSubmitted" v-model="currentGroupSearch" label="Admin AD Groups" />
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Group</th>
                          <th class="text-left">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="group in allowedGroups" :key="group">
                          <td>{{ group }}</td>
                          <v-btn icon @click="deleteGroup(group)"><v-icon>mdi-delete</v-icon></v-btn>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
              <v-row>
                <v-col id="v-step-6">
                  <v-btn style="color: white !important;" color="#E05929" :disabled="isSubmitted" @click="create">Create</v-btn>
                </v-col>
              </v-row>
              <v-row v-if="!validated || userError">
                <v-col class="text-center" >
                  <span style="color: #ff5252 !important">There are errors with your request. Please check the form above and try again</span>
                </v-col>
              </v-row>
              <v-row v-if="!validated || userError">
                <v-col class="text-center">
                  <span v-if="userError" style="color: #ff5252 !important">At least one user or group is required</span>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import userSearchComponent from '@/components/userSearchComponent'
import groupSearchComponent from '@/components/groupSearchComponent.vue'

export default {
  name: 'TeamBuilder',
  components: {
    'user-search-component': userSearchComponent,
    'group-search-component': groupSearchComponent
  },
  watch: {
    currentUserSearch: function (newValue, oldValue) {
      if (newValue) {
        if (!this.members.find(x => x.Key === newValue.Key)) {
          this.members.push(newValue)
        }
      }
    },
    currentGroupSearch: function (newValue, oldValue) {
      if (newValue) {
        if (!this.allowedGroups.find(x => x === newValue)) {
          if (newValue.toLowerCase().startsWith('domain')) {
            this.$store.commit('showErrorDialog',
              {
                errorData: {
                  response: {
                    data: {
                      errorMessage: 'You cannot use a Domain Group for this list. Please select a more granular group'
                    }
                  }
                },
                persistErrorDialog: false
              })
          } else {
            this.allowedGroups.push(newValue)
          }
        }
      }
    }
  },
  data: () => ({
    overwritePath: true,
    isSubmitted: false,
    currentUserSearch: null,
    currentGroupSearch: null,
    members: [ ],
    allowedGroups: [ ],
    allowedUsers: [ ],
    userError: false,
    affiliates: [ ],
    validated: true,
    team: {
      displayName: '',
      description: null,
      members: [ ],
      affiliates: [ ]
    },
    nameRules: [
      v => !!v || 'A Name is required',
      v => (v && v.length < 100) || 'A Name must be less than 100 characters'
    ],
    descriptionRules: [
      v => !!v || 'A Description is required',
      v => (v && v.length < 500) || 'The Description must be less than 500 characters'
    ]
  }),
  computed: {
    ...mapGetters([
      'oidcUser'
    ])
  },
  methods: {
    getAffiliates: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/affiliates/`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.affiliates = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getAffiliates() })
            return error
          })
    },
    validate: function () {
      this.validated = true
      this.userError = false
      if (this.members.length <= 0 && this.allowedGroups.length <= 0) {
        this.userError = true
        this.validated = false
      }

      if (!this.$refs.form.validate()) {
        this.validated = false
      }
      return this.validated
    },
    deleteUser: function (username) {
      this.members = this.members.filter((item) => {
        return item.Key !== username
      })
    },
    deleteGroup: function (group) {
      this.allowedGroups = this.allowedGroups.filter((item) => {
        return item !== group
      })
    },
    create: function () {
      this.isSubmitted = true

      if (!this.validate()) {
        this.isSubmitted = false
        return false
      }

      if (this.members) {
        this.team.members = [ ]
        this.members.forEach(x => {
          this.team.members.push(x.Key)
        })
      }

      if (this.allowedGroups) {
        this.team.adGroups = this.allowedGroups
      }

      axios(
        `${process.env.VUE_APP_BASEURL}/teams/`,
        {
          method: 'POST',
          data: JSON.stringify(this.team),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isSubmitted = false
            this.$router.push(`/teams/${response.data.id}/settings`)
          },
          (error) => {
            this.isSubmitted = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.create() })
            return error
          })
    }
  },
  mounted: function () {
    this.getAffiliates()
    this.members.push({
      Key: this.oidcUser.sub,
      Value: this.oidcUser.displayName
    })
  }
}
</script>
<style scoped>
  .user-error {
    font-size: 12px;
  }

  .required label::after {
    content: "*";
    color: 'red'
}
</style>
