<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h5">Badge Users:</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="createAdminUser" color="info">Add Admin User</v-btn>
      </v-col>
    </v-row>
    <v-row id="v-step-5">
      <v-col cols="12">
        <v-data-table
          :headers="adminUserHeaders"
          :items="adminUsers"
          :items-per-page="15"
          class="elevation-1"
          v-if="!loadingUsers"
        >
          <template v-slot:item.name="{ item }">
            <span>{{ item.firstName + ' ' + item.lastName }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn icon @click="deleteAdminUserDialog(item)"><v-icon>mdi-delete</v-icon></v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="displayAdminDialog" width="1500">
      <v-card>
        <v-card-title :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'" primary-title>Add/Update Admin User</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col v-if="!isEditAdmin">
                <user-search-component class="required" v-model="currentAdminUserSearch" :no-clear="true" label="Admin User" />
              </v-col>
              <v-col v-else>
                <v-text-field disabled v-model="currentAdminUser.name" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="displayAdminDialog = false">Cancel</v-btn>
          <v-btn color="primary" :loading="savingAdminUser" :disabled="savingAdminUser" text @click="createOrUpdateAdminUser">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import userSearchComponent from '@/components/userSearchComponent'
import axios from 'axios'

export default {
  name: 'badge-users',
  components: {
    'user-search-component': userSearchComponent
  },
  watch: {
    currentAdminUserSearch: function (newValue, oldValue) {
      if (newValue) {
        this.currentAdminUser.userId = newValue.Key
      }
    }
  },
  data: () => ({
    displayAdminDialog: false,
    loadingUsers: false,
    affiliates: [ ],
    adminUsers: [ ],
    currentAdminUserSearch: null,
    currentAdminUser: {
      userId: '',
      firstName: '',
      lastName: '',
      email: ''
    },
    isEditAdmin: false,
    savingAdminUser: false,
    adminUserHeaders: [
      {
        text: 'Name',
        align: 'left',
        sortable: true,
        value: 'name'
      },
      {
        text: 'Actions',
        align: 'left',
        sortable: true,
        value: 'actions'
      }
    ],
    userTab: 0,
    page: 0,
    limit: 10,
    search: ''
  }),
  methods: {
    createAdminUser: function () {
      this.currentAdminUser = {
        userId: '',
        firstName: '',
        lastName: '',
        email: ''
      }
      this.isEditAdmin = false
      this.displayAdminDialog = true
    },
    createOrUpdateAdminUser: function () {
      this.savingAdminUser = true

      this.getUserDetails(this.currentAdminUser.userId).then((user) => {
        let newUser = {
          userId: this.currentAdminUser.userId,
          firstName: user.FirstName,
          lastName: user.LastName,
          email: user.Email,
          roles: ['SystemAdmin']
        }
        axios(
          `${process.env.VUE_APP_BADGEBASEAPIURL}/badgeusers`,
          {
            method: 'POST',
            data: JSON.stringify(newUser),
            headers: { 'Content-Type': 'application/json' }
          })
          .then(
            (response) => {
              this.savingAdminUser = false
              this.getAdminUsers()
              this.displayAdminDialog = false
            },
            (error) => {
              this.savingAdminUser = false
              this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.createOrUpdateAdminUser() })
              return error
            })
      })
    },
    getAdminUsers: async function () {
      this.adminUsers = [ ]
      return axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/badgeusers/all`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.adminUsers = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getAdminUsers() })
          })
    },
    deleteAdminUserDialog: function (item) {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to delete this user. This action can not be undone',
        confirmMethod: () => {
          this.deleteAdminUser(item)
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    deleteAdminUser: function (item) {
      axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/badgeusers/${item.userId}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.getAdminUsers()
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.deletePolicy() })
            return error
          })
    },
    getUserDetails: function (username) {
      return axios(
        `${process.env.VUE_APP_BASENETWORKAPIURL}/activedirectory/user/${username}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getUserDetails() })
          })
    }
  },
  mounted: function () {
    this.getAdminUsers()
  }
}
</script>

<style scoped>

</style>
