<template>
  <v-container>
    <v-row>
      <v-col>
        <v-alert
          dense
          outlined
          type="info"
        >
          Settings updated on this tab will only take affect on this version
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span class="text-h5">Basic Template Settings</span>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center">
      <v-col cols="12" md="6">
        <v-select :items="releases" item-text="release.releaseId" item-value="release.releaseId" v-model="selectedImportReleaseName" label="Import settings for existing release" />
      </v-col>
      <v-col>
        <v-btn @click="copyFromExistingRelease">Import Settings</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-switch v-model="releaseDetails.release.isPublished" label="Publish This Release" />
      </v-col>
    </v-row>
    <git-ignore-component :releaseDetails="releaseDetails" :triggerRefresh="triggerIgnoreRefresh" :template="template" />
    <v-row>
      <v-col>
        <span class="text-h5">Github Action Template Settings</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="addVariable">Add Variable</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="variableHeaders"
          :items="variables"
        >
        <template v-slot:item.actions="{ item }">
              <v-btn icon @click="editVariable(item)">
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn icon @click="deleteVariable(item)">
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="variableDialog"
      width="1000"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Set/Update Variable
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="variableForm">
              <v-row>
                <v-col>
                  <v-text-field :rules="required" v-model="currentVariable.name" label="Variable Name" hint="This is the name that the system will search for in the format ##VariableName## in the repo files" persistent-hint />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field :rules="required" v-model="currentVariable.displayName" label="Display Name" hint="The name users will see in the evolve marketplace" persistent-hint />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field :rules="required" v-model="currentVariable.description" label="Description" hint="The help text for the variable" persistent-hint />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select :rules="selectRequired" :items="githubActionVariableTypes" v-model="currentVariable.type" label="Variable Type" hint="The type of this variable" persistent-hint item-text="itemText" item-value="itemValue" />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field v-if="currentVariable.type === 0 || currentVariable.type === 1 || currentVariable.type === 2" v-model="currentVariable.value" label="Value" hint="The default value if user defined is selected or a hidden value for this setting a user can't change if not user defined" persistent-hint />
                  <v-switch v-if="currentVariable.type === 3" v-model="currentVariable.value" label="Value" hint="The default value if user defined is selected or a hidden value for this setting a user can't change if not user defined" persistent-hint />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-switch v-model="currentVariable.userDefined" label="Allow User Defined" hint="If selected, the user can set this value. Otherwise the variable value will be hidden from the user and set using the Value above" persistent-hint />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-switch v-model="currentVariable.setAsGithubSecret" label="Set as Github Secret" hint="If selected, this variable will be set as a github secret" persistent-hint />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="variableDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="updateVariable"
          >
            Update Variable
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import gitIgnoreComponent from './gitIgnoreComponent'

export default {
  name: 'github-action-settings-component',
  props: {
    template: {
      required: true
    },
    releaseDetails: {
      required: true
    },
    releases: {
      required: true
    }
  },
  components: {
    'git-ignore-component': gitIgnoreComponent
  },
  watch: {
    variables: {
      deep: true,
      handler: function () {
        if (this.releaseDetails.release.settings.variables !== this.variables) {
          this.releaseDetails.release.settings.variables = this.variables
        }
      }
    }
  },
  data: () => ({
    triggerIgnoreRefresh: 0,
    selectedImportReleaseName: null,
    variableDialog: false,
    currentVariable: { },
    currentVariableIndex: -1,
    variables: [ ],
    validated: true,
    githubActionVariableTypes: [
      {
        itemText: 'String',
        itemValue: 0
      },
      {
        itemText: 'Number',
        itemValue: 1
      },
      {
        itemText: 'Password',
        itemValue: 2
      },
      {
        itemText: 'Boolean',
        itemValue: 3
      }
    ],
    variableHeaders: [
      {
        text: 'Name',
        align: 'left',
        sortable: false,
        value: 'name'
      },
      {
        text: 'Description',
        align: 'left',
        sortable: false,
        value: 'description'
      },
      {
        text: 'Actions',
        align: 'left',
        sortable: false,
        value: 'actions'
      }
    ],
    required: [
      v => !!v || 'This field is required'
    ],
    selectRequired: [
      v => v != null || 'This field is required'
    ]
  }),
  methods: {
    copyFromExistingRelease: function () {
      let releaseFound = this.releases.find(x => x.release.releaseId === this.selectedImportReleaseName)
      if (releaseFound) {
        let settings = JSON.parse(JSON.stringify(releaseFound.release.settings))
        this.releaseDetails.release.settings = settings
        this.variables = this.releaseDetails.release.settings.variables
        this.triggerIgnoreRefresh++
      } else {
        this.releaseDetails.release.settings = {
          templateType: 0,
          variables: [ ],
          ignoreFiles: [ ]
        }
        this.variables = this.releaseDetails.release.settings.variables
        this.triggerIgnoreRefresh++
      }
    },
    addVariable: function () {
      this.currentVariable = { }
      this.variableDialog = true
    },
    editVariable: function (item) {
      this.currentVariableIndex = this.variables.indexOf(item)
      this.currentVariable = this.variables[this.currentVariableIndex]
      this.variableDialog = true
    },
    deleteVariable: function (item) {
      let index = this.variables.indexOf(item)
      this.variables.splice(index, 1)
    },
    updateVariable: function () {
      if (!this.validate()) {
        return false
      }

      if (this.currentVariableIndex !== -1) {
        this.variables[this.currentVariableIndex] = this.currentVariable
      } else {
        this.variables.push(this.currentVariable)
      }

      this.currentVariable = {}
      this.currentVariableIndex = -1
      this.variableDialog = false
    },
    validate: function () {
      this.validated = true
      if (!this.$refs.variableForm.validate()) {
        this.validated = false
      }
      return this.validated
    }
  },
  beforeMount: function () {
    if (!this.releaseDetails.release.settings) {
      this.releaseDetails.release.settings = {
        templateType: 0,
        variables: [ ],
        ignoreFiles: [ ]
      }
    }
    this.variables = this.releaseDetails.release.settings.variables
  }
}
</script>

<style scoped>

</style>
