<template>
  <v-container>
    <v-stepper
      v-model="stepper"
      vertical
    >
      <v-stepper-step
        editable
        :complete="stepper > 1"
        step="1"
      >
        Authorize the Marketplace
        <small>Authorize Marketplace to act on your behalf</small>
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card
        >
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <span>Click the authorize button below to allow the Marketplace to read your Github user information.</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span>Note: You must be an organization admin to link Evolve Marketplace to your organization. Ensure when you authorize that you select any organization that you're an admin for.</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn @click="authorize">Authorize Github</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        </v-card>
      </v-stepper-content>

      <v-stepper-step
        editable
        :complete="stepper > 2"
        step="2"
      >
        Install the Application
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-card
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <span>Select your organization from the list below.</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span>If you do not see your organization either the oAuth2 scope was set incorrectly or you are not an administrator. Click on the step above to re-authenticate and try again.</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <span><v-select :loading="isLoadingList" :items="organizationAdmins" v-model="selectedOrg" item-text="orgName" return-object /></span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn :disabled="isLoadingList || !selectedOrg" @click="installGithubApp">Install</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-stepper-content>

      <v-stepper-step
        :complete="stepper > 3"
        step="3"
      >
        Finalize Installation
      </v-stepper-step>
       <v-stepper-content step="3">
        <v-card
        >
          <v-card-text>
           <v-container>
              <div v-if="!isInstallationFinalized && !isRequested">
                <v-row>
                  <v-col>
                    <span><v-progress-circular
                      indeterminate
                      color="primary"
                      size="10"
                      width="1"
                    ></v-progress-circular> Please wait while the link is finalized. DO NOT REFRESH YOUR PAGE.</span>
                  </v-col>
                </v-row>

              </div>
              <div v-if="isInstallationFinalized">
                <v-row>
                  <v-col>
                    <span>Github Organization Linked</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span><v-progress-circular
                      value="100"
                      color="green"
                      size="10"
                      width="1"
                    ></v-progress-circular> Your github organization has been linked successfully. You will be redirected back to your team momentarily</span>
                  </v-col>
                </v-row>
              </div>
              <div v-if="isRequested">
                <v-row>
                  <v-col>
                    <span>You are not an admin on the Github organization that you requested an installation on. This link will not be created. Please go back to step 1 and repeat the process, delete your request on the github installation page, and submit a request on an organization you are an admin on.</span>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-card-text>
        </v-card>
      </v-stepper-content>
    </v-stepper>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'github-callback-receiver',
  data: () => ({
    teamId: null,
    installationId: null,
    isLoadingList: false,
    isInstalled: false,
    isInstallationFinalized: false,
    isRequested: false,
    organizationAdmins: [],
    selectedOrg: null,
    code: null,
    stepper: 1
  }),
  methods: {
    installGithubApp: function () {
      if (this.selectedOrg.isInstalled) {
        this.installationId = this.selectedOrg.installationId
        this.finalizeRegistration()
        this.stepper = 3
      } else {
        window.location.href = `https://github.com/apps/${process.env.VUE_APP_GITHUBAPPNAME}/installations/new/permissions?target_id=${this.selectedOrg.targetId}&state=${this.teamId}`
      }
    },
    authorize: function () {
      window.location.href = `https://github.com/login/oauth/authorize?redirect_uri=${process.env.VUE_APP_GITHUBREDIRECTURL}/&client_id=${process.env.VUE_APP_GITHUBOAUTHID}&state=${this.teamId}&scope=read:org`
    },
    getOrgAdminList: function () {
      this.isLoadingList = true
      return axios(
        `${process.env.VUE_APP_BASEURL}/github/orgs?code=${this.code}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isLoadingList = false
            this.organizationAdmins = response.data
            return response
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getAccessToken() })
            this.generatingToken = false
          })
    },
    finalizeRegistration: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/github/finalize?teamId=${this.teamId}&installationId=${this.installationId}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isInstallationFinalized = true
            setTimeout(() => { this.$router.push(`/teams/${this.teamId}`) }, 5000)
            return response
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.finalizeRegistration() })
            this.generatingToken = false
          })
    }
  },
  beforeMount: function () {
    if (this.$route.params.teamId) {
      this.teamId = this.$route.params.teamId
      this.stepper = 1
    } else if (this.$router.currentRoute.query && this.$router.currentRoute.query.code && this.$router.currentRoute.query.state && !this.$router.currentRoute.query.setup_action) {
      this.teamId = this.$router.currentRoute.query.state
      this.code = this.$router.currentRoute.query.code
      this.getOrgAdminList()
      this.stepper = 2
    } else if (this.$router.currentRoute.query && this.$router.currentRoute.query.state && this.$router.currentRoute.query.setup_action) {
      this.teamId = this.$router.currentRoute.query.state

      if (this.$router.currentRoute.query.setup_action === 'requested') {
        this.isRequested = true
      } else if (this.$router.currentRoute.query.setup_action === 'install') {
        this.isInstalled = true
        this.installationId = this.$router.currentRoute.query.installation_id
        this.finalizeRegistration()
      }
      this.stepper = 3
    } else {
    }
  }
}
</script>

<style scoped>

</style>
