<template>
  <v-container>
      <v-form ref="editDepartmentForm">
      <v-row justify="center">
        <v-col cols="12" sm="auto">
          <div>
            <transition name="slide-y-transition" mode="out-in">
              <span v-if="department && !edittingDepartment" class="ma-2 text-h5" key="edit-display-off">{{department.name}}</span>
              <v-text-field
                v-if="department && edittingDepartment"
                key="edit-displayName"
                solo
                style="width: 600px"
                class="ml-2"
                v-model="department.name"
                label="Name"
                hint="The college department name."
                :rules="departmentNameRules"
                persistent-hint
              />
            </transition>
          </div>
          <transition name="slide-y-transition" mode="out-in">
            <p v-if="!edittingDepartment" class="ma-2" key="edit-description" style="width: 600px;">{{department.description}}</p>
            <v-textarea
              v-else
              class="ml-3"
              key="edit-description-enabled"
              style="height: 500px; width: 600px; !important"
              solo
              hint="The description for your department."
              v-model="department.description"
              label="Description"
              :rules="descriptionRules"
              required
              persistent-hint
            />
          </transition>
        </v-col>
        <v-spacer />
        <v-col cols="12" sm="auto">
          <transition name="slide-y-transition" mode="out-in">
            <div v-if="!edittingDepartment && !edittingCollege" key="edit-mode-buttons-disabled">
              <v-btn text color="primary" @click="edittingDepartment=true" v-if="hasAdminRole">
                <v-icon left>mdi-pencil</v-icon>Edit Department
              </v-btn>
              <v-btn text color="primary" @click="createNewCourse()" v-if="hasAdminRole">
                <v-icon left>mdi-plus</v-icon>Create a Course
              </v-btn>
            </div>
            <div v-if="edittingDepartment" key="edit-mode-buttons-enabled">
              <v-btn class="ma-1 pa-1" width="200px" :loading="isSubmitted" :disabled="isSubmitted" @click="updateDepartment"><v-icon left>mdi-check</v-icon>Save Department</v-btn>
              <br/>
              <v-btn class="ma-1 pa-1" width="200px" color="error" :loading="isDeleting" :disabled="isDeleting" @click="displayDeleteDialog"><v-icon left>mdi-delete</v-icon>Delete Department</v-btn>
              <br/>
              <v-btn class="ma-1 pa-1" width="200px" @click="cancelUpdate()"><v-icon left>mdi-close</v-icon>Cancel Update</v-btn>
            </div>
          </transition>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-if="courses">
          <v-row v-if="courses.length === 0 && isLoading">
            <v-col cols="12" md="4">
              <v-skeleton-loader
                class="pa-2"
                type="list-item-three-line"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="4">
              <v-skeleton-loader
                class="pa-2"
                type="list-item-three-line"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="4">
              <v-skeleton-loader
                class="pa-2"
                type="list-item-three-line"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="4">
              <v-skeleton-loader
                class="pa-2"
                type="list-item-three-line"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="4">
              <v-skeleton-loader
                class="pa-2"
                type="list-item-three-line"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="4">
              <v-skeleton-loader
                class="pa-2"
                type="list-item-three-line"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        <v-row align="stretch" v-else>
          <v-col cols="12" md="4" v-for="(course, index) in courses" :key="index">
            <v-card
              height="480px"
              :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
            >
            <v-app-bar
              absolute
              flat
              color="rgba(0, 0, 0, 0)"
            >
            <v-spacer></v-spacer>
              <v-btn x-small class="mr-1" v-if="course.id && hasAdminRole" color="primary" icon @click="openCourseBuilder(course)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn x-small v-if="course.id && hasAdminRole" color="primary" icon :disabled="isDeletingCourse" @click="displayDeleteCourseDialog(course.id)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-app-bar>
              <v-row class="d-flex justify-center pa-0 ma-0">
                <v-col cols="12" md="6">
                  <div>
                    <v-img contain v-if="course.type == 0" src="@/assets/university/pluralsight.png" width="300px" height="150px"></v-img>
                    <v-img contain v-if="course.type == 1" src="@/assets/university/cloudacademy.png" width="300px" height="150px"></v-img>
                    <v-img contain v-if="course.type == 2" src="@/assets/university/microsoft.png" width="300px" height="150px"></v-img>
                    <v-img contain v-if="course.type == 3" src="@/assets/university/udemy.png" width="300px" height="150px"></v-img>
                    <v-img contain v-if="course.type == 4" src="@/assets/university/healthstream.png" width="300px" height="150px"></v-img>
                  </div>
                </v-col>
              </v-row>
              <v-card-title class="justify-center text-center pt-0" style="height: 85px;">{{ course.name }}</v-card-title>
              <v-card-text style="overflow-y: hidden; height: 85px;" class="text-center">
                {{ course.description }}
              </v-card-text>
              <v-card-actions class="text-center justify-center">
                <v-row>
                  <v-col cols="12" class="pa-0 ma-0">
                    <v-card-text class="text-center justify-center">
                      <v-icon small>mdi-clock-time-four-outline</v-icon> Estimated Course Time: {{ (course.estimatedTime / 60).toFixed(1) }} hours
                    </v-card-text>
                  </v-col>
                  <v-col>
                    <v-btn outlined color="primary" :href="course.url" target="_blank">Course Details</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="count > 1">
      <v-col>
        <v-pagination
          v-model="page"
          :length="count"
          circle
        ></v-pagination>
      </v-col>
    </v-row>
    </v-form>
    <v-dialog v-model="showCourseBuilder" width="700">
      <course-builder-component @courseCreated="reloadCourseList" :course="currentCourse" @closeCourseModal="showCourseBuilder=false" />
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import courseBuilderComponent from './components/courseBuilderComponent.vue'

export default {
  name: 'Courses',
  components: {
    'course-builder-component': courseBuilderComponent
  },
  computed: {
    ...mapGetters(['adminSettings', 'isEdittingDepartment', 'isEdittingCollege']),
    ...mapGetters(['universityUser']),
    hasAdminRole: {
      get: function () {
        return this.universityUser.roles.find(x => x === 'Admin') !== undefined
      }
    },
    edittingDepartment: {
      get () {
        return this.isEdittingDepartment
      },
      set (value) {
        this.$store.commit('editDepartment', value)
      }
    },
    edittingCollege: {
      get () {
        return this.isEdittingCollege
      },
      set (value) {
        this.$store.commit('editCollege', value)
      }
    }
  },
  watch: {
    page: function (newValue, oldValue) {
      if (oldValue !== newValue) {
        this.getCourses()
      }
    },
    search: function () {
      clearTimeout(this.searchTimeout)

      // Make a new timeout set to go off in 800ms
      this.searchTimeout = setTimeout(() => {
        if (this.search == null) {
          this.search = ''
        }
        this.page = 1
        this.getCourses()
      }, 500)
    }
  },
  data: () => ({
    validated: true,
    searchTimeout: null,
    courses: [ ],
    department: '',
    departmentId: '',
    courseId: '',
    currentCourse: '',
    isLoading: false,
    isSubmitted: false,
    isDeleting: false,
    isDeletingCourse: false,
    page: 1,
    limit: 12,
    count: 0,
    search: '',
    showCourseBuilder: false,
    departmentNameRules: [
      v => !!v || 'Department name is required.',
      v => (v && v.length < 100) || 'must be less than 100 characters'
    ],
    descriptionRules: [
      v => !!v || 'Department description is required.',
      v => (v && v.length < 300) || 'must be less than 300 characters'
    ]
  }),
  beforeRouteUpdate (to, from, next) {
    this.edittingDepartment = false
    this.isLoading = true
    this.loadPageData(to.params.departmentId)
    next()
  },
  methods: {
    openCourseBuilder: function (course) {
      this.currentCourse = JSON.parse(JSON.stringify(course))
      this.showCourseBuilder = true
    },
    createNewCourse: function () {
      this.currentCourse = {
        name: '',
        description: '',
        departmentId: '',
        url: '',
        type: '',
        estimatedTime: ''
      }
      this.showCourseBuilder = true
    },
    reloadCourseList: function () {
      this.showCourseBuilder = false
      this.isLoading = true
      this.loadPageData(this.$route.params.departmentId)
    },
    loadPageData: function (id) {
      this.departmentId = id
      this.courseId = ''
      this.getDepartment().then(() => {
        this.getCourses()
      })
    },
    validate: function () {
      this.validated = true
      if (!this.$refs.editDepartmentForm.validate()) {
        this.validated = false
      }
      return this.validated
    },
    cancelUpdate: function () {
      this.loadPageData(this.$route.params.departmentId)
      this.edittingDepartment = false
    },
    getCourses: function () {
      axios(
        `${process.env.VUE_APP_UNIVERSITYBASEAPIURL}/api/departments/${this.departmentId}/courses?page=${this.page}&limit=${this.limit}&search=${this.search}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })

        .then(
          (response) => {
            this.isLoading = false
            this.courses = response.data.items
            this.count = Math.ceil(response.data.totalCount / this.limit)
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getCourses() })
          })
    },
    getDepartment: function () {
      return axios(
        `${process.env.VUE_APP_UNIVERSITYBASEAPIURL}/api/departments/${this.departmentId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.department = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getDepartment() })
          })
    },
    updateDepartment: function () {
      this.isSubmitted = true

      if (!this.validate()) {
        this.isSubmitted = false
        return false
      }

      const departmentUpdate = this.department

      axios(
        `${process.env.VUE_APP_UNIVERSITYBASEAPIURL}/api/departments/${this.departmentId}`,
        {
          method: 'PUT',
          data: JSON.stringify(departmentUpdate),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.department = response.data
            this.$parent.$parent.getDepartments()
            this.edittingDepartment = false
            this.isSubmitted = false
            this.$store.commit('triggerSnack', { snackMessage: 'The department has been updated successfully', snackColor: 'success' })
          },
          (error) => {
            this.isSubmitted = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.updateDepartment() })
            return error
          })
    },
    displayDeleteDialog: function () {
      this.$store.commit('showDialog', {
        message: 'Delete this department? This action cannot be undone. Related courses will be deleted.',
        confirmMethod: () => {
          this.deleteDepartment()
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    deleteDepartment: function () {
      this.isDeleting = true
      return axios(
        `${process.env.VUE_APP_UNIVERSITYBASEAPIURL}/api/departments/${this.departmentId}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          () => {
            this.isDeleting = false
            this.edittingDepartment = false
            this.$parent.$parent.getDepartments()
            this.$router.push(`/colleges/${this.department.collegeId}/departments`)
          },
          (error) => {
            this.isDeleting = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.deleteDepartment() })
          })
    },
    displayDeleteCourseDialog: function (id) {
      this.$store.commit('showDialog', {
        message: 'Delete this course? This action cannot be undone.',
        confirmMethod: () => {
          this.deleteCourse(id)
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    deleteCourse: function (id) {
      this.isDeletingCourse = true
      return axios(
        `${process.env.VUE_APP_UNIVERSITYBASEAPIURL}/api/courses/${id}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          () => {
            this.isDeletingCourse = false
            this.reloadCourseList()
          },
          (error) => {
            this.isDeletingCourse = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.deleteCourse(id) })
          })
    }
  },
  mounted: function () {
    this.loadPageData(this.$route.params.departmentId)
  }
}
</script>

<style scoped>
  .v-card__title {
    color: #1976D2;
  }
  .v-card__text, .v-card__title {
    word-break: normal !important;
  }
</style>
