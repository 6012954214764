<template>
    <v-col cols="12" md="12">
      <v-skeleton-loader
        class="mx-auto"
        min-width="500"
        type="list-item-two-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="mx-auto"
        min-width="500"
        type="list-item-two-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="mx-auto"
        min-width="500"
        type="list-item-two-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="mx-auto"
        min-width="500"
        type="list-item-two-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="mx-auto"
        min-width="500"
        type="list-item-two-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="mx-auto"
        min-width="500"
        type="list-item-two-line"
      ></v-skeleton-loader>
    </v-col>
</template>

<script>
export default {
  name: 'template-loading-skeleton'
}
</script>

<style>
</style>
