<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h5">Api &amp; Repo Users:</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="createAdminUser" color="info">Add Admin User</v-btn>
      </v-col>
    </v-row>
    <v-row id="v-step-5">
      <v-col cols="12">
        <v-data-table
          :headers="adminUserHeaders"
          :items="adminUsers"
          :items-per-page="15"
          class="elevation-1"
          v-if="!loadingUsers"
        >
          <template v-slot:item.username="{ item }">
            <span>{{ item.name }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn icon @click="editAdminUser(item)"><v-icon>mdi-pencil</v-icon></v-btn>
            <v-btn icon @click="deleteAdminUser(item)"><v-icon>mdi-delete</v-icon></v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="displayAdminDialog" width="1500">
      <v-card>
        <v-card-title :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'" primary-title>Add/Update Admin User</v-card-title>
        <v-card-text>
          <v-container>
            <v-tabs v-model="userTab">
              <v-tab>
                User Account
              </v-tab>
              <v-tab>
                Apigee Product Account
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="userTab">
              <v-tab-item>
                <v-row>
                  <v-col v-if="!isEditAdmin">
                    <user-search-component class="required" v-model="currentAdminUserSearch" :no-clear="true" label="Admin User" />
                  </v-col>
                  <v-col v-else>
                    <v-text-field disabled v-model="currentAdminUser.name" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      v-model="currentAdminUser.affiliates"
                      :items="affiliates"
                      item-text="displayName"
                      item-value="id"
                      attach
                      chips
                      label="Affiliates (Optional)"
                      multiple
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-switch label="Super User" hint="If enabled, this user will have all admin access including the ability to create new admins" v-model="currentAdminUser.isSuperUser" />
                    <v-switch label="Api Administrator" hint="If enabled, this user will be able to see all products/apis in the catalog and act on Administrative settings" v-model="currentAdminUser.isApiAdmin" />
                    <v-switch label="Cloud Administrator" hint="If enabled, this user will be able to modify cloud Administrative settings" v-model="currentAdminUser.isCloudAdmin" />
                    <v-switch label="Repository Administrator" hint="If enabled, this user will be able to see configure repositories" v-model="currentAdminUser.isRepositoryAdmin" />
                    <v-switch label="MU3 Administrator" hint="If enabled, this user will be able to approve certain mu3 tasks" v-model="currentAdminUser.isMu3Admin" />
                    <v-switch label="MU3 Product Builder" hint="If enabled, this user can select MU3 external when building a new product" v-model="currentAdminUser.isMu3ProductBuilder" />
                    <v-switch label="Pulse Admin" hint="If enabled, this user can send pulse surveys and download survey results" v-model="currentAdminUser.isPulseAdmin" />
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-row>
                  <v-col v-if="!isEditAdmin">
                    <v-text-field label="Product Apigee Id" v-model="currentAdminUser.username"/>
                  </v-col>
                  <v-col v-else>
                    <v-text-field disabled v-model="currentAdminUser.username" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      v-model="currentAdminUser.affiliates"
                      :items="affiliates"
                      item-text="displayName"
                      item-value="id"
                      attach
                      chips
                      label="Affiliates (Optional)"
                      multiple
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-switch label="Super User" hint="If enabled, this user will have all admin access including the ability to create new admins" v-model="currentAdminUser.isSuperUser" />
                    <v-switch label="Api Administrator" hint="If enabled, this user will be able to see all products/apis in the catalog and act on Administrative settings" v-model="currentAdminUser.isApiAdmin" />
                    <v-switch label="Repository Administrator" hint="If enabled, this user will be able to see configure repositories" v-model="currentAdminUser.isRepositoryAdmin" />
                    <v-switch label="MU3 Administrator" hint="If enabled, this user will be able to approve certain mu3 tasks" v-model="currentAdminUser.isMu3Admin" />
                    <v-switch label="MU3 Product Builder" hint="If enabled, this user will be able to create mu3 external products" v-model="currentAdminUser.isMu3ProductBuilder" />
                    <v-switch label="Pulse Admin" hint="If enabled, this user can send pulse surveys and download survey results" v-model="currentAdminUser.isPulseAdmin" />
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="displayAdminDialog = false">Cancel</v-btn>
          <v-btn color="primary" :loading="savingAdminUser" :disabled="savingAdminUser" text @click="createOrUpdateAdminUser">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import userSearchComponent from '@/components/userSearchComponent'
import axios from 'axios'

export default {
  name: 'admin-users',
  components: {
    'user-search-component': userSearchComponent
  },
  watch: {
    currentAdminUserSearch: function (newValue, oldValue) {
      if (newValue) {
        this.currentAdminUser.username = newValue.Key
      }
    }
  },
  data: () => ({
    displayAdminDialog: false,
    loadingUsers: false,
    affiliates: [ ],
    adminUsers: [ ],
    currentAdminUserSearch: null,
    currentAdminUser: {
      username: '',
      affiliates: [ ],
      isSuperUser: false,
      isMu3Admin: false,
      isPulseAdmin: false,
      isCloudAdmin: false
    },
    isEditAdmin: false,
    savingAdminUser: false,
    adminUserHeaders: [
      {
        text: 'Name',
        align: 'left',
        sortable: true,
        value: 'name'
      },
      {
        text: 'Affiliate',
        align: 'left',
        sortable: true,
        value: 'displayAffiliates'
      },
      {
        text: 'Super User',
        align: 'left',
        sortable: true,
        value: 'isSuperUser'
      },
      {
        text: 'Api Admin',
        align: 'left',
        sortable: true,
        value: 'isApiAdmin'
      },
      {
        text: 'Cloud Admin',
        align: 'left',
        sortable: true,
        value: 'isCloudAdmin'
      },
      {
        text: 'Repository Admin',
        align: 'left',
        sortable: true,
        value: 'isRepositoryAdmin'
      },
      {
        text: 'MU3 Admin',
        align: 'left',
        sortable: true,
        value: 'isMu3Admin'
      },
      {
        text: 'MU3 Product Builder',
        align: 'left',
        sortable: true,
        value: 'isMu3ProductBuilder'
      },
      {
        text: 'Pulse Admin',
        align: 'left',
        sortable: true,
        value: 'isPulseAdmin'
      },
      {
        text: 'Actions',
        align: 'left',
        sortable: true,
        value: 'actions'
      }
    ],
    userTab: 0,
    page: 0,
    limit: 10,
    search: ''
  }),
  methods: {
    editAdminUser: function (item) {
      this.isEditAdmin = true
      this.currentAdminUser = item

      if (this.currentAdminUser.isProductAccount) {
        this.userTab = 1
      } else {
        this.userTab = 0
      }

      this.displayAdminDialog = true
    },
    createAdminUser: function () {
      this.currentAdminUser = {
        username: '',
        affiliates: [ ],
        isSuperUser: false,
        isMu3Admin: false,
        isMu3ProductBuilder: false,
        isPulseAdmin: false,
        isCloudAdmin: false
      }
      this.isEditAdmin = false
      this.displayAdminDialog = true
    },
    createOrUpdateAdminUser: function () {
      this.savingAdminUser = true

      if (this.userTab === 1) {
        this.currentAdminUser.isProductAccount = true
      } else {
        this.currentAdminUser.isProductAccount = false
      }

      axios(
        `${process.env.VUE_APP_BASEURL}/admin/users/`,
        {
          method: 'PUT',
          data: JSON.stringify(this.currentAdminUser),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.savingAdminUser = false
            this.getAdminUsers()
            this.displayAdminDialog = false
          },
          (error) => {
            this.savingAdminUser = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.createOrUpdateAdminUser() })
            return error
          })
    },
    getAdminUsers: async function () {
      this.adminUsers = [ ]
      return axios(
        `${process.env.VUE_APP_BASEURL}/admin/users`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.adminUsers = response.data
            this.adminUsers.forEach(x => {
              x.displayAffiliates = this.affiliates.filter(y => x.affiliates.find(z => z === y.id)).map(a => a.displayName).join(', ')
            })
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getAdminUsers() })
          })
    },
    deleteAdminUser: function (item) {
      axios(
        `${process.env.VUE_APP_BASEURL}/admin/users/${item.id}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.getAdminUsers()
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.deletePolicy() })
            return error
          })
    },
    getAffiliates: function () {
      this.loadingAffiliates = true
      return axios(
        `${process.env.VUE_APP_BASEURL}/affiliates/`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.affiliates = response.data
            this.loadingAffiliates = false
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getAffiliates() })
            return error
          })
    }
  },
  mounted: function () {
    this.getAffiliates().then(() => {
      this.getAdminUsers()
    })
  }
}
</script>

<style scoped>

</style>
