<template>
  <div>
    <v-navigation-drawer
      absolute
      :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
      permanent
      v-if="productId"
    >
      <v-list nav>
       <template v-for="item in links">
        <v-list-item
          link
          v-if="!item.subItems"
          :to="generateLink(item.link)"
          :key="item.title"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <template v-if="item.subItems">
          <v-list-group
            :key="item.title"
            :prepend-icon="item.icon"
            no-action
            :value = "shouldExpand(item)"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-if="item.subItems">
            <v-list-item
              v-for="child in display(item.subItems)"
              :key="child.title"
              link
              :to="generateLink(child.link)"
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            </template>
          </v-list-group>
        </template>
       </template>
      </v-list>
    </v-navigation-drawer>
    <div style="margin-left: 256px">
     <router-view></router-view>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import marked from 'marked'
import userSearchComponent from '@/components/userSearchComponent'
import groupSearchComponent from '@/components/groupSearchComponent'

export default {
  name: 'cloudEdit',
  data: () => ({
    links: [
      {
        icon: 'mdi-rocket-launch',
        title: 'Deployment',
        link: `deployments`,
        display: { val: '' }
      }
    ],
    productId: '',
    product: {
      displayName: '',
      description: '',
      allowedUsers: [ ],
      subscriptions: [ ]
    },
    cloudAccount: {}
  }),
  methods: {
    display (items) {
      items[0].val = true
      items[1].val = this.isCloudAccountCreationEnabled

      return items.filter(item => item.val === true)
    },
    shouldExpand: function (item) {
      let path = this.$router.currentRoute.path
      let subItemPath = item.subItems.find(x => {
        return this.generateLink(x.link) === path
      })

      if (subItemPath) {
        return true
      }

      return false
    },
    generateLink: function (subItem) {
      return `/products/${this.cloudAccount.evolveMarketplaceProductId}/cloud/accounts/${this.cloudAccount.id}/${subItem}`
    },
    getCloudAccount: function () { //   done
      return axios(
        `${process.env.VUE_APP_CLOUDBASEURL}/cloud/accounts/${this.cloudAccountId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.cloudAccount = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getTeam() })
          })
    }
  },
  beforeMount: function () { // done
    if (this.$route.params.id && this.$route.params.cloudAccountId) {
      this.productId = this.$route.params.id
      this.cloudAccountId = this.$route.params.cloudAccountId
      this.getCloudAccount().then(() => {
        this.$store.commit('breadcrumbs', [
          {
            text: 'Products',
            exact: true,
            disabled: false,
            to: '/products'
          },
          {
            text: `${this.cloudAccount.displayName}`,
            exact: true,
            disabled: false,
            to: `/products/${this.productId}`
          },
          {
            text: `Deployment`,
            exact: true,
            disabled: true,
            to: `/products/${this.cloudAccount.evolveMarketplaceProductId}/cloud/accounts/${this.cloudAccount.id}/deployments`
          }
        ])
      })
    }
  }
}
</script>
