var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('span',{staticClass:"text-h5"},[_vm._v("University Users")])])],1),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"info"},on:{"click":_vm.createAdminUser}},[_vm._v("Add Admin User")])],1)],1),_c('v-row',{attrs:{"id":"v-step-5"}},[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.loadingUsers)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.adminUserHeaders,"items":_vm.adminUsers.items,"items-per-page":15},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.firstName + ' ' + item.lastName))])]}},{key:"item.userId",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.userId))])]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.roles.join()))])]}},{key:"item.createdOn",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.meta.createdOnUtc).toLocaleString()))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteAdminUserDialog(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,false,326103648)}):_vm._e()],1)],1),_c('v-dialog',{attrs:{"width":"700"},model:{value:(_vm.displayAdminDialog),callback:function ($$v) {_vm.displayAdminDialog=$$v},expression:"displayAdminDialog"}},[_c('v-card',[_c('v-card-title',{class:_vm.$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3',attrs:{"primary-title":""}},[_vm._v("Add/Update Admin User")]),_c('v-card-text',[_c('v-container',[_c('v-row',[(!_vm.isEditAdmin)?_c('v-col',[_c('user-search-component',{staticClass:"required",attrs:{"no-clear":true,"label":"Admin User"},model:{value:(_vm.currentAdminUserSearch),callback:function ($$v) {_vm.currentAdminUserSearch=$$v},expression:"currentAdminUserSearch"}})],1):_c('v-col',[_c('v-text-field',{attrs:{"disabled":""},model:{value:(_vm.currentAdminUser.name),callback:function ($$v) {_vm.$set(_vm.currentAdminUser, "name", $$v)},expression:"currentAdminUser.name"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.displayAdminDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.savingAdminUser,"disabled":_vm.savingAdminUser,"text":""},on:{"click":_vm.createOrUpdateAdminUser}},[_vm._v("Save")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }