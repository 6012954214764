<template>
  <v-container>
    <v-row>
      <v-col>
          <h2>Public and Proxy Repositories</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-btn @click="addRepo" color="info">Add Public or Proxy Repository</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="repoHeaders"
          :items="repos"
          :items-per-page="15"
          v-if="!loadingRepos"
        >
          <template v-slot:item.repositoryType="{ item }">
            {{ repositoryType[item.repositoryType].key }}
          </template>
          <template v-slot:item.isProxyRepo="{ item }">
            {{ item.isProxyRepo ? 'Yes' : 'No' }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn icon @click="editRepo(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon @click="deleteRepoConfirmation(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="displayRepoDialog" width="1500">
      <v-card>
        <v-card-title :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'" primary-title>Add/Update Public Repository</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field v-model="currentRepo.name" label="Nexus Repository Name" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field v-model="currentRepo.displayName" label="Display Name" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="currentRepo.repositoryType"
                  :items="repositoryType"
                  item-text="key"
                  item-value="value"
                  label="Repository Type"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox
                  v-model="currentRepo.isProxyRepo"
                  label="Is this repo a proxy repo?"
                  hint="Proxy repos allow teams to pull in public repositories into their private repositories"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="displayRepoDialog = false">Cancel</v-btn>
          <v-btn color="primary" :loading="savingRepo" :disabled="savingRepo" text @click="createOrUpdateRepo">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'admin-repositories',
  computed: {
    ...mapGetters(['adminSettings'])
  },
  data: () => ({
    savingRepo: false,
    loadingRepos: false,
    repos: [ ],
    repoHeaders: [
      {
        text: 'Name',
        align: 'left',
        sortable: true,
        value: 'displayName'
      },
      {
        text: 'Type',
        align: 'left',
        sortable: true,
        value: 'repositoryType'
      },
      {
        text: 'Is Proxy Repo',
        align: 'left',
        sortable: true,
        value: 'isProxyRepo'
      },
      {
        text: 'Url',
        align: 'left',
        sortable: true,
        value: 'url'
      },
      {
        text: 'Actions',
        align: 'left',
        sortable: true,
        value: 'actions'
      }
    ],
    repositoryType: [
      {
        key: 'Nuget',
        value: 0
      },
      {
        key: 'NPM',
        value: 1
      },
      {
        key: 'Maven',
        value: 2
      },
      {
        key: 'Docker',
        value: 3
      },
      {
        key: 'Pypi',
        value: 4
      },
      {
        key: 'Bower',
        value: 5
      },
      {
        key: 'Helm',
        value: 6
      },
      {
        key: 'Raw',
        value: 7
      }
    ],
    displayRepoDialog: false,
    currentRepo: {
      name: '',
      displayName: '',
      url: ''
    }
  }),
  methods: {
    getDate: function (date) {
      return moment(date).fromNow()
    },
    getUserDetails: async function (username) {
      return axios(
        `${process.env.VUE_APP_BASENETWORKAPIURL}/activedirectory/search/user?searchText=${username}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getUserDetails() })
          })
    },
    getPublicRepos: function () {
      this.loadingRepos = true
      return axios(
        `${process.env.VUE_APP_REPO_BASEURL}/repositories/`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.repos = response.data
            this.loadingRepos = false
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getPublicRepos() })
          })
    },
    createOrUpdateRepo: function () {
      this.savingRepo = true
      axios(
        `${process.env.VUE_APP_REPO_BASEURL}/repositories`,
        {
          method: 'PUT',
          data: JSON.stringify(this.currentRepo),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.savingRepo = false
            this.getPublicRepos()
            this.displayRepoDialog = false
          },
          (error) => {
            this.savingRepo = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.createOrUpdateRepo() })
            return error
          })
    },
    addRepo: function () {
      this.currentRepo = {
        name: '',
        displayName: '',
        url: '',
        isProxyRepo: false
      }
      this.displayRepoDialog = true
    },
    editRepo: function (item) {
      this.currentRepo = item
      this.displayRepoDialog = true
    },
    deleteRepoConfirmation: function (item) {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to delete this repository. This action can not be undone',
        confirmMethod: () => {
          this.deleteRepo(item)
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    deleteRepo: function (item) {
      axios(
        `${process.env.VUE_APP_REPO_BASEURL}/repositories/public/${item.id}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.getPublicRepos()
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.deleteRepo() })
            return error
          })
    }
  },
  mounted: function () {
    if (this.adminSettings.isRepositoryAdmin) {
      this.getPublicRepos()
    }
  }
}
</script>

<style scoped>

</style>
