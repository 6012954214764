<template>
  <v-dialog v-model="openModal" width="1000">
    <v-card>
      <v-card-title>Template Builder</v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" md="6" sm="12">
                <v-text-field v-model="template.displayName" label="Name" :rules="requiredField" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" sm="12">
                <v-text-field v-model="template.description" label="Short Description" :rules="requiredField" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-select :items="templateTypes" v-model="selectedTemplateType" item-text="itemText" item-value="itemValue" label="Template Type" :rules="requiredSelectField" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-switch v-model="template.isEnabled" label="Enabled" :rules="requiredField" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  @change="getRepositories"
                  :loading="loadingOrgs"
                  :items="githubLinks"
                  v-model="template.githubOrganizationLinkId"
                  item-text="orgName"
                  item-value="id"
                  label="Github Organization"
                  hint="Select your github organization. If you do not have an organization go back to your team page and create one"
                  persistent-hint
                  no-data-text="No github organizations have been linked to this team"
                  :rules="requiredField"
                />
              </v-col>
            </v-row>
            <v-row v-if="template.githubOrganizationLinkId">
              <v-col cols="12" md="6">
                <v-autocomplete
                  :loading="loadingRepos"
                  :items="repos"
                  v-model="template.githubRepoName"
                  item-text="name"
                  item-value="name"
                  label="Github Repository"
                  hint="Select the repository from the list that holds the template code"
                  persistent-hint
                  no-data-text="No repositories could be found for the organization."
                  :rules="requiredSelectField"
                >
                  <v-btn slot="append-outer" @click="getRepositories" icon>
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
       <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="variableDialog = false; closeModal();"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="saveTemplate"
            :disabled="isSubmitting"
            :loading="isSubmitting"
          >
            Create Template
          </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'template-builder-component',
  props: {
    open: {
      required: true
    },
    teamId: {
      required: true
    }
  },
  computed: {
    ...mapGetters(['adminSettings'])
  },
  watch: {
    open: function () {
      this.openModal = this.open
    },
    openModal: function () {
      if (!this.openModal) {
        this.closeModal()
      }
    }
  },
  data: () => ({
    variableDialog: false,
    openModal: false,
    selectedTemplateType: null,
    team: null,
    githubLinks: [ ],
    loadingOrgs: false,
    loadingRepos: false,
    isSubmitting: false,
    repos: [ ],
    templateTypes: [
      {
        itemText: 'Github Action',
        itemValue: 0
      },
      {
        itemText: 'Project',
        itemValue: 3
      },
      {
        itemText: 'Legacy HCA Terraform Module',
        itemValue: 2
      }
    ],
    template: {
      name: '',
      description: '',
      isEnabled: true,
      tags: [ ],
      githubOrganizationLinkId: '',
      githubRepoName: '',
      templateType: 0
    },
    requiredSelectField: [
      v => v != null || 'This field is required'
    ],
    requiredField: [
      v => !!v || 'This field is required'
    ]
  }),
  methods: {
    closeModal: function () {
      this.$emit('closeModal')
    },
    getTeam: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/teams/${this.teamId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.team = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getTeam() })
          })
    },
    getGithubLinks: function () {
      this.loadingOrgs = true
      axios(
        `${process.env.VUE_APP_BASEURL}/github/teams/${this.teamId}/links`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.githubLinks = response.data
            this.loadingOrgs = false
          },
          (error) => {
            this.loadingOrgs = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getGithubLinks() })
            return error
          })
    },
    getRepositories: function () {
      this.loadingRepos = true
      axios(
        `${process.env.VUE_APP_BASEURL}/github/teams/${this.teamId}/links/${this.template.githubOrganizationLinkId}/repos`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.repos = response.data
            this.loadingRepos = false
          },
          (error) => {
            this.loadingRepos = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getGithubLinks() })
            return error
          })
    },
    saveTemplate: function () {
      this.template.templateType = this.selectedTemplateType
      this.template.teamId = this.teamId
      this.isSubmitting = true

      if (!this.validate()) {
        this.isSubmitting = false
        return false
      }

      axios(
        `${process.env.VUE_APP_TEMPLATEBASEURL}/api/templates`,
        {
          method: 'POST',
          data: JSON.stringify(this.template),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isSubmitting = false
            this.$router.push(`/templates/${response.data.id}/releases/latest`)
          },
          (error) => {
            this.isSubmitting = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.authorizeGithub() })
            return error
          })
    },
    validate: function () {
      let validated = true
      if (!this.$refs.form.validate()) {
        validated = false
      }
      return validated
    }
  },
  beforeMount: function () {
    this.getGithubLinks()
  }
}
</script>

<style scoped>

</style>
