<template>
  <v-container>
    <v-row>
      <v-col>
          <h2>Affiliates</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="2">
        <v-btn @click="addAffiliate" color="info">Add Affiliate</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="affiliateHeaders"
          :items="affiliates"
          :items-per-page="15"
          v-if="!loadingAffiliates"
        >
          <template v-slot:item.isPublic="{ item }">
            {{ item.isPublic ? "Yes" : "No" }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn icon @click="editAffiliate(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon @click="deleteAffiliateDialog(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="displayAffiliateDialog" width="500">
      <v-card>
        <v-card-title :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'" primary-title>Create/Update Affiliate</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field v-model="affiliate.displayName" label="DisplayName" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-switch v-model="affiliate.isPublic" label="Publically Available"></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" id="v-step-4">
                <group-search-component class="required" id="v-step-5" v-model="currentGroupSearch" label="Groups allowed to select this affiliate" />
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Name</th>
                        <th class="text-left">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(group, index) in affiliate.groups" :key="index">
                        <td>{{ group }}</td>
                        <v-btn icon @click="affiliate.groups.splice(index, 1)"><v-icon>mdi-delete</v-icon></v-btn>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" id="v-step-4">
                <user-search-component class="required" id="v-step-5" v-model="currentUserSearch" label="Users allowed to select this affiliate" />
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">34</th>
                        <th class="text-left">Name</th>
                        <th class="text-left">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(user, index) in affiliateUsers" :key="index">
                        <td>{{ user.Key }}</td>
                        <td>{{ user.Value }}</td>
                        <v-btn icon @click="affiliateUsers.splice(index, 1)"><v-icon>mdi-delete</v-icon></v-btn>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="displayAffiliateDialog = false">Cancel</v-btn>
          <v-btn color="primary" :loading="savingAffiliate" :disabled="savingAffiliate" text @click="createOrUpdateAffiliate">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import groupSearchComponent from '@/components/groupSearchComponent'
import userSearchComponent from '@/components/userSearchComponent'

export default {
  name: 'admin-api-affiliates',
  components: {
    'group-search-component': groupSearchComponent,
    'user-search-component': userSearchComponent
  },
  watch: {
    currentGroupSearch: function (newValue, oldValue) {
      if (newValue) {
        if (!this.affiliate.groups.find(x => x === newValue)) {
          this.affiliate.groups.push(newValue)
        }
      }
    },
    currentUserSearch: function (newValue, oldValue) {
      if (newValue) {
        if (!this.affiliateUsers.find(x => x.Key === newValue.Key)) {
          this.affiliateUsers.push(newValue)
        }
      }
    }
  },
  data: () => ({
    affiliates: [ ],
    affiliateUsers: [ ],
    loadingAffiliates: false,
    savingAffiliate: false,
    affiliate: {
      displayName: '',
      groups: [ ],
      users: [ ],
      apigeeRoleName: ''
    },
    displayAffiliateDialog: false,
    affiliateHeaders: [
      {
        text: 'Name',
        align: 'left',
        sortable: true,
        value: 'displayName'
      },
      {
        text: 'Apigee Role Name',
        align: 'left',
        sortable: true,
        value: 'apigeeRoleName'
      },
      {
        text: 'Publicly Available',
        align: 'left',
        sortable: true,
        value: 'isPublic'
      },
      {
        text: 'Actions',
        align: 'left',
        sortable: true,
        value: 'actions'
      }
    ],
    currentGroupSearch: null,
    currentUserSearch: null
  }),
  methods: {
    getAffiliates: function () {
      this.loadingAffiliates = true
      return axios(
        `${process.env.VUE_APP_BASEURL}/affiliates/`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.affiliates = response.data
            this.loadingAffiliates = false
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getAffiliates() })
            return error
          })
    },
    addAffiliate: function () {
      this.affiliate = {
        displayName: '',
        groups: [ ],
        users: [ ],
        isPublic: true
      }
      this.affiliateUsers = [ ]
      this.displayAffiliateDialog = true
    },
    editAffiliate: function (item) {
      this.affiliate = item
      if (this.affiliate.users) {
        this.affiliateUsers = [ ]
        this.affiliate.users.forEach(x => {
          this.getUserDetails(x).then(x => {
            if (x) {
              this.affiliateUsers.push(x[0])
            }
          })
        })
      }
      this.displayAffiliateDialog = true
    },
    deleteAffiliateDialog: function (item) {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to delete this affiliate. This action can not be undone',
        confirmMethod: () => {
          this.deleteAffiliate(item)
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    deleteAffiliate: function (item) {
      return axios(
        `${process.env.VUE_APP_BASEURL}/affiliates/${item.id}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.$emit('getAffiliates')
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.deleteAffiliate() })
            return error
          })
    },
    getUserDetails: async function (username) {
      return axios(
        `${process.env.VUE_APP_BASENETWORKAPIURL}/activedirectory/search/user?searchText=${username}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getUserDetails() })
          })
    },
    createOrUpdateAffiliate: function () {
      this.savingAffiliate = true

      if (this.affiliateUsers) {
        this.affiliate.users = [ ]
        this.affiliateUsers.forEach(x => {
          this.affiliate.users.push(x.Key)
        })
      }

      if (this.affiliate.id) {
        axios(
          `${process.env.VUE_APP_BASEURL}/affiliates/${this.affiliate.id}`,
          {
            method: 'PUT',
            data: JSON.stringify(this.affiliate),
            headers: { 'Content-Type': 'application/json' }
          })
          .then(
            (response) => {
              this.savingAffiliate = false
              this.displayAffiliateDialog = false
              this.affiliate = { }
              this.affiliateUsers = [ ]
              this.$emit('getAffiliates')
            },
            (error) => {
              this.savingAffiliate = false
              this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.createOrUpdateAffiliate() })
              return error
            })
      } else {
        axios(
          `${process.env.VUE_APP_BASEURL}/affiliates`,
          {
            method: 'POST',
            data: JSON.stringify(this.affiliate),
            headers: { 'Content-Type': 'application/json' }
          })
          .then(
            (response) => {
              this.savingAffiliate = false
              this.displayAffiliateDialog = false
              this.affiliate = { }
              this.affiliateUsers = [ ]
              this.$emit('getAffiliates')
            },
            (error) => {
              this.savingAffiliate = false
              this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.createOrUpdateAffiliate() })
              return error
            })
      }
    }
  },
  mounted: function () {
    this.getAffiliates()
  }
}
</script>

<style scoped>

</style>
