<template>
  <v-row class="mt-2">
    <v-col>
      <v-row>
        <v-col cols="12" md="12" v-if="adminSettings && (adminSettings.enableRepositoryFeatures || adminSettings.isSuperUser)">
          <v-btn color="primary" @click="showTemplateBuilder = true">Create a new template</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-if="teamTemplates">
          <v-row align="center" justify="start">
            <v-card
              class="ma-2 pa-1"
              hover
              ripple
              v-for="(template, index) in teamTemplates" :key="index"
              max-width="344"
              min-width="344"
              max-height="115"
              min-height="115"
              :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
              :to="`/templates/${template.id}`"
            >
              <v-card-title class="card-title text-no-wrap text-truncate">{{ template.displayName }}</v-card-title>
              <v-card-subtitle class="text-no-wrap text-truncate"><v-chip :color="generateColor(template)">{{ generateText(template) }}</v-chip></v-card-subtitle>
            </v-card>
          </v-row>
        </v-col>
        <v-col cols="12" v-if="teamTemplates.length === 0">
          No Templates found for this team. Use the 'Create a Template' button to build one
        </v-col>
      </v-row>
      <v-row v-if="teamTemplateCount > 1">
        <v-col>
          <v-pagination
            v-model="teamTemplatePage"
            :length="teamTemplateCount"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
      <template-builder-component v-if="teamId" :open="showTemplateBuilder" :teamId="teamId" @closeModal="showTemplateBuilder = false" />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import templateBuilderComponent from './templateBuilderComponent.vue'

export default {
  name: 'team-templates',
  components: {
    'template-builder-component': templateBuilderComponent
  },
  computed: {
    ...mapGetters(['adminSettings']),
    ...mapGetters('teams', ['teamTemplates', 'teamId', 'teamTemplatePage', 'teamTemplateCount']),
    ...mapState(['teams'])
  },
  watch: {
    // templatePage: function (newValue, oldValue) {
    //   if (oldValue !== newValue) {
    //     this.getTemplates()
    //   }
    // }
  },
  data: () => ({
    showTemplateBuilder: false,
    types: [
      {
        key: 'Github Actions',
        value: 0,
        color: '#B2EBF2'
      },
      {
        key: 'Github Workflow',
        value: 1,
        color: '#'
      },
      {
        key: 'Legacy Terraform Module',
        value: 2,
        color: '#66BB6A'
      },
      {
        key: 'Project',
        value: 3,
        color: '#FFECB3'
      },
      {
        key: 'Terraform Module',
        value: 6,
        color: '#4DB6AC'
      }
    ]
  }),
  methods: {
    generateColor: function (template) {
      var type = this.types.find(x => x.value === template.templateType)
      if (type) {
        return type.color
      } else {
        return '#757575'
      }
    },
    generateText: function (template) {
      var type = this.types.find(x => x.value === template.templateType)
      if (type) {
        return type.key
      } else {
        return 'Unknown'
      }
    }
  },
  mounted: function () {
  }
}
</script>

<style scoped>
</style>
