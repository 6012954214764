<template>
<v-container>
    <v-form ref="form">
    <v-row>
      <v-col cols="12" md="8" sm="6" class="d-flex justify-start align-center">
        <v-skeleton-loader
          v-if="!subscription"
          class="ma-2"
          style="height: 90px; width: 90px;"
          type="image"
        ></v-skeleton-loader>
          <v-img
            v-if="subscription && subscription.provider == 0"
            class="image-select mb-1"
            max-height="100"
            max-width="100"
            min-height="100"
            min-width="100"
            src="@/assets/cloud/gcp.png"
            contain
            style="width: 100px"
          />
          <v-img
            v-if="subscription && subscription.provider == 1"
            class="image-select mb-1"
            max-height="100"
            max-width="100"
            min-height="100"
            min-width="100"
            src="@/assets/cloud/azure.png"
            contain
            style="width: 100px"
          />
          <v-img
            v-if="subscription && subscription.provider == 2"
            class="image-select mb-1"
            max-height="100"
            max-width="100"
            min-height="100"
            min-width="100"
            src="@/assets/cloud/aws.png"
            contain
            style="width: 100px"
          />
        <v-skeleton-loader
          v-if="!subscription"
          type="list-item-two-line"
          class="badge-name-loading"
          style="width: 500px"
        ></v-skeleton-loader>
        <span v-if="subscription && subscription.displayName" class="ma-2 text-h4">{{ subscription.displayName }}</span>
      </v-col>
      <v-spacer/>
      <v-col cols="12" md="2" class="d-flex justify-center align-center">
        <v-btn color="error" @click="displayDeleteDialog">Delete Subscription</v-btn>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12" md="12" xl="12">
      <v-tabs
        v-model="tabView"
      >
        <v-tab>Details</v-tab>
        <v-tab>Environments</v-tab>
        <v-tab>Approvals</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabView">
        <v-tab-item>
          <v-container v-if="subscription">
            <v-row>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col>
                    <v-text-field readonly v-model="subscription.displayName" label="Display Name" hint="Give this subscription a friendly name" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select readonly :items="cloudProviders" v-model="subscription.provider" label="Cloud Provider" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select readonly :items="cloudOrganizations" item-value="id" item-text="name" v-model="subscription.cloudOrganizationId" label="Cloud Organization Group" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field readonly v-model="subscription.appCode" label="Application Code" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span>Service Central CI: <a :href="`https://hcaservicecentral.service-now.com/nav_to.do?uri=cmdb_ci.do?sys_id=${subscription.serviceCentralCmdbId}`" target="_blank">{{subscription.serviceCentralCmdbName }}</a></span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col>
                    <v-text-field readonly v-model="subscription.coid" label="HCA Coid" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field readonly v-model="subscription.departmentId" label="HCA Department Id" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field readonly v-model="subscription.activityId" label="HCA Activity Id" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field readonly v-model="subscription.tcoCode" label="HCA TCO Code" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field readonly v-model="subscription.softwareTag" label="HCA Software Tag" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container v-if="subscription">
            <v-row>
              <v-col>
                <v-data-table
                  :items="subscription.environments"
                  :headers="environmentHeaders"
                >
                  <template v-slot:item.type="{ item }">
                    {{ environmentTypes.find(x => x.value == item.type).text }}
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-btn icon><v-icon>mdi-magnify</v-icon></v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container v-if="subscription">
            <v-row>
              <v-col>
                <v-data-table
                  :items="subscription.approvals"
                  :headers="approvalHeaders"
                >
                 <template v-slot:item.status="{ item }">
                   <span v-if="item.isApproved">Approved</span>
                   <span v-if="item.isRejected">Rejected</span>
                   <span v-if="!item.isApproved && !item.isRejected">Pending</span>
                 </template>
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon color="primary" @click="approveSubscription(item)" class="mr-2 action-button">
                          <v-icon color="success">mdi-check</v-icon>
                        </v-btn>
                      </template>
                      <span>Approve</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon color="error" @click="rejectSubscription(item)" class="mr-2 action-button">
                          <v-icon color="error">mdi-close</v-icon>
                        </v-btn>
                      </template>
                      <span>Reject</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
      </v-col>
    </v-row>
    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'subscription-details',
  computed: {
    ...mapGetters(['adminSettings']),
    isCloudAdmin: {
      get: function () {
        return this.adminSettings.isSuperUser || this.adminSettings.isCloudAdmin
      }
    }
  },
  data: () => ({
    subscription: null,
    product: null,
    subscriptionId: null,
    productId: null,
    tabView: 0,
    cloudOrganizations: [],
    cloudProviders: [
      {
        value: 0,
        text: 'GCP'
      },
      {
        value: 1,
        text: 'Azure'
      },
      {
        value: 2,
        text: 'AWS'
      }
    ],
    environmentHeaders: [
      {
        text: 'Environment Name',
        align: 'start',
        sortable: false,
        value: 'name'
      },
      {
        text: 'Environment Type',
        align: 'start',
        sortable: false,
        value: 'type'
      },
      {
        text: 'Actions',
        align: 'start',
        sortable: false,
        value: 'actions'
      }
    ],
    environmentTypes: [
      {
        value: 0,
        text: 'Non-Production'
      },
      {
        value: 1,
        text: 'Production'
      }
    ],
    approvalHeaders: [
      {
        text: 'Approval Type',
        align: 'start',
        sortable: false,
        value: 'type'
      },
      {
        text: 'Status',
        align: 'start',
        sortable: false,
        value: 'status'
      },
      {
        text: 'Completed By',
        align: 'start',
        sortable: false,
        value: 'completedBy'
      },
      {
        text: 'Completed',
        align: 'start',
        sortable: false,
        value: 'completedDateTimeUtc'
      },
      {
        text: 'Actions',
        align: 'start',
        sortable: false,
        value: 'actions'
      }
    ]
  }),
  methods: {
    getProduct: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/product/${this.productId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.product = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getProduct() })
          })
    },
    getCloudSubscription: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/cloud/subscriptions/${this.subscriptionId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.subscription = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getCloudSubscription() })
          })
    },
    getCloudOrganizations: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/cloud/organizations?isPublicView=true`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.cloudOrganizations = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getCloudOrganizations() })
            return error
          })
    },
    displayDeleteDialog: function () {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to delete this Subscription? This action can not be undone.',
        confirmMethod: () => {
          this.deleteSubscription()
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    deleteSubscription: function () {
      this.isDeleting = true
      return axios(
        `${process.env.VUE_APP_BASEURL}/cloud/subscriptions/${this.subscriptionId}/`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          () => {
            this.$router.push(`/products/${this.productId}`)
          },
          (error) => {
            this.isDeleting = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.deleteSubscription() })
          })
    },
    approveSubscription: function (item) {
      let approvalItem = this.subscription.approvals.find(x => x.type === item.type)

      approvalItem.isApproved = true
      approvalItem.isRejected = false
      return axios(
        `${process.env.VUE_APP_BASEURL}/cloud/subscriptions/${this.subscriptionId}/`,
        {
          method: 'PUT',
          data: JSON.stringify(this.subscription),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.subscription = response.data
          },
          (error) => {
            this.isDeleting = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.deleteSubscription() })
          })
    },
    rejectSubscription: function (item) {
      let approvalItem = this.subscription.approvals.find(x => x.type === item.type)

      approvalItem.isRejected = true
      approvalItem.isApproved = false
      return axios(
        `${process.env.VUE_APP_BASEURL}/cloud/subscriptions/${this.subscriptionId}/`,
        {
          method: 'PUT',
          data: JSON.stringify(this.subscription),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.subscription = response.data
          },
          (error) => {
            this.isDeleting = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.deleteSubscription() })
          })
    }
  },
  mounted: function () {
    if (this.$route.params.id && this.$route.params.subscriptionId) {
      this.productId = this.$route.params.id
      this.subscriptionId = this.$route.params.subscriptionId
      this.getCloudOrganizations()
      this.getCloudSubscription()
      this.getProduct().then(() => {
        this.$store.commit('breadcrumbs', [
          {
            text: 'My Products',
            exact: true,
            disabled: false,
            to: '/products'
          },
          {
            text: this.product.displayName,
            exact: true,
            disabled: false,
            to: `/products/${this.productId}`
          },
          {
            text: 'Cloud Subscription',
            exact: true,
            disabled: true
          },
          {
            text: this.subscription.displayName,
            exact: true,
            disabled: false,
            to: `/products/${this.productId}/cloud/subscription/${this.subscriptionId}`
          }
        ])
      })
    } else {
      alert('could not find product as an ID was not provided in the url')
    }
  }
}
</script>

<style scoped>

</style>
