<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="12" id="v-step-0">
          <h1 class="text-center">Build a Product</h1>
            <p class="text-center">Follow the form below to build a product</p>
      </v-col>
    </v-row>
    <v-divider/>
    <v-row justify="center">
      <v-col cols="12" md="12">
        <v-card
          :loading="isSubmitted"
        >
          <template v-slot:progress>
            <v-progress-linear
              absolute
              color="green lighten-3"
              height="4"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-row>
                    <v-col>
                      <v-text-field class="required" @blur="overwritePath = false" label="Name" hint="The name of your product. This cannot be changed later" :rules="nameRules" required :disabled="isSubmitted" v-model="productName"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-switch label="Product only used to subscribe to APIs" v-model="product.isSubscriberOnly" hint="Select this if your product will only be used to subscribe to APIs. This value can not be changed in the future." persistent-hint />
                    </v-col>
                  </v-row>
                  <v-row v-if="adminSettings.isMu3ProductBuilder && !product.isSubscriberOnly">
                    <v-col>
                      <v-switch label="Product is only used for MU3 External APIs" v-model="product.isMU3Product" hint="Select this if your product will only be used for MU3 External APIs" persistent-hint />
                    </v-col>
                  </v-row>
                  <v-row v-if="!product.isSubscriberOnly">
                    <v-col id="v-step-2">
                      <v-text-field class="required" label="Base Url Route" :rules="routeRules" required hint="All Apis will begin with this route. This cannot be changed later" v-model="baseUrlPath" />
                    </v-col>
                  </v-row>
                  <v-row v-if="!product.isSubscriberOnly">
                    <v-col>
                      <v-text-field filled label="Calculated Path" v-model="calculatedPath" disabled></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        v-model="product.affiliates"
                        :items="affiliates"
                        item-text="displayName"
                        item-value="id"
                        attach
                        chips
                        label="Affiliates (Optional)"
                        multiple
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        v-model="product.teamId"
                        :items="teams"
                        item-text="displayName"
                        item-value="id"
                        label="Team"
                        :rules="teamRules"
                        required
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-textarea v-model="product.description" label="Description (Markdown Supported)" />
                      <v-btn x-small outlined @click="previewMarkdownDialog = true">Preview Markdown</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-row>
                    <v-col cols="12" md="12" id="v-step-4">
                      <user-search-component class="required" id="v-step-5" :disabled="isSubmitted" v-model="currentUserSearch" label="Admin Users" />
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">34</th>
                              <th class="text-left">Name</th>
                              <th class="text-left">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="user in allowedUsers" :key="user.Key">
                              <td>{{ user.Key }}</td>
                              <td>{{ user.Value }}</td>
                              <v-btn icon @click="deleteUser(user.Key)"><v-icon>mdi-delete</v-icon></v-btn>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" id="v-step-4">
                      <group-search-component class="required" id="v-step-5" :disabled="isSubmitted" v-model="currentGroupSearch" label="Admin AD Groups (Optional)" />
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Group</th>
                              <th class="text-left">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="group in allowedGroups" :key="group">
                              <td>{{ group }}</td>
                              <v-btn icon @click="deleteGroup(group)"><v-icon>mdi-delete</v-icon></v-btn>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col id="v-step-6">
                  <v-btn style="color: white !important;" color="#E05929" :disabled="isSubmitted" @click="create">Create</v-btn>
                </v-col>
              </v-row>
              <v-row v-if="!validated || userError || pathError">
                <v-col class="text-center">
                  <span style="color: #ff5252 !important">There are errors with your request. Please check the form above and try again.</span>
                </v-col>
              </v-row>
              <v-row v-if="!validated || userError">
                <v-col class="text-center">
                  <span v-if="userError" style="color: #ff5252 !important">At least one user or group is required</span>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="previewMarkdownDialog"
      width="500"
      >
        <v-card>
          <v-card-title
            :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'"
            primary-title
          >
            Markdown Preview
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <div v-html="compiledMarkdown(product.description)"></div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="previewMarkdownDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import marked from 'marked'
import { mapGetters, mapActions } from 'vuex'
import userSearchComponent from '@/components/userSearchComponent'
import groupSearchComponent from '@/components/groupSearchComponent'

export default {
  name: 'ProductBuilder',
  components: {
    'user-search-component': userSearchComponent,
    'group-search-component': groupSearchComponent
  },
  watch: {
    currentUserSearch: function (newValue, oldValue) {
      if (newValue) {
        if (!this.allowedUsers.find(x => x.Key === newValue.Key)) {
          this.allowedUsers.push(newValue)
        }
      }
    },
    currentGroupSearch: function (newValue, oldValue) {
      if (newValue) {
        if (!this.allowedGroups.find(x => x === newValue)) {
          if (newValue.toLowerCase().startsWith('domain')) {
            this.$store.commit('showErrorDialog',
              {
                errorData: {
                  response: {
                    data: {
                      errorMessage: 'You cannot use a Domain Group for this list. Please select a more granular group'
                    }
                  }
                },
                persistErrorDialog: false
              })
          } else {
            this.allowedGroups.push(newValue)
          }
        }
      }
    },
    productName: function (newValue, oldValue) {
      if (this.overwritePath) {
        this.baseUrlPath = newValue
      }
    },
    baseUrlPath: function (newValue, oldValue) {
      var newPath = newValue

      if (newPath.startsWith('/')) {
        newPath = newPath.slice(1)
      }
      if (newPath.endsWith('/')) {
        newPath = newPath.substring(0, newPath.length - 1)
      }

      newPath = newPath.replace(/\s+/g, '-').toLowerCase()

      if (newPath[newPath.length - 1] === '-') {
        newPath = newPath.substring(0, newPath.length - 1)
      }
      this.calculatedPath = '/' + newPath.replace(/\s+/g, '-') + '/'

      this.baseUrlPath = newPath

      if (this.calculatedPath.split('/').length - 1 > 2) {
        this.pathError = true
      } else if (this.calculatedPath === '//') {
        this.pathError = true
      } else {
        this.pathError = false
      }
    }
  },
  data: () => ({
    previewMarkdownDialog: false,
    overwritePath: true,
    teams: [ ],
    productName: '',
    baseUrlPath: '',
    calculatedPath: '',
    isSubmitted: false,
    currentUserSearch: null,
    currentGroupSearch: null,
    allowedUsers: [ ],
    allowedGroups: [ ],
    userError: false,
    pathError: false,
    affiliates: [ ],
    validated: true,
    product: {
      displayName: '',
      description: '',
      allowedUsers: [ ],
      baseUrlPath: '',
      teamId: null,
      isSubscriberOnly: false,
      isMU3Product: false
    },
    nameRules: [
      v => !!v || 'A Name is required',
      v => (v && v.length < 100) || 'A Name must be less than 100 characters'
    ],
    descriptionRules: [
      v => !!v || 'A Description is required',
      v => (v && v.length < 500) || 'The Description must be less than 500 characters'
    ],
    teamRules: [
      v => !!v || 'A Team is required'
    ],
    routeRules: [
      v => !!v || 'A Base Url Route is required'
    ],
    renderConfig: {
      // Mermaid config
      mermaid: {
        theme: 'dark'
      }
    }
  }),
  computed: {
    ...mapGetters([
      'oidcUser',
      'adminSettings'
    ]),
    appRouteName: {
      get () {
        return `/${this.product.displayName.toLowerCase().replace(/\s+/g, '-')}/`
      }
    },
    pathRule: {
      get () {
        const rule = !this.pathError || 'The path is required and your path can not exceed one level. Please remove the text after the first /'
        return [rule]
      }
    }
  },
  methods: {
    compiledMarkdown: function (input) {
      return marked(input, { sanitize: true })
    },
    getAffiliates: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/affiliates/`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.affiliates = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getAffiliates() })
            return error
          })
    },
    getMyTeams: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/teams?showOwnedTeamsOnly=true`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.teams = response.data.items
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getAllTeams() })
          })
    },
    validate: function () {
      this.validated = true
      this.userError = false
      if (this.allowedUsers.length <= 0 && this.allowedGroups.length <= 0) {
        this.userError = true
        this.validated = false
      }

      if (this.pathError) {
        this.validated = false
      }

      if (!this.$refs.form.validate()) {
        this.validated = false
      }
      return this.validated
    },
    deleteUser: function (username) {
      this.allowedUsers = this.allowedUsers.filter((item) => {
        return item.Key !== username
      })
    },
    deleteGroup: function (group) {
      this.allowedGroups = this.allowedGroups.filter((item) => {
        return item !== group
      })
    },
    create: function () {
      this.isSubmitted = true

      if (!this.validate()) {
        this.isSubmitted = false
        return false
      }

      if (!this.product.isSubscriberOnly) {
        this.product.baseUrlPath = this.baseUrlPath
      }

      this.product.displayName = this.productName

      if (this.allowedUsers) {
        this.product.allowedUsers = [ ]
        this.allowedUsers.forEach(x => {
          this.product.allowedUsers.push(x.Key)
        })
      }

      if (this.allowedGroups) {
        this.product.allowedGroups = this.allowedGroups
      }

      axios(
        `${process.env.VUE_APP_BASEURL}/product/`,
        {
          method: 'POST',
          data: JSON.stringify(this.product),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isSubmitted = false
            this.$router.push(`/products/${response.data.id}`)
          },
          (error) => {
            this.isSubmitted = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.create() })
            return error
          })
    }
  },
  mounted: function () {
    this.getAffiliates()
    this.getMyTeams()
    this.allowedUsers.push({
      Key: this.oidcUser.sub,
      Value: this.oidcUser.displayName
    })

    if (this.$router.currentRoute.query && this.$router.currentRoute.query.teamId) {
      this.product.teamId = this.$router.currentRoute.query.teamId
    }
  }
}
</script>
<style scoped>
  .user-error {
    font-size: 12px;
  }

  .required label::after {
    content: "*";
    color: 'red'
}
</style>
