<template>
  <v-container>
    <v-row>
      <v-col>
          <h2>Pending API Deployments:</h2>
      </v-col>
    </v-row>
    <v-row id="v-step-5">
      <v-col cols="12">
        <v-data-table
          :headers="deploymentHeaders"
          :items="deployments"
          :items-per-page="15"
          class="elevation-1"
          v-if="!loadingDeployments"
        >
          <template v-slot:item.environmentId="{ item }">
            <span>{{ getEnvironmentName(item.environmentId) }}</span>
          </template>
          <template v-slot:item.productId="{ item }">
            <span>{{ item.productName }}</span>
          </template>
          <template v-slot:item.createdDateTimeUtc="{ item }">
            <span>{{ getDate(item.createdDateTimeUtc) }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon color="primary" :loading="currentlyApproving.includes(item.id)" @click="approveDeploymentConfirmation(item.id)" class="mr-2 action-button">
                  <v-icon color="success">mdi-check</v-icon>
                </v-btn>
              </template>
              <span>Approve</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon color="error" :loading="currentlyDenying.includes(item.id)" @click="denyDeploymentConfirmation(item.id)" class="mr-2 action-button">
                  <v-icon color="error">mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Reject</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon color="info" :to="'/apis/' + item.apiId" target="_blank" class="action-button">
                  <v-icon>mdi-call-made</v-icon>
                </v-btn>
              </template>
              <span>Visit Api</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <v-col v-if="loadingDeployments">
        <v-skeleton-loader
          class="mx-auto"
          type="table"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
        <v-col>
            <h2>Completed API Deployment Requests:</h2>
        </v-col>
    </v-row>
    <v-row id="v-step-5">
      <v-col cols="12">
        <v-data-table
          :headers="completedDeploymentHeaders"
          :items="completedDeployments"
          :items-per-page="15"
          class="elevation-1"
          v-if="!loadingCompletedDeployments"
        >
          <template v-slot:item.environmentId="{ item }">
            <span>{{ getEnvironmentName(item.environmentId) }}</span>
          </template>
          <template v-slot:item.productId="{ item }">
            <span>{{ item.productName }}</span>
          </template>
          <template v-slot:item.createdDateTimeUtc="{ item }">
            <span>{{ getDate(item.createdDateTimeUtc) }}</span>
          </template>
          <template v-slot:item.completionDateTimeUtc="{ item }">
            <span>{{ getDate(item.completionDateTimeUtc) }}</span>
          </template>
          <template v-slot:item.status="{ item }">
            <span v-if="item.status === 0">Approved</span>
            <span v-else-if="item.status === 2">Denied</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon color="info" :to="'/apis/' + item.apiId" target="_blank" class="action-button">
                  <v-icon>mdi-call-made</v-icon>
                </v-btn>
              </template>
              <span>Visit Api</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <v-col v-if="loadingCompletedDeployments">
        <v-skeleton-loader
          class="mx-auto"
          type="table"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
        <v-dialog v-model="displayApproveDialog" width="500">
      <v-card>
        <v-card-title :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'" primary-title>Confirmation Required</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <h3>You are about to deploy this API to an internet facing environment.</h3><br/>
                <p>External APIs require ISC approval prior to being published.
                  <br/> By clicking approve, you agree that you have verified the ISC process has been followed and this API has been approved for external deployment.
                </p>
                <v-text-field v-model="reason" label="Change Ticket # or other ISC Identifier" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="displayApproveDialog = false">Cancel</v-btn>
          <v-btn color="primary" @click="approveDeployment" text>Approve</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="displayDenyDialog" width="500">
      <v-card>
        <v-card-title :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'" primary-title>Confirmation Required</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <p>Are you sure you wish to deny this deployment request? This action cannot be undone and the user will need to request approval again</p>
                <v-text-field v-model="reason" label="Deny Reason" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="displayDenyDialog = false">Cancel</v-btn>
          <v-btn color="primary" text @click="denyDeployment">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'admin-api-deployments',
  data: () => ({
    currentlyApproving: [ ],
    currentlyDenying: [ ],
    loadingDeployments: true,
    loadingCompletedDeployments: true,
    completedDeployments: [ ],
    deployments: [ ],
    environments: [ ],
    deploymentHeaders: [
      {
        text: 'Api Name',
        align: 'left',
        sortable: true,
        value: 'apiName'
      },
      {
        text: 'Product',
        align: 'left',
        sortable: true,
        value: 'productId'
      },
      {
        text: 'Environment',
        align: 'left',
        sortable: true,
        value: 'environmentId'
      },
      {
        text: 'Requested By',
        align: 'left',
        sortable: true,
        value: 'initiatedBy'
      },
      {
        text: 'Requested Time',
        align: 'left',
        sortable: true,
        value: 'createdDateTimeUtc'
      },
      {
        text: 'Actions',
        align: 'left',
        sortable: true,
        value: 'actions'
      }
    ],
    completedDeploymentHeaders: [
      {
        text: 'Api Name',
        align: 'left',
        sortable: true,
        value: 'apiName'
      },
      {
        text: 'Product',
        align: 'left',
        sortable: true,
        value: 'productId'
      },
      {
        text: 'Environment',
        align: 'left',
        sortable: true,
        value: 'environmentId'
      },
      {
        text: 'Requested Time',
        align: 'left',
        sortable: true,
        value: 'createdDateTimeUtc'
      },
      {
        text: 'Completed Time',
        align: 'left',
        sortable: true,
        value: 'completionDateTimeUtc'
      },
      {
        text: 'CompletedBy',
        align: 'left',
        sortable: true,
        value: 'completedBy'
      },
      {
        text: 'Reason',
        align: 'left',
        sortable: true,
        value: 'reason'
      },
      {
        text: 'Status',
        align: 'left',
        sortable: true,
        value: 'status'
      },
      {
        text: 'Actions',
        align: 'left',
        sortable: true,
        value: 'actions'
      }
    ],
    displayApproveDialog: false,
    displayDenyDialog: false,
    reason: null,
    currentId: ''
  }),
  methods: {
    getDate: function (date) {
      return moment(date).fromNow()
    },
    getEnvironmentName: function (environmentId) {
      if (this.environments) {
        var environment = this.environments.filter(x => {
          return x.id === environmentId
        })

        if (environment != null && environment[0]) {
          return environment[0].displayName
        }
      }
      return 'Unknown'
    },
    getUserDetails: async function (username) {
      return axios(
        `${process.env.VUE_APP_BASENETWORKAPIURL}/activedirectory/search/user?searchText=${username}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getUserDetails() })
          })
    },
    approveDeploymentConfirmation: function (deploymentId) {
      this.reason = null
      this.currentId = deploymentId
      this.displayApproveDialog = true
    },
    denyDeploymentConfirmation: function (deploymentId) {
      this.reason = null
      this.currentId = deploymentId
      this.displayDenyDialog = true
    },
    approveDeployment: function () {
      this.displayApproveDialog = false
      this.currentlyApproving.push(this.currentId)
      return axios(
        `${process.env.VUE_APP_BASEURL}/deployments/${this.currentId}/approve?reason=${this.reason}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.currentlyApproving = this.currentlyApproving.filter(x => {
              return x !== this.currentId
            })
            this.getPendingDeployments()
            this.getCompletedDeployments()
            this.currentId = null
            this.reason = null
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.approveDeployment() })
            this.currentlyApproving = this.currentlyApproving.filter(x => {
              return x !== this.currentId
            })
          })
    },
    denyDeployment: function () {
      this.displayDenyDialog = false
      this.currentlyDenying.push(this.currentId)
      return axios(
        `${process.env.VUE_APP_BASEURL}/deployments/${this.currentId}/deny?reason=${this.reason}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.currentlyDenying = this.currentlyDenying.filter(x => {
              return x !== this.currentId
            })
            this.getPendingDeployments()
            this.getCompletedDeployments()
            this.currentId = null
            this.reason = null
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.denyDeployment() })
            this.currentlyDenying = this.currentlyDenying.filter(x => {
              return x !== this.currentId
            })
          })
    },
    getPendingDeployments: function () {
      this.loadingDeployments = true
      return axios(
        `${process.env.VUE_APP_BASEURL}/admin/deployments/pending`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.deployments = response.data
            this.deployments.forEach(x => {
              this.getUserDetails(x.initiatedBy).then((y) => {
                if (y && y[0] && typeof y[0] === 'object' && Object.prototype.hasOwnProperty(y[0], 'Value')) {
                  x.initiatedBy = y[0].Value
                }
              })
            })
            this.loadingDeployments = false
          },
          (error) => {
            this.loadingDeployments = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getPendingDeployments() })
          })
    },
    getCompletedDeployments: function () {
      this.loadingCompletedDeployments = true
      return axios(
        `${process.env.VUE_APP_BASEURL}/admin/deployments/completed`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.completedDeployments = response.data
            this.completedDeployments.forEach(x => {
              this.getUserDetails(x.completedBy).then((y) => {
                if (y && y[0] && typeof y[0] === 'object' && Object.prototype.hasOwnProperty(y[0], 'Value')) {
                  x.completedBy = y[0].Value
                }
              })
            })
            this.loadingCompletedDeployments = false
          },
          (error) => {
            this.loadingCompletedDeployments = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getCompletedDeployments() })
          })
    },
    getEnvironments: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/environments?internetAccessible=true`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.environments = response.data
            return this.environments
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getEnvironments() })
          })
    }
  },
  mounted: function () {
    this.getEnvironments().then(() => {
      this.getPendingDeployments()
      this.getCompletedDeployments()
    })
  }
}
</script>

<style scoped>

</style>
