<template>
     <v-autocomplete
        v-model="model"
        :rules="rules"
        :items="groupSearchResults"
        :loading="groupSearchLoading"
        :search-input.sync="groupSearchSync"
        :label="label"
        placeholder="Start typing a group name"
        :disabled="disabled"
        clearable
    >
    </v-autocomplete>
</template>

<script>
import axios from 'axios'

export default {
  name: 'group-search-component',
  props: ['value', 'disabled', 'rules', 'label'],
  watch: {
    groupSearchSync: {
      handler () {
        if (this.groupSearchSync) {
          if (this.model && this.groupSearchSync === this.model) {
            return
          }
          clearTimeout(this.groupSeachTimeout)

          // Make a new timeout set to go off in 800ms
          this.groupSeachTimeout = setTimeout(() => {
            this.searchForgroup()
          }, 500)
        } else {
          this.groupSearchResults = [ ]
          this.model = ''
        }
      }
    },
    model: {
      handler () {
        this.$nextTick(() => {
          this.model = null
        })
      }
    }
  },
  computed: {
    model: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  data () {
    return {
      groupSearchResults: [],
      groupSearchLoading: false,
      groupSeachTimeout: null,
      groupSearchSync: null
    }
  },
  methods: {
    searchForgroup () {
      this.groupSearchLoading = true
      axios(`${process.env.VUE_APP_BASENETWORKAPIURL}/activedirectory/search/group?searchText=${this.groupSearchSync}`, { method: 'GET', params: { } })
        .then((response) => {
          this.groupSearchLoading = false
          this.groupSearchResults = response.data
        }, (error) => {
          if (error) {
            this.groupSearchLoading = false
          }
        })
    }
  },
  beforeMount () {
    if (this.value) {
      this.groupSearchResults = [this.value]
    }
  }
}
</script>

<style>
</style>
