<template>
  <div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Callback',
  data: () => ({
  }),
  methods: {
    ...mapActions([
      'oidcSignInCallback'
    ])
  },
  mounted: function () {
    this.oidcSignInCallback()
      .then((redirectPath) => {
        this.$router.push(redirectPath)
      })
      .catch((err) => {
        if (err) {
          this.$router.push('/') // Handle errors any way you want
        }
      })
  }
}
</script>

<style scoped>
</style>
