<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h5">Admin Actions:</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span class="text-subtitle-1">The following action buttons perform a corrective action on data elements in the marketplace. Do not select these unless instructed to do so by the Marketplace Product Owner</span>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col="12" md="12">
        <v-btn style="width: 260px" :disabled="isUpdatingCounts" :loading="isUpdatingCounts" @click="updateApiCounts">Update Product API Counts</v-btn>
      </v-col>
      <v-col col="12" md="12">
        <v-btn style="width: 260px" :disabled="isUpdatingNames" :loading="isUpdatingNames" @click="updateTeamNames">Update Product Team Names</v-btn>
      </v-col>
      <v-col col="12" md="12">
        <v-btn style="width: 260px" :disabled="isUpdatingTeamAffiliates" :loading="isUpdatingTeamAffiliates" @click="updateTeamAffiliates">Update Team Affiliates</v-btn>
      </v-col>
      <v-col col="12" md="12">
        <v-btn style="width: 260px" :disabled="isUpdatingProductAffiliates" :loading="isUpdatingProductAffiliates" @click="updateProductAffiliates">Update Product Affiliates</v-btn>
      </v-col>
      <v-col col="12" md="12">
        <v-btn style="width: 260px" :disabled="isUpdatingRepositoryUrls" :loading="isUpdatingRepositoryUrls" @click="updateRepositoryUrls">Update Repository URL's</v-btn>
      </v-col>
      <v-col col="12" md="12">
        <v-btn style="width: 260px" :disabled="isUpdatingApiAndApiRevisionIsDeletedStatus" :loading="isUpdatingApiAndApiRevisionIsDeletedStatus" @click="confirmCorrectiveAction(updateApiAndApiRevisionsIsDeletedStatus)">Sync Api/ApiRevision isDeleted</v-btn>
      </v-col>
      <v-col col="12" md="12">
        <v-btn style="width: 260px" :disabled="isUpdatingNugetV3Urls" :loading="isUpdatingNugetV3Urls" @click="confirmCorrectiveAction(updateNugetV3Urls)">Update Nuget V3 Repo URL's</v-btn>
      </v-col>
    </v-row>
</v-container>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'admin-settings',
  computed: {
    ...mapGetters(['adminSettings'])
  },
  data: () => ({
    isLoading: false,
    isUpdatingCounts: false,
    isUpdatingNames: false,
    isUpdatingTeamAffiliates: false,
    isUpdatingProductAffiliates: false,
    isUpdatingRepositoryUrls: false,
    isUpdatingApiAndApiRevisionIsDeletedStatus: false,
    isUpdatingNugetV3Urls: false
  }),
  methods: {
    confirmCorrectiveAction: function (action) {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to run this action?',
        confirmMethod: () => {
          action()
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    updateAdminSettings: function () {
      this.isLoading = true
      this.$store.dispatch(
        {
          type: 'updateAdminSettings',
          admin: this.adminSettings
        })
        .then(() => {
          this.isLoading = false
          this.$store.commit('triggerSnack', { snackMessage: 'The admin settings have been saved successfully', snackColor: 'success' })
        })
    },
    updateApiCounts: function () {
      this.isUpdatingCounts = true
      axios(
        `${process.env.VUE_APP_BASEURL}/product/update/counts`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isUpdatingCounts = false
          },
          (error) => {
            this.isUpdatingCounts = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.updateApiCounts() })
            return error
          })
    },
    updateTeamNames: function () {
      this.isUpdatingNames = true
      axios(
        `${process.env.VUE_APP_BASEURL}/product/update/teamNames`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isUpdatingNames = false
          },
          (error) => {
            this.isUpdatingNames = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.updateApiCounts() })
            return error
          })
    },
    updateTeamAffiliates: function () {
      this.isUpdatingTeamAffiliates = true
      axios(
        `${process.env.VUE_APP_BASEURL}/teams/update/affiliates`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isUpdatingTeamAffiliates = false
          },
          (error) => {
            this.isUpdatingTeamAffiliates = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.updateApiCounts() })
            return error
          })
    },
    updateProductAffiliates: function () {
      this.isUpdatingProductAffiliates = true
      axios(
        `${process.env.VUE_APP_BASEURL}/product/update/affiliates`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isUpdatingProductAffiliates = false
          },
          (error) => {
            this.isUpdatingProductAffiliates = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.updateApiCounts() })
            return error
          })
    },
    updateRepositoryUrls: function () {
      this.isUpdatingRepositoryUrls = true
      axios(
        `${process.env.VUE_APP_REPO_BASEURL}/repositories/update/repositoryurls`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isUpdatingRepositoryUrls = false
          },
          (error) => {
            this.isUpdatingRepositoryUrls = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.updateRepositoryUrls() })
            return error
          })
    },
    updateApiAndApiRevisionsIsDeletedStatus: function () {
      this.isUpdatingApiAndApiRevisionIsDeletedStatus = true
      axios(
        `${process.env.VUE_APP_BASEURL}/api/update/apiandapirevisionsisdeletedstatus`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isUpdatingApiAndApiRevisionIsDeletedStatus = false
          },
          (error) => {
            this.isUpdatingApiAndApiRevisionIsDeletedStatus = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.updateApiAndApiRevisionsIsDeletedStatus() })
            return error
          })
    },
    updateNugetV3Urls: function () {
      this.isUpdatingNugetV3Urls = true
      axios(
        `${process.env.VUE_APP_REPO_BASEURL}/repositories/update/nugetv3urls`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isUpdatingNugetV3Urls = false
          },
          (error) => {
            this.isUpdatingNugetV3Urls = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.isUpdatingNugetV3Urls() })
            return error
          })
    }

  }
}
</script>

<style scoped>

</style>
