<template>
<v-row class="mt-2">
    <v-col cols="12" md="12">
    <v-row>
      <v-col cols="12" md="12" v-if="adminSettings && (adminSettings.enableProductCreation || adminSettings.isSuperUser)">
        <v-btn color="primary" :to="getProductBuilderLink">Create a Product</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="teamProducts">
        <v-row align="center" justify="start">
          <v-card
              class="ma-2 pa-1"
              hover
              ripple
              v-for="(product, index) in teamProducts" :key="index"
              max-width="344"
              min-width="344"
              max-height="115"
              min-height="115"
              :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
              @click="routeToProduct(product.id)"
          >
            <v-card-title class="card-title text-no-wrap text-truncate">{{ product.displayName }}</v-card-title>
            <v-card-subtitle class="text-no-wrap text-truncate">{{ removeMarkdown(product) }}</v-card-subtitle>
            <!--<v-list-item>
              <v-list-item-content>
                <v-list-item-title class="api-name">{{ product.displayName }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>-->
          </v-card>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="teamProducts.length === 0">
        No Products found for this team. Use the 'Create a Product' button to build one
      </v-col>
    </v-row>
    </v-col>
</v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import removeMd from 'remove-markdown'

export default {
  name: 'team-products',
  computed: {
    ...mapGetters(['adminSettings']),
    ...mapGetters('teams', ['teamProducts', 'teamId']),
    getProductBuilderLink: function () {
      return `/product-builder?teamId=${this.teamId}`
    }
  },
  watch: {
  },
  data: () => ({
  }),
  methods: {
    routeToProduct: function (id) {
      this.$router.push(`/products/${id}/settings`)
    },
    removeMarkdown: function (product) {
      return removeMd(product.description)
    }
  },
  mounted: function () {
  }
}
</script>

<style scoped>
  .card-title {
    display: inline-block;
    max-width: 340px
  }
</style>
