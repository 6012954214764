<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" id="v-step-0">
        <h1 class="text-center" style="font-size: 36px">The Marketplace</h1>
        <p class="text-center">The Evolve Marketplace lets HCA developers find consumable apis, packages, and other assets to use in their development.
          <br/>Select an option below to find reusable components or view our <a href="/learning-center">Learning Center</a>.
        </p>
      </v-col>
    </v-row>

    <v-divider/>

    <v-row justify="center" class="main-app-view">
      <v-col cols="12" lg="6" md="6">
        <v-row>
          <v-col>
            <v-card class="mx-auto" hover ripple to="apis" height="110" :color="$vuetify.theme.dark ? 'grey darken-4' : ''">
              <v-list-item three-line>
                <v-list-item-avatar tile size="75">
                  <v-img contain src="@/assets/api.svg"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="headline mb-1">APIs</v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-1 line-height">Discover and subscribe to HCA's APIs and interact with different products throughout HCA's business lines</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card class="mx-auto" hover ripple to="packages" height="110" :color="$vuetify.theme.dark ? 'grey darken-4' : ''">
              <v-list-item three-line>
                <v-list-item-avatar tile size="75">
                  <v-img contain src="@/assets/package.svg"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="headline mb-1">Packages</v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-1 line-height">Access and discover HCA innersourced packages and utilize them within your code base</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card class="mx-auto" hover ripple to="learning-center" height="110" :color="$vuetify.theme.dark ? 'grey darken-4' : ''">
              <v-list-item three-line>
                <v-list-item-avatar tile size="75">
                  <v-img contain src="@/assets/learningCenter.svg"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="headline mb-1">Learning Center</v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-1 line-height">Access Evolve Marketplace Confluence labs to learn how to utilze the marketplace effectively</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" lg="6" md="6">
        <v-row>
          <v-col>
            <v-card class="mx-auto" hover ripple to="templates" height="110" :color="$vuetify.theme.dark ? 'grey darken-4' : ''">
              <v-list-item three-line>
                <v-list-item-avatar tile size="75">
                  <v-img contain src="@/assets/template.svg"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="headline mb-1">Templates</v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-1 line-height">Access Evolve Templates, approved and reusable github actions, cloud modules, code snippits, and other assets</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card class="mx-auto" hover ripple to="colleges" height="110" :color="$vuetify.theme.dark ? 'grey darken-4' : ''">
              <v-list-item three-line>
                <v-list-item-avatar tile size="75">
                  <v-img contain src="@/assets/school-building2.svg"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="headline mb-1">University</v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-1 line-height">Visit Evolve University to find courses for developer education</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card class="mx-auto" hover ripple to="badges" height="110" :color="$vuetify.theme.dark ? 'grey darken-4' : ''">
              <v-list-item three-line>
                <v-list-item-avatar tile size="75">
                  <v-img contain src="@/assets/award1.svg"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="headline mb-1">Badges</v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-1 line-height">View HCA's awardable badges for education and events</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'home',
  watch: {
    search: function () {
      clearTimeout(this.searchTimeout)

      // Make a new timeout set to go off in 800ms
      this.searchTimeout = setTimeout(() => {
        // Insert search call here
      }, 500)
    }
  },

  computed: {
    ...mapGetters(['adminSettings', 'oidcUser', 'oidcIsAuthenticated'])
  },

  data: () => ({
    searchTimeout: null,
    search: '',
    apis: [ ],
    isApiLoading: false
  }),

  mounted: function () {
    this.$store.commit('breadcrumbs', [])
  }
}
</script>

<style scoped>
.line-height {
  line-height: 18pt;
}

.main-app-view {
  margin-top: 15px;
}
</style>
