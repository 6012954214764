<template>
  <v-container>
    <v-row>
      <v-col>
        <v-alert
          dense
          outlined
          type="info"
        >
          Settings updated on this tab will only take affect on this version
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span class="text-h5">Basic Template Settings</span>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center">
      <v-col cols="12" md="6">
        <v-select :items="releases" item-text="release.releaseId" item-value="release.releaseId" v-model="selectedImportReleaseName" label="Import settings for existing release" />
      </v-col>
      <v-col>
        <v-btn @click="copyFromExistingRelease">Import Settings</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-switch v-model="releaseDetails.release.isPublished" label="Publish This Release" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span class="text-h5">Terraform Template Settings</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select :rules="selectRequired" :items="systems" v-model="releaseDetails.release.settings.system" label="Terraform system" hint="This system represents what provider this module will utilize" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'terraform-settings-component',
  props: {
    template: {
      required: true
    },
    releaseDetails: {
      required: true
    },
    releases: {
      required: true
    }
  },
  data: () => ({
    selectedImportReleaseName: null,
    validated: true,
    required: [
      v => !!v || 'This field is required'
    ],
    selectRequired: [
      v => v != null || 'This field is required'
    ],
    systems: [
      'azurerm',
      'aws',
      'gcp'
    ]
  }),
  methods: {
    copyFromExistingRelease: function () {
      let releaseFound = this.releases.find(x => x.release.releaseId === this.selectedImportReleaseName)
      if (releaseFound) {
        let settings = JSON.parse(JSON.stringify(releaseFound.release.settings))
        this.releaseDetails.release.settings = settings
      } else {
        this.releaseDetails.release.settings = {
          templateType: 2,
          system: 'azurerm'
        }
      }
    },
    validate: function () {
      this.validated = true
      if (!this.$refs.variableForm.validate()) {
        this.validated = false
      }
      return this.validated
    }
  },
  beforeMount: function () {
    if (!this.releaseDetails.release.settings) {
      this.releaseDetails.release.settings = {
        templateType: 2,
        system: 'azurerm'
      }
    }
  }
}
</script>

<style scoped>

</style>
