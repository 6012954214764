<template>
  <v-container>
      <v-row>
        <v-col cols="12" md="12" id="v-step-0">
            <h1>{{ product.displayName }}</h1>
            <v-skeleton-loader
              v-if="!product.displayName"
              class="mx-auto"
              min-width="500"
              type="heading"
            ></v-skeleton-loader>
        </v-col>
      </v-row>
    <v-divider/>
    <v-row>
        <v-col>
            <h2>Product Details:</h2>
        </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-card
          :loading="isSubmitted"
          class="ap-2"
        >
          <template v-slot:progress>
            <v-progress-linear
              absolute
              color="green lighten-3"
              height="4"
              indeterminate>
            </v-progress-linear>
          </template>
          <v-form ref="form">
            <v-container>
              <v-row id="v-step-1">
                <v-col cols="12" md="6">
                  <p><span class="item-header">Product Team:</span>
                  <ol>
                    <template v-for="(item, index) in allowedUsers">
                      <li v-if="typeof item === 'object' && Object.prototype.hasOwnProperty.call(item, 'Value')" :key="index">{{ item.Value }}</li>
                    </template>
                  </ol>
                  </p>
                  <p><span class="item-header">Product Description:</span></p>
                  <div v-html="compiledMarkdown(product.description)"></div>
                  <p><span class="item-header">Product Base Url Path:</span> {{product.baseUrlPath }}</p>
                  <p><span class="item-header">Product Apigee ID:</span> {{ product.apigeeProductGuid }}</p>
                  <p><span class="item-header">Product Apigee Name:</span> {{product.name }}</p>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-divider/>
    <v-row>
      <v-col cols="12" md="12">
      <v-tabs v-model="tabView" dark background-color="lighten-2">
        <v-tab id="v-step-10">
          Product Apis
        </v-tab>
        <v-tab id="v-step-11">
          Product Subscriptions
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabView">
        <v-tab-item>
          <v-container>
            <v-row>
              <v-col cols="12" v-if="apis">
                <v-row align="center" justify="start">
                  <v-card
                      class="ma-2 pa-1"
                      hover
                      ripple
                      v-for="(api, index) in apis" :key="index"
                      max-width="344"
                      min-width="344"
                      :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
                      @click="routeToApiPublicView(api.id)"
                  >
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="api-name">{{ api.displayName }}</v-list-item-title>
                        <v-list-item-subtitle>{{ api.version }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-row>
              </v-col>
              <v-col cols="12" v-if="apis.length === 0">
                No subscribable APIs found for this product.
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container>
            <v-row>
              <v-col cols="12" v-if="groupedApiSubscriptions">
                <v-row align="center" justify="start">
                  <v-card
                          class="ma-2 pa-1"
                          hover
                          ripple
                          v-for="(api, index) in groupedApiSubscriptions" :key="index"
                          max-width="350"
                          min-width="350"
                          :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
                          @click="routeToApiPublicView(api[0].apiId)"
                      >
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title class="headline api-name">{{ index }}</v-list-item-title>
                            <v-list-item-subtitle class="d-flex flex-wrap">
                              <template v-for="subscription in api">
                                <v-tooltip bottom :key="subscription.subscriptionId">
                                  <template v-slot:activator="{ on }">
                                    <v-chip
                                      dark
                                      :color="subscription.subscriptionStatus === 'Approved' || subscription.subscriptionStatus === 0 ? '#4CAF50' : '#FF9800'"
                                      v-on="on"
                                      class="subscription-environment"
                                    >
                                      {{ subscription.environmentName}}
                                    </v-chip>
                                  </template>
                                  <span>{{ subscription.subscriptionStatus === 'Approved' || subscription.subscriptionStatus === 0 ? 'Subscription Approved' : 'Subscription Pending' }}</span>
                                </v-tooltip>
                                </template>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                </v-row>
              </v-col>
              <v-col cols="12" v-if="!product.subscriptions || product.subscriptions.length === 0">
                This product is not subscribed to any APIs
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import marked from 'marked'
import { mapGetters } from 'vuex'

export default {
  name: 'Products',
  computed: {
    ...mapGetters(['adminSettings', 'breadcrumbs']),
    groupedApiSubscriptions: function () {
      if (this.product.subscriptions) {
        let subscriptions = this.product.subscriptions.filter(x => {
          return x.apiName !== null
        }).reduce((acc, obj) => {
          let key = obj['apiName']
          if (!acc[key]) {
            acc[key] = []
          }
          acc[key].push(obj)
          return acc
        }, {})
        return subscriptions
      } else {
        return [ ]
      }
    }
  },
  data: () => ({
    tabView: 0,
    allowedUsers: [ ],
    environments: [ ],
    productId: '',
    userError: false,
    isSubmitted: false,
    product: {
      displayName: '',
      description: '',
      allowedUsers: [ ],
      subscriptions: [ ]
    },
    apis: []
  }),
  methods: {
    routeToApiPublicView: function (id) {
      this.$router.push(`/api-view/${id}`)
    },
    routeToProductPublicView: function (id) {
      this.$router.push(`/product-view/${id}`)
    },
    compiledMarkdown: function (input) {
      if (input !== null) {
        return marked(input, { sanitize: true })
      } else {
        return null
      }
    },
    getApis: async function () {
      await axios(
        `${process.env.VUE_APP_BASEURL}/product/${this.productId}/apis?publicView=true`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          async (response) => {
            this.apis = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getApis() })
          })
    },
    getUserDetails: function (username) {
      return axios(
        `${process.env.VUE_APP_BASENETWORKAPIURL}/activedirectory/search/user?searchText=${username}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getUserDetails() })
          })
    },
    getProduct: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/product/${this.productId}?publicView=true`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            var res = response.data

            this.product = res
            if (this.product.allowedUsers) {
              this.allowedUsers = [ ]
              this.product.allowedUsers.forEach(x => {
                this.getUserDetails(x).then(x => {
                  if (x) {
                    this.allowedUsers.push(x[0])
                  }
                })
              })
            }
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getProduct() })
          })
    }
  },

  mounted: function () {
    if (this.$route.params.id) {
      this.productId = this.$route.params.id
      this.getProduct().then(() => {
        this.getApis()
        if (this.breadcrumbs.length > 0) {
          this.$store.commit('addBreadcrumbs', [
            {
              text: 'Product',
              disabled: true
            },
            {
              text: this.product.displayName,
              exact: true,
              disabled: false,
              to: `/product-view/${this.productId}`
            }
          ])
        } else {
          this.$store.commit('breadcrumbs', [
            {
              text: 'Product',
              disabled: true
            },
            {
              text: this.product.displayName,
              exact: true,
              disabled: false,
              to: `/product-view/${this.productId}`
            }
          ])
        }
      })
    } else {
      alert('could not find product as an ID was not provided in the url')
    }
  }
}
</script>

<style scoped>
  .item-header {
    font-weight: bold;
  }

</style>
