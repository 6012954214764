<template>
  <v-row class="mt-2">
    <v-col>
      <v-row>
        <v-col cols="12" md="12" v-if="adminSettings && (adminSettings.enableRepositoryFeatures || adminSettings.isSuperUser)">
          <v-btn color="primary" :to="repositoryBuilderLink">Create a Repository</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-if="teamRepositories">
          <v-row align="center" justify="start">
            <v-card
              class="ma-2 pa-1"
              hover
              ripple
              v-for="(repo, index) in teamRepositories" :key="index"
              max-width="344"
              min-width="344"
              max-height="115"
              min-height="115"
              :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
              :to="generateRepoLink(repo.id)"
            >
              <v-card-title class="card-title text-no-wrap text-truncate">{{ repo.displayName }}</v-card-title>
              <v-card-subtitle class="text-no-wrap text-truncate"><v-chip dark :color="generateColor(repo)">{{ generateText(repo) }}</v-chip></v-card-subtitle>
            </v-card>
          </v-row>
        </v-col>
        <v-col cols="12" v-if="teamRepositories.length === 0">
          No Repositories found for this team. Use the 'Create a Repository' button to build one
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'team-repositories',
  computed: {
    ...mapGetters(['adminSettings']),
    ...mapGetters('teams', ['teamRepositories', 'teamId']),
    repositoryBuilderLink: function () {
      return `/teams/${this.teamId}/repositories/builder`
    }
  },
  watch: {
  },
  data: () => ({
    repositoryType: [
      {
        key: 'Nuget',
        color: '#546E7A',
        value: 0
      },
      {
        key: 'NPM',
        color: '#0277BD',
        value: 1
      },
      {
        key: 'Maven',
        color: '#00838F',
        value: 2
      },
      {
        key: 'Docker',
        color: '#757575',
        value: 3
      },
      {
        key: 'Pypi',
        color: '#37474F',
        value: 4
      },
      {
        key: 'Bower',
        color: '#558B2F',
        value: 5
      },
      {
        key: 'Helm',
        color: '#FF6F00',
        value: 6
      },
      {
        key: 'Raw',
        color: '#FF8F00',
        value: 7
      }
    ]
  }),
  methods: {
    generateColor: function (repo) {
      var type = this.repositoryType.find(x => x.value === repo.repositoryType)

      if (type) {
        return type.color
      } else {
        return '#757575'
      }
    },
    generateText: function (repo) {
      var type = this.repositoryType.find(x => x.value === repo.repositoryType)
      if (type) {
        return type.key
      } else {
        return 'Unknown'
      }
    },
    generateRepoLink: function (repoId) {
      return `/teams/${this.teamId}/repositories/${repoId}`
    }
  },
  mounted: function () {
  }
}
</script>

<style scoped>
  .card-title {
    display: inline-block;
    max-width: 340px
  }
</style>
