<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h5">Feature Flags</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-alert
          dense
          text
          type="warning"
        >
          Warning. Please edit feature flags with caution and a purpose.
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col col="12" md="6">
        <v-switch
            v-model="adminSettings.showBannerMessage"
            label="Show Banner Message"
          ></v-switch>
      </v-col>
      <v-col col="12" md="6">
        <v-switch
            v-model="adminSettings.enableProductCreation"
            label="Allow Product Creation"
          ></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col col="12" md="6">
        <v-switch
            v-model="adminSettings.enableApiCreation"
            label="Allow API Creation"
          ></v-switch>
      </v-col>
      <v-col col="12" md="6">
        <v-switch
            v-model="adminSettings.enableExistingApiSubscriptions"
            label="Allow New Subscriptions to Existing APIs"
          ></v-switch>
      </v-col>
      <v-col col="12" md="6">
        <v-switch
            v-model="adminSettings.enableExistingTeamSave"
            label="Allow Editing of Teams"
          ></v-switch>
      </v-col>
      <v-col col="12" md="6">
        <v-switch
            v-model="adminSettings.enableTeamCreation"
            label="Allow Creation of Teams"
          ></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col col="12" md="6">
        <v-switch
            v-model="adminSettings.enableExistingApiDeployment"
            label="Allow Deloyment of APIs"
          ></v-switch>
      </v-col>
      <v-col col="12" md="6">
        <v-switch
            v-model="adminSettings.enableExistingApiSave"
            label="Allow Updates of Existing APIs"
          ></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col col="12" md="6">
        <v-switch
            v-model="adminSettings.enableExistingProductSave"
            label="Allow Updates to Products"
          ></v-switch>
      </v-col>
      <v-col col="12" md="6">
        <v-switch
            v-model="adminSettings.enableRegerationOfTokens"
            label="Allow Regeneration of Consumer Keys/Secrets"
          ></v-switch>
      </v-col>
      <v-col col="12" md="6">
        <v-switch
            v-model="adminSettings.enableRepositoryFeatures"
            label="Allow Repository Creation/Updates"
          ></v-switch>
      </v-col>
      <v-col col="12" md="6">
        <v-switch
            v-model="adminSettings.enableTestCreation"
            label="Allow Test Creation/Updates"
          ></v-switch>
      </v-col>
      <v-col col="12" md="6">
        <v-switch
            v-model="adminSettings.enableEmailNotifications"
            label="Enable Email Notifications"
          ></v-switch>
      </v-col>
      <v-col col="12" md="6">
        <v-switch
            v-model="adminSettings.enableBadges"
            label="Enable Evolve Badges"
          ></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col col="12" md="6">
        <v-switch
            v-model="adminSettings.enableUniversity"
            label="Enable Evolve University"
          ></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="1">
        <v-btn color="primary" @click="updateAdminSettings" :loading="isLoading">Update</v-btn>
      </v-col>
    </v-row>
</v-container>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'admin-settings',
  computed: {
    ...mapGetters(['adminSettings'])
  },
  data: () => ({
    isLoading: false
  }),
  methods: {
    updateAdminSettings: function () {
      this.isLoading = true
      this.$store.dispatch(
        {
          type: 'updateAdminSettings',
          admin: this.adminSettings
        })
        .then(() => {
          this.isLoading = false
          this.$store.commit('triggerSnack', { snackMessage: 'The admin settings have been saved successfully', snackColor: 'success' })
        })
    }
  }
}
</script>

<style scoped>

</style>
