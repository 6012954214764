<template>
  <v-container>
      <v-row justify="center">
        <v-col cols="12" md="6" id="v-step-0">
          <h1>Evolve University</h1>
          <v-icon color="info">mdi-help-circle</v-icon>
          <a class="pl-1" target="_blank" href="https://confluence.app.medcity.net/c/display/EM/Evolve+Marketplace">Need help using Evolve University</a>
        </v-col>
        <v-spacer />
        <v-col cols="12" md="2" id="v-step-1" v-if="hasAdminRole">
          <v-btn @click="showCollegeBuilder=true" dark color="primary" v-if="hasAdminRole">Create a College</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="11" sm="11" md="6" id="v-step-1" class="mr-0 pr-0">
          <v-text-field
            solo
            label="Search college by name or description"
            placeholder=""
            prepend-inner-icon="mdi-magnify"
            clearable
            v-model="search"
            :loading="isLoading"
          >
          </v-text-field>
        </v-col>
      </v-row>
    <v-divider/>
    <v-row>
      <v-col cols="12" v-if="colleges" id="v-step-2">
        <v-row v-if="colleges.length === 0 && isLoading">
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row v-else class="mt-2">
          <v-col cols="12" md="4" v-for="(college, index) in colleges" :key="index">
            <v-card
                hover
                ripple
                :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
                max-width="344"
                min-width="375"
                :to="'/colleges/' + college.id + '/departments'"
            >
                <v-list-item>
                <!-- <v-list-item-avatar color="#971B2F"></v-list-item-avatar> -->
                <v-list-item-content >
                    <v-list-item-title class="headline api-name">{{ college.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ college.description }}</v-list-item-subtitle>
                </v-list-item-content>
                </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="count > 1">
      <v-col>
        <v-pagination
          v-model="page"
          :length="count"
          circle
        ></v-pagination>
      </v-col>
    </v-row>
    <v-dialog v-model="showCollegeBuilder" width="700">
      <college-builder-component @closeModal="showCollegeBuilder=false" />
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import collegeBuilderComponent from './components/collegeBuilderComponent.vue'

export default {
  name: 'Colleges',
  components: {
    'college-builder-component': collegeBuilderComponent
  },
  computed: {
    ...mapGetters(['adminSettings']),
    ...mapGetters(['universityUser']),
    hasAdminRole: {
      get: function () {
        return this.universityUser.roles.find(x => x === 'Admin') !== undefined
      }
    }
  },
  watch: {
    page: function (newValue, oldValue) {
      if (oldValue !== newValue) {
        this.getColleges()
      }
    },
    search: function () {
      clearTimeout(this.searchTimeout)

      // Make a new timeout set to go off in 800ms
      this.searchTimeout = setTimeout(() => {
        if (this.search == null) {
          this.search = ''
        }
        this.page = 1
        this.getColleges()
      }, 500)
    }
  },
  data: () => ({
    searchTimeout: null,
    colleges: [ ],
    isLoading: true,
    page: 1,
    limit: 12,
    count: 0,
    search: '',
    showCollegeBuilder: false
  }),
  methods: {
    getColleges: function () {
      this.isLoading = true
      axios(
        `${process.env.VUE_APP_UNIVERSITYBASEAPIURL}/api/colleges?page=${this.page}&limit=${this.limit}&search=${this.search}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isLoading = false
            this.colleges = response.data.items
            this.count = Math.ceil(response.data.totalCount / this.limit)
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getColleges() })
          })
    },
    routeToCollege: function (id) {
      this.$router.push(`/colleges/${id}`)
    }
  },
  mounted: function () {
    this.getColleges()
    this.$store.commit('breadcrumbs', [
      {
        text: 'Evolve University',
        exact: true,
        disabled: false,
        to: '/colleges'
      }
    ])
  }
}
</script>

<style scoped>

</style>
