<template>
  <v-container>
      <v-row>
        <v-col cols="12" md="12" id="v-step-0">
            <h1>{{ product.displayName }}</h1>
            <v-skeleton-loader
              v-if="!product.displayName"
              class="mx-auto"
              min-width="500"
              type="heading"
            ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-if="isEditDisabled">
      <v-col>
        <v-alert
          border="bottom"
          colored-border
          type="warning"
          elevation="2"
        >
          This product is managed by an external service and cannot be altered through the portal. For help please contact an Evolve administrator
        </v-alert>
      </v-col>
    </v-row>
    <v-divider/>
    <v-row>
        <v-col>
            <h2>Edit Product Details:</h2>
        </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-card
          :loading="isSubmitted"
          class="ap-2"
        >
          <template v-slot:progress>
            <v-progress-linear
              absolute
              color="green lighten-3"
              height="4"
              indeterminate>
            </v-progress-linear>
          </template>
          <v-form ref="form">
            <v-container>
              <v-row id="v-step-1">
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col>
                      <v-text-field v-model="product.displayName" label="Product Display Name" :disabled="isEditDisabled"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-switch label="Product only used to subscribe to APIs" disabled v-model="product.isSubscriberOnly" hint="Select this if your product will only be used to subscribe to APIs. This value can not be changed in the future." persistent-hint />
                    </v-col>
                  </v-row>
                   <v-row v-if="product.isMu3Product && !product.isSubscriberOnly">
                    <v-col>
                      <v-switch label="Product is only used for MU3 External APIs" v-model="product.isMu3Product" disabled hint="Select this if your product will only be used for MU3 External APIs" persistent-hint />
                    </v-col>
                  </v-row>
                  <v-row v-if="!product.isSubscriberOnly">
                    <v-col>
                      <v-text-field disabled readonly v-model="product.baseUrlPath" label="Base URL Path" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        v-model="product.affiliates"
                        :items="affiliates"
                        item-text="displayName"
                        item-value="id"
                        attach
                        chips
                        label="Affiliates (Optional)"
                        multiple
                        :disabled="isEditDisabled"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        v-model="product.teamId"
                        :items="teams"
                        item-text="displayName"
                        item-value="id"
                        label="Team"
                        :rules="teamRules"
                        required
                        :disabled="isEditDisabled"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-textarea v-model="product.description" label="Description (Markdown Supported)" :disabled="isEditDisabled" />
                      <v-btn x-small outlined @click="previewMarkdownDialog = true">Preview Markdown</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12">
                    <user-search-component :disabled="isSubmitted || isEditDisabled" v-model="currentUserSearch" label="Admin Users" />
                    <v-simple-table class="user-table">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">34</th>
                            <th class="text-left">Name</th>
                            <th class="text-left">Delete</th>
                          </tr>
                        </thead>
                        <tbody v-if="allowedUsers && allowedUsers.length > 0">
                          <tr v-for="(user, userIndex) in allowedUsers" :key="'user-' + userIndex">
                            <td>{{user.Key}}</td>
                            <td>{{user.Value}}</td>
                            <td>
                            <v-btn icon @click="deleteUser(user.Key)" :disabled="isEditDisabled"><v-icon>mdi-delete</v-icon></v-btn></td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <group-search-component class="required" id="v-step-5" :disabled="isSubmitted || isEditDisabled" v-model="currentGroupSearch" label="Admin AD Groups (Optional)" />
                      <v-simple-table class="user-table">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Group</th>
                              <th class="text-left">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="group in allowedGroups" :key="group">
                              <td>{{ group }}</td>
                              <v-btn icon @click="deleteGroup(group)" :disabled="isEditDisabled"><v-icon>mdi-delete</v-icon></v-btn>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                    <cmdb-search-component :disabled="isSubmitted || isEditDisabled" v-model="currentCmdbSearch" label=" Linked Service Central CIs" />
                    <v-simple-table class="user-table">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Name</th>
                            <th class="text-left">Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{{ cmdbItem.name }}</td>
                            <td>
                            <v-btn v-if="cmdbItem!=''" icon @click="deleteCmdb(cmdbItem.name)" :disabled="isEditDisabled"><v-icon>mdi-delete</v-icon></v-btn></td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <p><span class="item-header">Product Apigee ID:</span> {{ product.apigeeProductGuid }}</p>
                      <p><span class="item-header">Product Apigee Name:</span> {{product.name }}</p>
                      <v-switch v-if="adminSettings.isCloudAdmin" v-model="product.enableCloudAccountCreation" label="Allow Cloud Account Creation" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-btn class="ma-2" color="info" :loading="isSubmitted" :disabled="isSubmitted || isEditDisabled" @click="update">Update Product</v-btn>
                  <v-btn class="ma-2" :loading="isDeletingProduct" :disabled="isDeletingProduct || isEditDisabled" @click="displayDeleteDialog" color="error">Delete Product</v-btn>
                </v-col>
              </v-row>
              <v-row v-if="!validated || userError">
                <v-col class="text-center">
                  <span v-if="userError" style="color: #ff5252 !important">At least 1 user or group is required</span>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-divider/>
    <v-row>
    </v-row>
      <v-dialog
      v-model="previewMarkdownDialog"
      width="500"
      >
        <v-card>
          <v-card-title
            :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'"
            primary-title
          >
            Markdown Preview
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <div v-html="compiledMarkdown(product.description)"></div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="previewMarkdownDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import marked from 'marked'
import userSearchComponent from '@/components/userSearchComponent'
import groupSearchComponent from '@/components/groupSearchComponent'
import cmdbSearchComponent from '@/components/cmdbSearchComponent'

export default {
  name: 'productSettings',
  components: {
    'user-search-component': userSearchComponent,
    'group-search-component': groupSearchComponent,
    'cmdb-search-component': cmdbSearchComponent
  },
  watch: {
    currentUserSearch: function (newValue, oldValue) {
      if (newValue) {
        if (!this.allowedUsers.find(x => x.Key === newValue.Key)) {
          this.allowedUsers.push(newValue)
        }
      }
    },
    currentCmdbSearch: function (newValue, oldValue) {
      if (newValue) {
        this.cmdbItem = newValue
        this.product.serviceCentralAppInstanceId = this.cmdbItem.sys_id
      }
    },
    currentGroupSearch: function (newValue, oldValue) {
      if (newValue) {
        if (!this.allowedGroups.find(x => x === newValue)) {
          if (newValue.toLowerCase().startsWith('domain')) {
            this.$store.commit('showErrorDialog',
              {
                errorData: {
                  response: {
                    data: {
                      errorMessage: 'You cannot use a Domain Group for this list. Please select a more granular group'
                    }
                  }
                },
                persistErrorDialog: false
              })
          } else {
            this.allowedGroups.push(newValue)
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(['adminSettings']),
    groupedApiSubscriptions: function () {
      if (this.product.subscriptions) {
        let subscriptions = this.product.subscriptions.filter(x => {
          return x.apiName !== null
        }).reduce((acc, obj) => {
          let key = obj['apiName']
          if (!acc[key]) {
            acc[key] = []
          }
          acc[key].push(obj)
          return acc
        }, {})
        return subscriptions
      } else {
        return [ ]
      }
    },
    internalEnvironmentAppKeys: function () {
      let keys = [ ]
      if (this.environments) {
        let envs = this.environments.filter(x => {
          return x.isInternetAccessible === false
        })

        envs.forEach(x => {
          if (this.product && this.product.appKeys) {
            let key = this.product.appKeys.find(y => {
              return y.environmentName === x.name
            })
            x.key = key
            keys.push(x)
          }
        })
      }
      return keys
    },
    externalEnvironmentAppKeys: function () {
      let keys = [ ]
      if (this.environments) {
        let envs = this.environments.filter(x => {
          return x.isInternetAccessible === true
        })

        envs.forEach(x => {
          if (this.product && this.product.appKeys) {
            let key = this.product.appKeys.find(y => {
              return y.environmentName === x.name
            })
            x.key = key
            keys.push(x)
          }
        })
      }
      return keys
    },
    isEditDisabled: function () {
      return this.product.isApiManagedOnly
    }
  },
  data: () => ({
    cmdbName: '',
    cmdbItem: '',
    cloudAccounts: [ ],
    previewMarkdownDialog: false,
    resettingKeys: [ ],
    teams: [ ],
    validated: true,
    generatingToken: false,
    recreatingToken: false,
    copyText: 'Copy to clipboard',
    environments: [ ],
    unsubscriptionDialog: false,
    selectedUnsubscribeApi: { },
    selectedUnsubscribeSubscriptionId: null,
    tabView: 0,
    productId: '',
    userError: false,
    isSubmitted: false,
    isDeletingProduct: false,
    currentUserSearch: null,
    currentGroupSearch: null,
    currentCmdbSearch: null,
    allowedUsers: [ ],
    allowedGroups: [ ],
    product: {
      displayName: '',
      description: '',
      allowedUsers: [ ],
      subscriptions: [ ],
      serviceCentralAppInstanceId: ''
    },
    affiliates: [ ],
    apis: [ ],
    descriptionRules: [
      v => !!v || 'A Description is required',
      v => (v && v.length < 500) || 'The Description must be less than 500 characters'
    ],
    teamRules: [
      v => !!v || 'A Team is required'
    ],
    cloudProviders: [
      {
        value: 0,
        name: 'GCP',
        color: '#FFCDD2'
      },
      {
        value: 1,
        name: 'Azure',
        color: '#E1BEE7'
      },
      {
        value: 2,
        name: 'AWS',
        color: '#C8E6C9'
      }
    ]
  }),
  methods: {
    getProviderColor: function (providerValue) {
      let provider = this.cloudProviders.find(x => x.value === providerValue)
      return provider.color
    },
    getProviderName: function (providerValue) {
      let provider = this.cloudProviders.find(x => x.value === providerValue)
      return provider.name
    },
    compiledMarkdown: function (input) {
      return marked(input, { sanitize: true })
    },
    getMyTeams: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/teams?showOwnedTeamsOnly=true`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.teams = response.data.items
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getAllTeams() })
          })
    },
    resetKeyDialog: function (consumerKey) {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to reset this key? This action cannot be undone. You will need to update the consumer key and secret in all your applications utilizing this Key/Secret',
        confirmMethod: () => {
          this.resetKey(consumerKey)
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    deleteCmdb: function (cmdb) {
      this.cmdbItem = ''
      this.product.serviceCentralAppInstanceId = ''
    },
    resetKey: function (consumerKey) {
      this.recreatingToken = true
      this.resettingKeys.push(consumerKey)
      return axios(
        `${process.env.VUE_APP_BASEURL}/product/${this.productId}/token/${consumerKey}/`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          () => {
            this.getProduct()
            this.recreatingToken = false
            this.resettingKeys = this.resettingKeys.filter(x => {
              return x !== consumerKey
            })
            this.$store.commit('triggerSnack', { snackMessage: 'The token has been revoked and recreated successfully', snackColor: 'success' })
          },
          (error) => {
            this.resettingKeys = this.resettingKeys.filter(x => {
              return x !== consumerKey
            })
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.resetKey(consumerKey) })
          })
    },
    generateToken: function (environmentId) {
      this.generatingToken = true
      return axios(
        `${process.env.VUE_APP_BASEURL}/product/${this.productId}/environments/${environmentId}/token`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.$copyText(response.data.access_token).then(x => {
              this.$store.commit('triggerSnack', { snackMessage: 'The token has been copied to your clipboard', snackColor: 'success' })
              this.generatingToken = false
            }, function (e) {
              this.$store.commit('triggerSnack', { snackMessage: 'Failed to obtain token. Please try again later', snackColor: 'error' })
              this.generatingToken = false
            })
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.generateToken(environmentId) })
            this.generatingToken = false
          })
    },
    copyMouseClick: function () {
      this.copyText = 'Copied!'
    },
    copyMouseLeave: function () {
      setTimeout(x => {
        this.copyText = 'Copy to clipboard'
      }, 200)
    },
    validate: function () {
      this.validated = true
      this.userError = false
      if (this.allowedUsers.length <= 0 && this.allowedGroups.length <= 0) {
        this.userError = true
        this.validated = false
      }
      if (!this.$refs.form.validate()) {
        this.validated = false
      }
      return this.validated
    },
    revokeSubscriptionRequest: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/subscriptions/${this.selectedUnsubscribeSubscriptionId}/revoke`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.product.subscriptions = this.product.subscriptions.filter(x => {
              return x.subscriptionId !== this.selectedUnsubscribeSubscriptionId
            })
            this.selectedUnsubscribeApi = { }
            this.selectedUnsubscribeSubscriptionId = null
            this.unsubscriptionDialog = false
            this.$store.commit('triggerSnack', { snackMessage: 'The subscription has been revoked successfully', snackColor: 'success' })
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.revokeSubscriptionRequest() })
          })
    },
    deleteUser: function (username) {
      this.allowedUsers = this.allowedUsers.filter((item) => {
        return item.Key !== username
      })
    },
    deleteGroup: function (group) {
      this.allowedGroups = this.allowedGroups.filter((item) => {
        return item !== group
      })
    },
    getUserDetails: function (username) {
      return axios(
        `${process.env.VUE_APP_BASENETWORKAPIURL}/activedirectory/search/user?searchText=${username}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getUserDetails() })
          })
    },
    getProduct: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/product/${this.productId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            var res = response.data
            res.appKeys.forEach(x => {
              x.showKeys = false
            })

            this.product = res
            if (this.product.allowedUsers) {
              this.allowedUsers = [ ]
              this.product.allowedUsers.forEach(x => {
                this.getUserDetails(x).then(x => {
                  if (x && x[0]) {
                    this.allowedUsers.push(x[0])
                  }
                })
              })
            }

            if (this.product.allowedGroups) {
              this.allowedGroups = this.product.allowedGroups
            }

            this.product.serviceCentralAppInstanceId = res.serviceCentralAppInstanceId
            if (this.product.serviceCentralAppInstanceId) {
              this.getProductCI()
            }
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getProduct() })
          })
    },
    getProductCI: function () {
      axios(
        `${process.env.VUE_APP_CLOUDBASEURL}/cmdb/${this.product.serviceCentralAppInstanceId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.cmdbItem = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getProductCI() })
          })
    },
    getApis: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/product/${this.productId}/apis`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.apis = response.data
            this.apis.forEach(x => this.getApiHealthAtAGlance(x))
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getApis() })
          })
    },
    getApiHealthAtAGlance: function (api) {
      axios(
        `${process.env.VUE_APP_BASEURL}/tests/api/${api.id}/glance`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            api.health = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getApis() })
          })
    },
    getCloudAccounts: function () {
      axios(
        `${process.env.VUE_APP_CLOUDBASEURL}/cloud/accounts/products/${this.productId}/`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.cloudAccounts = response.data.items
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getCloudAccounts() })
            return error
          })
    },
    routeToApiBuilder: function () {
      this.$router.push(`/products/${this.productId}/builder`)
    },
    routeToApi: function (id) {
      this.$router.push(`/apis/${id}`)
    },
    routeToApiPublicView: function (id) {
      this.$router.push(`/api-view/${id}`)
    },
    update: function () {
      this.isSubmitted = true

      if (!this.validate()) {
        this.isSubmitted = false
        return false
      }

      if (this.allowedUsers) {
        this.product.allowedUsers = [ ]
        this.allowedUsers.forEach(x => {
          this.product.allowedUsers.push(x.Key)
        })
      }

      if (this.allowedGroups) {
        this.product.allowedGroups = this.allowedGroups
      }

      axios(
        `${process.env.VUE_APP_BASEURL}/product/${this.productId}`,
        {
          method: 'PUT',
          data: JSON.stringify(this.product),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isSubmitted = false
            this.getProduct()
            this.$store.commit('triggerSnack', { snackMessage: 'The product has been updated successfully', snackColor: 'success' })
          },
          (error) => {
            this.isSubmitted = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.update() })
            return error
          })
    },
    getEnvironments: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/environments?internetAccessible=true`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.environments = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getEnvironments() })
          })
    },
    getAffiliates: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/affiliates/`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.affiliates = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getAffiliates() })
            return error
          })
    },
    displayDeleteDialog: function () {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to delete this Product? This action can not be undone and will delete all APIs under this product and the product.',
        confirmMethod: () => {
          this.deleteProduct()
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    deleteProduct: function () {
      this.isDeletingProduct = true
      return axios(
        `${process.env.VUE_APP_BASEURL}/product/${this.productId}/`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.$router.push(`/products/`)
          },
          (error) => {
            this.isDeletingProduct = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.deleteProduct() })
          })
    }
  },
  mounted: function () {
    if (this.$route.params.id) {
      this.productId = this.$route.params.id
      this.getProduct().then(() => {
        // this.getCloudAccounts()
        this.$store.commit('breadcrumbs', [
          {
            text: 'My Products',
            exact: true,
            disabled: false,
            to: '/products'
          },
          {
            text: this.product.displayName,
            exact: true,
            disabled: false,
            to: `/products/${this.productId}`
          },
          {
            text: 'settings',
            exact: true,
            disabled: false,
            to: `/products/${this.productId}/settings`
          }
        ])
      })
      this.getMyTeams()
      this.getApis()
      this.getAffiliates()
      this.getEnvironments()
    } else {
      alert('could not find product as an ID was not provided in the url')
    }
  }
}
</script>

<style scoped>
  .user-table {
    border: 1px solid rgba(0, 0, 0, 0.4);
  }
  .key-table {
    /* border: 1px solid rgba(0, 0, 0, 0.4);
    padding: 5px; */
    margin: 2px;
    margin-bottom: 15px;
  }
  .user-error {
    font-size: 12px;
  }

  .api-name {
    margin-bottom: 10px;
  }

  .visible {
    margin-bottom: 8px;
  }

  .generate-token {
    margin-top:15px;
    padding-left: 5px;
    padding-right: 5px;
    min-width: 150px !important;
  }
#v-step-8 {
  max-width: 200px;
}

</style>
