<template>
    <v-card max-height="500px" rounded style="overflow-y: auto;">
      <v-card-title>Course Builder</v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols="12" md="10">
                <v-text-field v-model="course.name" label="Course Name" :rules="nameRules" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="10">
                <v-text-field v-model="course.url" label="Course URL" hint="Include http or https" :rules="urlRules.concat(isValidUrl)" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="10">
                <v-textarea v-model="course.description" label="Description" counter rows="3" :rules="descriptionRules" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="10">
                <v-select
                  v-model="selectedPlatform"
                  :items="items"
                  label="Platform"
                  item-text="text"
                  item-value="value"
                  required
                  :rules="platformRules"
                  dense
                  >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="10">
                <v-text-field v-model="course.estimatedTime" hint="Minutes" label="Approximate Completion Time" :rules="estimateRules" />
              </v-col>
            </v-row>
           </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
         <v-spacer/>
         <v-btn
           text
           @click="closeModal"
         >
           Cancel
         </v-btn>
         <v-btn
           color="primary"
           text
           @click="saveCourse"
           :disabled="isSubmitting"
           :loading="isSubmitting"
         >
           Publish
         </v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'course-builder-component',
  props: {
    course: {
      required: false
    }
  },
  computed: {
    ...mapGetters(['adminSettings']),
    isValidUrl: function () {
      return () => {
        try {
          const u = new URL(this.course.url)
        } catch (e) {
          return 'Enter a valid URL.'
        }
        return true
      }
    },
    selectedPlatform: {
      get () {
        return this.course.type.toString()
      },
      set (value) {
        this.course.type = parseInt(value)
      }
    }
  },
  data: () => ({
    isSubmitting: false,
    validated: true,
    items: [
      { text: 'Pluralsight', value: '0' },
      { text: 'Cloud Academy', value: '1' },
      { text: 'Microsoft Learn', value: '2' },
      { text: 'Udemy', value: '3' },
      { text: 'Healthstream', value: '4' }
    ],
    nameRules: [
      v => !!v || 'Course name is required.',
      v => (v && v.length < 100) || 'must be less than 100 characters'
    ],
    descriptionRules: [
      v => !!v || 'Course description is required.',
      v => (v && v.length < 300) || 'must be less than 300 characters'
    ],
    platformRules: [
      v => !!v || 'Course platform is required.'
    ],
    estimateRules: [
      v => (v && Number.isInteger(parseInt(v)) && parseInt(v) > 0) || 'Course estimate must be a number (in minutes).'
    ],
    urlRules: [
      v => !!v || 'Enter a valid URL.'
    ]
  }),
  methods: {
    validate: function () {
      this.validated = true
      if (!this.$refs.form.validate()) {
        this.validated = false
      }
      return this.validated
    },
    saveCourse: function () {
      this.course.departmentId = this.$route.params.departmentId

      this.isSubmitting = true
      if (!this.$refs.form.validate()) {
        this.isSubmitting = false
        return false
      }

      const method = this.course.id ? 'PUT' : 'POST'
      const appendIfPut = this.course.id ? `/${this.course.id}` : ''

      axios(
        `${process.env.VUE_APP_UNIVERSITYBASEAPIURL}/api/courses` + appendIfPut,
        {
          method: method,
          data: this.course,
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isSubmitting = false
            this.$emit('courseCreated')
          },
          (error) => {
            this.isSubmitting = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.saveCourse() })
            return error
          })
    },
    closeModal: function () {
      this.$emit('closeCourseModal')
    }
  }
}
</script>

<style scoped>

</style>
