<template>
  <v-card>
    <v-card-title>Pulse Bot Surveys</v-card-title>
    <v-divider></v-divider>
    <v-container>
      <v-row no-gutters>
        <v-col cols="9">
          <v-label>Actions</v-label>
        </v-col>
        <v-col cols="4">
          <v-btn color="primary" @click="askSurveyDialog">Ask Survey Question</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <v-container>
      <v-row no-gutters>
        <v-col cols="9">
          <v-label>Results</v-label>
        </v-col>
        <v-col cols="4">
          <v-btn color="primary" @click="downloadSelectedSurveys">Download Results for Selected Surveys</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-progress-circular
      v-if="!loaded"
      class="d-flex mr-auto ml-auto mt-3 mb-3"
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    ></v-progress-circular>
    <v-data-table
      v-if="loaded"
      v-model="selectedSurveys"
      :headers="surveyDateHeaders"
      :items="surveyDates"
      :items-per-page="10"
      item-key="surveyId"
      show-select
      :single-select="false"
      class="elevation-1"
    >
      <template v-slot:item.toggle="{ item }">
        <v-switch
          @mouseup="toggleSurvey(item)"
          v-model="item.isClosed"
        ></v-switch>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          @click="askDeleteSurvey(item)"
        >
          mdi-delete
        </v-icon>
        <!--<span v-if="item.isClosed === true" @click="toggleSurvey(item)"><v-btn>Open Survey</v-btn></span>-->
        <!--<span v-if="item.isClosed === false" @click="toggleSurvey(item)"><v-btn>Close Survey</v-btn></span>-->
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'pulseBot',
  data: () => ({
    surveyDates: [],
    loaded: false,
    surveyDateHeaders: [
      {
        text: 'Survey Date',
        align: 'left',
        sortable: true,
        value: 'date'
      },
      {
        text: 'Closed',
        align: 'left',
        sortable: false,
        value: 'toggle'
      },
      {
        text: 'Actions',
        align: 'left',
        sortable: false,
        value: 'actions'
      }
    ],
    selectedSurveys: [],
    surveys: null
  }),
  methods: {
    askDeleteSurvey: function (survey) {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to delete the survey on ' + survey?.date + '?',
        confirmMethod: () => {
          this.deleteSurvey(survey)
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    askSurveyDialog: function () {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to create a survey?',
        confirmMethod: () => {
          this.createSurvey()
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    createSurvey: function (item) {
      axios(
        `${process.env.VUE_APP_BASEURL}/pulsesurvey/ask`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.getSurveys()
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.createSurvey() })
            return error
          })
    },
    deleteSurvey: function (survey) {
      axios(
        `${process.env.VUE_APP_BASEURL}/pulsesurvey/delete`,
        {
          method: 'DELETE',
          data: survey,
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.getSurveys()
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.deleteSurvey() })
            return error
          })
    },
    toggleSurvey: function (survey) {
      axios(
        `${process.env.VUE_APP_BASEURL}/pulsesurvey/toggle`,
        {
          method: 'PUT',
          data: survey,
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.getSurveys()
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.deleteSurvey() })
            return error
          })
    },
    downloadSelectedSurveys: function (item) {
      return axios(
        `${process.env.VUE_APP_BASEURL}/pulsesurvey/download`,
        {
          method: 'POST',
          data: this.selectedSurveys,
          headers: { 'Content-Type': 'application/json',
            'Accept': 'text/csv' }
        })
        .then(
          (response) => {
            const link = document.createElement('a')
            const dateString = new Date().toLocaleString()
            link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data)
            link.target = '_blank'
            link.setAttribute('download', `surveys-answers${dateString}.csv`)
            document.body.appendChild(link)
            link.click()
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.downloadSelectedSurveys() })
          })
    },
    getSurveys: async function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/pulsesurvey/list`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.surveys = response.data
            this.surveyDates = response.data.map(survey => {
              let obj = { ...survey }
              obj.date = moment(survey.submittedTime).format('dddd, MMM Do YYYY h:mm a')
              return obj
            })
            this.loaded = true
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getSurveys() })
          })
    }
  },
  mounted: function () {
    this.getSurveys()
  }
}
</script>

<style scoped>

</style>
