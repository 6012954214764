<template>
  <v-container>
      <v-row justify="center">
        <v-col cols="12" md="3" id="v-step-0">
          <h1>My Products</h1>
          <v-icon color="info">mdi-help-circle</v-icon>
          <a class="pl-1" target="_blank" href="https://confluence.app.medcity.net/c/display/EAM1/Lab+%28API+Creator%29+-+Create+a+Product">Need help using Products</a>
        </v-col>
        <v-spacer />
        <v-col cols="12" md="2" id="v-step-1" v-if="adminSettings && (adminSettings.enableProductCreation || adminSettings.isSuperUser)">
          <v-btn to="/product-builder" dark color="primary">Create a Product</v-btn>
        </v-col>
      </v-row>
      <v-row class="mb-0 pb-0">
        <v-col cols="12" sm="11" md="6" id="v-step-1" class="mr-0 pr-0 mb-0 pb-0">
          <v-text-field
            label="Search products by name or description"
            placeholder=""
            prepend-inner-icon="mdi-magnify"
            solo
            clearable
            v-model="search"
            :loading="isLoading"
          >
          </v-text-field>
        </v-col>
    </v-row>
    <v-row class="mt-0 pt-0">
      <v-col class="mt-0 pt-0">
        <v-switch
          v-model="hideNoApi"
          label="Hide products with no APIs"
        ></v-switch>
      </v-col>
    </v-row>
    <v-divider/>
    <v-row>
      <v-col cols="12" v-if="products" id="v-step-2">
        <v-row v-if="products.length === 0 && isLoading">
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row class="mt-2">
          <v-col cols="12" md="4" v-for="(product, index) in products" :key="index">
            <v-card
                :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
                min-height="120"
                :to="'/products/' + product.id + '/' + 'settings'"
                hover
                ripple
            >
                <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="headline api-name">{{ product.displayName }}</v-list-item-title>
                    <v-list-item-subtitle v-if="product.teamName">By {{ product.teamName }}</v-list-item-subtitle>
                    <v-list-item-subtitle v-else>Please update team</v-list-item-subtitle>
                </v-list-item-content>
                </v-list-item>
                <v-card-actions>
                  <v-chip
                    v-if="!product.isSubscriberOnly"
                    class="ma-2"
                    color="primary"
                  >
                    {{product.apiCount}} Api(s)
                  </v-chip>
                  <v-chip
                    v-if="product.isSubscriberOnly"
                    class="ma-2"
                    color="orange"
                  >
                    Subscriber Only
                  </v-chip>
                  <v-spacer></v-spacer>
                  <v-tooltip right v-if="!product.teamName">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="warning"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-alert
                      </v-icon>
                    </template>
                    <span>Please link this product to a team</span>
                  </v-tooltip>
                </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="count > 1">
          <v-col>
            <v-pagination
              v-model="page"
              :length="count"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'Products',
  computed: {
    ...mapGetters(['adminSettings'])
  },
  watch: {
    page: function (newValue, oldValue) {
      if (oldValue !== newValue) {
        this.getProducts()
      }
    },
    hideNoApi: function (newValue, oldValue) {
      if (oldValue !== newValue) {
        this.page = 1
        this.getProducts()
      }
    },
    search: function () {
      clearTimeout(this.searchTimeout)

      // Make a new timeout set to go off in 800ms
      this.searchTimeout = setTimeout(() => {
        if (this.search == null) {
          this.search = ''
        }
        this.page = 1
        this.getProducts()
      }, 500)
    }
  },
  data: () => ({
    showSearchOptionsMenu: false,
    products: [ ],
    isLoading: true,
    page: 1,
    limit: 12,
    count: 0,
    search: '',
    hideNoApi: false
  }),
  methods: {
    getProducts: function () {
      this.isLoading = true
      axios(
        `${process.env.VUE_APP_BASEURL}/product?showOwnedProductsOnly=true&page=${this.page}&limit=${this.limit}&search=${this.search}&showProductsWithoutApis=${!this.hideNoApi}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isLoading = false
            this.products = response.data.items
            this.count = Math.ceil(response.data.totalCount / this.limit)
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getAllProducts() })
          })
    },
    routeToApi: function (id) {
      this.$router.push(`/products/${id}`)
    }
  },
  mounted: function () {
    this.getProducts()
    this.$store.commit('breadcrumbs', [
      {
        text: 'My Products',
        exact: true,
        disabled: false,
        to: '/products'
      }
    ])
  }
}
</script>

<style scoped>

</style>
